/**
 * IUser represents any user that can log into parcelport app
 */
import {LANGUAGE} from './common_enums';
import {BaseModel} from './base_model';
import {Company} from './company';
import {Locker} from './locker';
import {Location} from './location';

export interface UserUpdatableFields {
    firstName: string;
    lastName: string;
    phone: string;
    locationId: Location | string;
}

export enum USER_ROLE {
    USER = 'USER',
    ADMIN = 'ADMIN',
    COMPANY_ADMIN = 'COMPANY_ADMIN',
    CARRIER_USER = 'CARRIER_USER'
}

/** Status for User */
export enum USER_STATUS {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    BLOCKED = 'BLOCKED',
    ARCHIVED = 'ARCHIVED'
}

/** Types for User */
export enum DATA_SOURCE {
    RBC = 'RBC',
    /**
     * @deprecated "Studio" data source was changed to CCC and C_PLUS integrations
     */
    STUDIO = 'STUDIO',
    CCC = 'CCC',
    C_PLUS = 'C_PLUS',
    ADMIN_PANEL = 'ADMIN_PANEL',
    SIGN_UP = 'SIGN_UP',
    CPC = 'CPC',
    PIER = 'PIER'
}

export class ShipmentCounts {
    totalShipments: Number;
    activeShipments: Number;
}

export class User extends BaseModel implements UserUpdatableFields {
    role: USER_ROLE;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    businessPhone: string;
    language?: LANGUAGE;
    shipmentCounts?: ShipmentCounts;
    newAt?: Date;
    provider?: string;
    salt?: string;
    hashedPassword?: string;
    newHashedPassword?: boolean;
    facebook?: string;
    google?: string;
    unit: string;
    isHeightRestricted?: boolean;
    followUpSent?: boolean;
    accessCode?: string;
    dataSource?: DATA_SOURCE;
    newUser?: boolean;
    verifyCode?: string;
    status?: USER_STATUS;
    locationId: Location | string;
    companyId: Company | string;
    confirmLink?: string;
    rbcFloor?: string;
    preferredLockerId?: Locker | string;
    allowCommunication: boolean;
    billingAddress?: {
        fullName: string;
        address1: string;
        address2: string;
        stateProvince: string,
        city: string,
        postalCode: string,
        countryCode: string
    };
}

/**
 * RbcUser is applicable to Royal Bank of Canada users
 */
export class RbcUser extends User {
    rbcFloor: string;
    department: string;
}

export class ResidentialUser extends User {

}

export class OutboundRecipient extends BaseModel {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    companyName: string;
    address1: string;
    address2: string;
    city: string;
    stateProvince: string;
    countryCode: string;
    postalCode: string;
    user: string;
    addToAddressBook: boolean;
    isBusiness: boolean;
}
