import {Component, TemplateRef , OnInit} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {UsersService} from './users.service';
import {AuthService} from '../../common/auth.service';
import {User} from './user';
import {Constant} from '../../common/constant';
import {Company} from '../companies/company';
import {Plan} from '../payment-plans/plans';
import {CompaniesService} from '../companies/companies.service';
import {Router, ActivatedRoute} from '@angular/router';
import {StorageService} from '../../common/storage.service';
import {ExportService} from '../../common/export.service';
import {Observable, mergeMap} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {ToastrService} from '../../common/toastr.service';
import {USER_STATUS_ENUM} from '../../common/common_enums';
import {debounceTime, filter} from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'pp-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {

    public isDataLoaded;
    public selectedUser: any;
    public usersToUpdate: any;
    public selectedPlanId: String;
    public isExporting: boolean;
    public filterObj: any;
    public currentUser: User;
    public isAllUsersChecked = false;
    public usersArr: Array<User> = [];
    public totUsers: number;
    public USER_STATUS = USER_STATUS_ENUM;
    public perPageArr: Array<number> = [10, 25, 50, 100, 250];
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public plansArr: Array<Plan> = [{_id: '', planName: 'Select Plan'}];
    readonly CONSTANT = Constant;
    private USER_FILTER = 'userFilter';
    searchPhoneTxt = '';
    searchEmailTxt = '';
    dataSource: Observable<any>;
    updatePlanModalRef: BsModalRef;
    public previousToken = '';
    public previousInputValue = '';

    constructor(private _bsModalService: BsModalService,
                private activatedRoute: ActivatedRoute,
                private auth: AuthService,
                private companiesService: CompaniesService,
                private router: Router,
                private storage: StorageService,
                private usersService: UsersService,
                private exportService: ExportService,
                private toastr: ToastrService) {
        this.usersToUpdate = [];
        this.isExporting = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.totUsers = 0;
        this.selectedPlanId = '';
        this.filterObj = {sortBy: '', orderBy: '', page: 0, limit: 0, companyId: ''};
        this.fnMergeQueryParams();
        this.previousInputValue = this.selectedUser || '';
    }

    fnOpenUpdateUsersPlanModal(template: TemplateRef<any>) {
        this.updatePlanModalRef  = this._bsModalService.show(template);
    }

    ngOnInit() {
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.fnGetCompanies();
        } else if (this.currentUser.role === Constant.ROLE_COMPANY_ADMIN || this.currentUser.role === Constant.ROLE_CARRIER_ADMIN) {
            this.filterObj.companyId = this.currentUser.companyId;
            this.fnGetUsers(this.filterObj);
        }

        this.dataSource = new Observable((observer: any) => {
            observer.next(this.selectedUser || '');
        }).pipe(
            debounceTime(500),
            filter((data: string) => this.previousToken !== data.trim()),
            mergeMap((token: string) => {
                this.previousToken = token && token.trim();
                if (!this.filterObj.companyId) {
                    this.toastr.fnWarning('Please select company to search the users');
                    return of([]);
                }
                return this.previousToken ? this.filterResults(token) : of([]);
            }));
    }

    filterResults(token: string) {
        return this.usersService.fnGetUsers({companyId: this.filterObj.companyId, name: token});
    }

    fnOnSelectUser(event: TypeaheadMatch): void {
        this.filterObj._id = event.item._id;
        this.selectedUser = event.item.firstName + ' ' + event.item.lastName;
        this.filterObj.selectedUser = this.selectedUser;
        this.fnChangeCompany(this.filterObj);
    }

    fnFillUsersToUpdate() {
        return this.usersArr.filter((o) => {
            return o.isChecked;
        }).map((o) => {
            return o._id;
        });
    }

    /**
     * All users select event
     * @param {boolean} isAllUsersChecked
     * */
    fnOnAllUsersChanged(isAllUsersChecked: boolean) {
        _.forEach(this.usersArr, (userObj: any) => {
            userObj.isChecked = isAllUsersChecked;
        });

        if (this.isAllUsersChecked) {
            this.usersToUpdate = this.fnFillUsersToUpdate();
        } else {
            this.usersToUpdate = [];
        }
    }

    /**
     * Select single user change event.
     * */
    fnOnUserChanged() {
        // Check if all true
        this.isAllUsersChecked = !!this.usersArr.map((obj: any) => obj.isChecked)
            .reduce((a: any, b: any) => {
                return (a === b) ? a : NaN;
            });

        this.usersToUpdate = this.fnFillUsersToUpdate();
    }

    fnTypeAheadOnBlur(event: any): void {
        this.previousToken = '';
        const selectedUser = this.selectedUser && this.selectedUser.trim();
        const inputValue = event.target.value && event.target.value.trim();
        if (!selectedUser && !inputValue && this.previousInputValue !== inputValue) {
            delete this.filterObj._id;
            this.filterObj.selectedUser = '';
            this.fnChangeCompany(this.filterObj);
        }
        this.previousInputValue = inputValue;
    }

    public onPhoneInput() {
        this.filterObj.phone = this.searchPhoneTxt;
        this.fnChangeCompany(this.filterObj);
    }

    public onEmailInput() {
        this.filterObj.email = this.searchEmailTxt;
        this.fnChangeCompany(this.filterObj);
    }

    /**
     * Change pagination
     * @param {any} event
     * */
    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetUsers(this.filterObj);
    }

    /**
     * Change per page
     * @param {any} filterObj
     * */
    fnPerPageChanged(filterObj: any) {
        this.fnGetUsers(filterObj);
    }

    /**
     * Change company pull down
     * @param {any} filterObj
     * */
    fnChangeCompany(filterObj: any) {
        this.filterObj.page = 1;
        this.fnGetUsers(filterObj);
        this.usersToUpdate = [];
        this.isAllUsersChecked = false;
    }

    /**
     * Clear filter
     * */
    fnClearFilters() {
        this.filterObj = {
            sortBy: 'firstName', orderBy: 'asc', page: 1, limit: 10, companyId: ''
        };
        this.selectedPlanId = '' ;
        this.selectedUser = '';
        this.searchPhoneTxt = '';
        this.searchEmailTxt = '';
        this.storage.fnRemove(this.USER_FILTER);
        this.filterObj.companyId = this.currentUser.role === Constant.ROLE_ADMIN ? '' : this.currentUser.companyId;
        this.usersToUpdate = [];
        this.fnMergeQueryParams();
        this.fnGetCompanies();
    }

    /**
     * Merge local storage filter object into query params.
     * */
    fnMergeQueryParams() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const storedFilterObj: any = this.storage.fnGetData(this.USER_FILTER);
            if (!_.isEmpty(storedFilterObj)) {
                this.selectedUser = storedFilterObj.selectedUser || '';
                this.searchPhoneTxt = storedFilterObj.phone || '';
                this.searchEmailTxt = storedFilterObj.email || '';
                _.forEach(params, (value, key) => {
                    if (key === 'limit') {
                        const limit = parseInt(value, 10);
                        this.filterObj[key] = this.perPageArr.indexOf(limit) > -1 ? limit : 10;
                    } else if (key === 'page') {
                        this.filterObj[key] = parseInt(value, 10);
                    } else {
                        this.filterObj[key] = value;
                    }
                });
                _.forEach(storedFilterObj, (value, key) => {
                    if (!this.filterObj[key]) {
                        this.filterObj[key] = value;
                    }
                });
            } else {
                this.filterObj.sortBy = 'firstName';
                this.filterObj.orderBy = 'asc';
                this.filterObj.page = 1;
                this.filterObj.limit = 10;
            }
            if (this.filterObj.userId === this.currentUser._id) {
                this.filterObj.companyId = '';
            }
        });
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.sortBy = 'firstName';
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        this.usersService.fnGetUsers(this.filterObj)
            .then((res: any) => {
                this.usersArr = res.data;
            });
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.companiesService.fnGetCompanies({limitData: true})
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
                if (this.currentUser.role === Constant.ROLE_ADMIN) {
                    this.fnGetUsers(this.filterObj);
                } else {
                    this.filterObj.companyId = this.currentUser.companyId;
                    this.fnGetUsers(this.filterObj);
                }
            });
    }

    /**
     * Get list of users
     * @param {object} params
     * */
    fnGetUsers(params?: object) {
        this.isDataLoaded = false;
        this.storage.fnStoreData(this.USER_FILTER, params);

        const requestParam: any = {...params};
        delete requestParam.selectedUser;

        this.usersService.fnGetUsers(requestParam)
            .then((res: any) => {
                this.isDataLoaded = true;
                this.totUsers = res.total;
                this.usersArr = res.data.map((o) => {
                    o.isChecked = this.isAllUsersChecked;
                    return o;
                });
            }).catch((error) => {
                console.error('UsersComponent: fnGetUsers(): error', error);
                if (error && error.message) {
                    this.toastr.fnError(error.message);
                } else {
                    this.toastr.fnError('Facing difficulties in fetching data');
                }
            });
    }

    /**
     * Update multiple selected users plan
     * @param {any} usersArr
     */
    fnUpdateMultiUsersPlan() {
        if (this.selectedPlanId) {
            this.usersService.fnUpdateUsersPlan(this.selectedPlanId, this.usersToUpdate)
                .then(() => {
                    this.toastr.fnSuccess('Users plan updated successfully.');
                    this.updatePlanModalRef.hide();
                })
                .catch((err: any) => {
                    console.log('Error occurred in updating users plan', err);
                });
        }else if (this.plansArr.length <= 1 && this.usersToUpdate.length) {
            this.updatePlanModalRef.hide();
        } else  {
            this.toastr.fnError('Please select any plan to update.');
        }
    }

    /**
     * Delete user
     * @param {string} id
     * */
    fnDeleteUser(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this user?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.usersService.fnDeleteUser(id)
                    .then(() => {
                        _.remove(this.usersArr, function (user) {
                            return user._id === id;
                        });
                        this.toastr.fnSuccess('User deleted successfully.');
                    })
                    .catch((error) => {
                        this.toastr.fnError(error.message);
                    });
            }
        });
    }

    /**
     * Check user role for edit and delete user
     * @param {User} user
     * */
    fnCheckUserRoleForEdit(user: User) {
        let flag = true;
        switch (user.role) {
            case Constant.ROLE_ADMIN:
                flag = this.currentUser._id === user._id ? false : true;
                break;
            case Constant.ROLE_COMPANY_ADMIN:
                flag = this.currentUser.role === Constant.ROLE_ADMIN;
                break;
            case Constant.ROLE_CARRIER_ADMIN:
                flag = this.currentUser.role === Constant.ROLE_ADMIN;
                break;
            default:
                flag = true;
        }
        return flag;
    }

    fnExportCSV() {
        this.isExporting = true;
        const filterObj: any = {...this.filterObj};
        filterObj.page = 1;
        filterObj.limit = this.totUsers;
        this.usersService.fnGetUsers(filterObj)
            .then((res: any) => {
                const users = res.data;
                const csvArr = [];
                _.forEach(users, element => {
                    if(element.status !== USER_STATUS_ENUM.ARCHIVED) {
                        element.fullName = element.lastName ? element.firstName + ' ' + element.lastName : element.firstName;
                        csvArr.push(element);
                    }
                });
                const headers = ['_id', 'fullName', 'email', 'phone', 'role', 'companyId.name',
                    'preferredLockerId.name', 'locationId.city', 'unit', 'rbcFloor', 'status', 'updatedAt', 'createdAt'];
                this.exportService.fnDownloadCSV('Users', csvArr, headers);
                this.isExporting = false;
            });
    }
}
