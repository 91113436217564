import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../common/auth.service';
import { CabinetsService } from '../cabinets.service';
import { CompaniesService } from '../../companies/companies.service';
import { LockersService } from '../../lockers/lockers.service';
import { ToastrService } from '../../../common/toastr.service';

import { Cabinet } from '../cabinet';
import { Company } from '../../companies/company';
import {Locker} from '../../lockers/locker';
import { User } from '../../users/user';
import {SearchResultsWithMetadata} from '../../../common/search-results'

import { Constant } from '../../../common/constant';

@Component({
    selector: 'pp-cabinet',
    templateUrl: './cabinet.component.html',
    styleUrls: ['./cabinet.component.css']
})

export class CabinetComponent implements OnInit {

    public isDataLoaded;
    public currentUser: User;
    public currentCabinet: Cabinet;
    public cabinetObj: Cabinet;
    public cabinetId: string;
    public selectedCompany: string;
    public selectedLocker: string;
    private cabinetNumberEnum: Array<string> = Constant.CABINET_NUMBER_ENUM;
    public lockersArr: Array<Locker> = [{ _id: '', name: 'Select Locker' }];
    public companiesArr: Array<Company> = [{ _id: '', name: 'Select Company' }];
    readonly CONSTANT = Constant;
    public availableCabinets = [];
    public slotsArray = [
        {
            slotType: 'SMALL',
            slotNumber: '01',
            isUpperSlot: false
        }
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private cabinetsService: CabinetsService,
        private companiesService: CompaniesService,
        private lockersService: LockersService,
        private router: Router,
        private toastr: ToastrService,
    ) {
        this.isDataLoaded = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.cabinetObj = {companyId: '', lockerId: '', type: Constant.LOCKER_BANK, cabinetNumber: '', slots: this.slotsArray};
        this.activatedRoute.params.subscribe(params => {
            this.cabinetId = params['id'];
        });
    }

    ngOnInit() {
        this.fnGetCompanies();
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.isDataLoaded = true;
        this.companiesService.fnGetCompanies({limitData: true})
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({ _id: '', name: 'Select Company' });
                if (this.cabinetId !== 'add') {
                    this.fnGetCabinet(this.cabinetId);
                } else {
                    this.isDataLoaded = false;
                    if (this.currentUser.role.endsWith('_' + Constant.ROLE_ADMIN)) {
                        this.fnGetLockers({ companyId: this.currentUser.companyId });
                    }
                }
            });
    }

    /**
     * Get lockers
     * */
    fnGetLockers(params?: object) {
        const company = this.companiesArr.find(company => company._id === params['companyId']);
        this.selectedCompany = company.name;
        this.lockersService.getLockers(params, true)
            .then((lockersArr: Array<SearchResultsWithMetadata<Locker>>) => {
                this.lockersArr = [];
                this.availableCabinets = [];
                if (!this.cabinetObj.lockerId) {
                    this.cabinetObj.cabinetNumber = '';
                }
                if (this.cabinetObj.companyId) {
                    this.lockersArr = lockersArr[0].data;
                }
                this.lockersArr.unshift({ _id: '', name: 'Select Locker' });
                let existingCabinetNumbers = [];
                if (this.cabinetObj.lockerId) {
                    for (const locker of this.lockersArr) {
                        if (locker._id === this.cabinetObj.lockerId && locker.cabinets) {
                            this.selectedLocker = locker.name;
                            existingCabinetNumbers = locker.cabinets.map(cabinet => {
                                if (cabinet.cabinetNumber) {
                                    return cabinet.cabinetNumber;
                                }
                            });
                            break;
                        }
                    }
                    for (const cabinetNumber of this.cabinetNumberEnum) {
                        if (!existingCabinetNumbers.includes(cabinetNumber) || cabinetNumber===this.cabinetObj.cabinetNumber) {
                            this.availableCabinets.push(cabinetNumber);
                        }
                    }
                }
            });
    }

    /**
     * Get cabinet
     * @param {string} id
     * */
    fnGetCabinet(id: string) {
        this.cabinetsService.fnGetCabinet(id)
            .then((cabinetObj: Cabinet) => {
                this.isDataLoaded = false;
                this.cabinetObj = cabinetObj;
                this.cabinetObj.type = this.cabinetObj.type.toUpperCase();
                this.cabinetObj.cabinetNumber = cabinetObj.cabinetNumber;
                this.fnGetLockers({ companyId: this.cabinetObj.companyId });
            });
    }

    /**
     * Set the cabinet number
     * */
    setCabinetNumber() {
        this.availableCabinets = [];
        this.cabinetObj.cabinetNumber = '';
        if (this.cabinetObj.lockerId  && this.cabinetObj.companyId) {
            const finalLocker = this.lockersArr.find((locker) => locker._id === this.cabinetObj.lockerId);
            const existingLockers = [];
            if (finalLocker && finalLocker.cabinets) {
                for (const cabinet of finalLocker.cabinets) {
                    if (cabinet.cabinetNumber) {
                        existingLockers.push(cabinet.cabinetNumber);
                    }
                }
                for (const cabinetNumber of this.cabinetNumberEnum) {
                    if (!existingLockers.includes(cabinetNumber)) {
                        this.availableCabinets.push(cabinetNumber);
                    }
                }
            }
        }
    }

    checkCabinetType() {
        if (this.cabinetObj.type === Constant.HOST_BANK && this.slotsArray.length > 10) {
            this.slotsArray.splice(10, this.slotsArray.length-10);
        }
    }

    /**
     * Create/Update cabinet
     * @param {object} cabinetObj
     * */
    fnSaveCabinet(cabinetObj: Cabinet) {
        if (cabinetObj._id) {
            this.cabinetsService.fnUpdateCabinet(cabinetObj)
                .then(() => {
                    this.toastr.fnSuccess('Cabinet updated successfully.');
                    this.router.navigateByUrl('/cabinets');
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        } else {
            if (!cabinetObj.companyId) {
                cabinetObj.companyId = this.currentUser.companyId;
            }
            if (!cabinetObj.lockerId) {
                cabinetObj.lockerId = this.currentCabinet.lockerId;
            }
            this.cabinetsService.fnCreateCabinet(cabinetObj)
                .then((response) => {
                    this.toastr.fnSuccess(response['message']);
                    this.router.navigateByUrl('/cabinets');
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        }
    }

}
