<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Transactions
            <button class="pull-right btn btn-success" (click)="fnExportCSV()"
                    *ngIf="transactionsArr.length && !invalidDateRange" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-1">
                <div class="form-group">
                    <select id="limit" class="form-control" name="company" [(ngModel)]="filterObj.limit"
                            (change)="fnPerPageChanged(filterObj);">
                        <option [ngValue]="item" *ngFor="let item of perPageArr">{{item}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" [(ngModel)]="filterObj.companyId"
                            (change)="filterObj.userId = '';filterObj.lockerId = '';fnChangeCompany(filterObj)">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <ng-template #customItemTemplate let-model="item" let-index="index">
                        {{model.firstName}} {{model.lastName}}{{model.email ? ' (' + model.email + ')' : ''}}
                    </ng-template>
                    <input id="users" name="users" class="form-control" autocomplete="off" [(ngModel)]="selectedUser" [typeahead]="dataSource"
                        (typeaheadOnSelect)="fnOnSelectUser($event)" [typeaheadItemTemplate]="customItemTemplate"
                        [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body" [typeaheadMinLength]="0"
                        [typeaheadOptionsInScrollableView]="10" (blur)="fnTypeAheadOnBlur($event)" placeholder="Select User" autocomplete="off">
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group" [ngClass]="{'has-error': !selectedCreatedAt || invalidDateRange}">
                    <input type="text" name="daterangeInput" class="form-control" daterangepicker
                           placeholder="Select Date Range"
                           [(ngModel)]="selectedCreatedAt"
                           [options]="{autoUpdateInput: false, opens: 'left'}"
                           (applyDaterangepicker)="fnSelectedDate($event)"
                           (change)="changeDateRange()"
                           (cancelDaterangepicker)="fnClearDate(true)"/>
                    <small class="text-danger" *ngIf="invalidDateRange">
                        Maximum date range is 31 days.
                    </small>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <select id="action" class="form-control" name="action" [(ngModel)]="filterObj.action"
                            (change)="changeAction(filterObj);">
                        <option [ngValue]="item._id" *ngFor="let item of actions">{{ item.name | RemoveUnderscore }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="filterObj.lockerId"
                            (change)="fnChangeLocker(filterObj);">
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <select id="cabinet" class="form-control" name="cabinet" [(ngModel)]="filterObj.cabinetId"
                            (change)="changeCabinet(filterObj);">
                        <option [ngValue]="item._id" *ngFor="let item of cabinetsArr">{{item.cabinetNumber}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <select id="slot" class="form-control" name="slot" [(ngModel)]="filterObj.slotId"
                            (change)="changeSlot(filterObj);">
                        <option [ngValue]="item._id" *ngFor="let item of slotsArr">{{item.slotNumber}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-primary btn-block pull-right" (click)="fnClearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="table-responsive" *ngIf="transactionsArr.length">
            <table class="transactions-table">
                <thead>
                <tr>
                    <th class="bg-primary" width="2%">
                        <a class="m-l-4" href="javascript:void(0);" (click)="fnToggleAllShipments()">
                            <span class="fa" [ngClass]="isAllShipments ? 'fa-minus' : 'fa-plus'"></span></a></th>
                    <th class="bg-primary" (click)="fnSortBy('lockerId.companyId.name')" width="10%">
                        Company Name <i [class]="fnSetSortIcon('lockerId.companyId.name')"></i></th>
                    <th class="bg-primary" (click)="fnSortBy('lockerId.name')" width="13%">
                        Locker Name <i [class]="fnSetSortIcon('lockerId.name')"></i></th>
                    <th class="bg-primary cursor-auto" width="5%">APK Ver.</th>
                    <th class="bg-primary" (click)="fnSortBy('slotId.cabinetId.cabinetNumber')">
                        Slot <i [class]="fnSetSortIcon('slotId.cabinetId.cabinetNumber')" width="4%"></i></th>
                    <th class="bg-primary" (click)="fnSortBy('transactionAt')">
                        Transaction Time <i [class]="fnSetSortIcon('transactionAt')" width="9%"></i></th>
                    <th class="bg-primary" (click)="fnSortBy('action')">
                        Action <i [class]="fnSetSortIcon('action')" width="5%"></i></th>
                    <th class="bg-primary" (click)="fnSortBy('description')">
                        Description <i [class]="fnSetSortIcon('description')" width="25%"></i></th>
                    <th class="bg-primary" (click)="fnSortBy('packageStatus')">
                        Package Status <i [class]="fnSetSortIcon('packageStatus')" width="6%"></i></th>
                    <th class="bg-primary" (click)="fnSortBy('slotStatus')">
                        Slot Status <i [class]="fnSetSortIcon('slotStatus')" width="5%"></i></th>
                    <th class="bg-primary" (click)="fnSortBy('createdBy')">
                        Created By <i [class]="fnSetSortIcon('createdBy')" width="7%"></i></th>
                    <th class="bg-primary" (click)="fnSortBy('userId.firstName')">
                        User <i [class]="fnSetSortIcon('userId.firstName')" width="5%"></i></th>
                    <th class="bg-primary cursor-auto" width="4%">Feedback</th>
                </tr>
                </thead>
                <tbody>
                <ng-template ngFor let-transaction [ngForOf]="transactionsArr" let-i="index">
                    <tr >
                        <td width="2%"><a class="m-l-4" href="javascript:void(0);" (click)="fnToggleShipments(transaction)">
                            <i class="fa" [ngClass]="transaction.isShipments ? 'fa-minus' : 'fa-plus'" *ngIf="transaction.shipmentId"></i></a></td>
                        <td width="10%">{{transaction.lockerId && transaction.lockerId.companyId && transaction.lockerId.companyId.name
                            ? transaction.lockerId.companyId.name : '&nbsp;'}}
                        </td>
                        <td width="13%">{{transaction.lockerId ? transaction.lockerId.name : '&nbsp;'}}</td>
                        <td width="5%">{{transaction.apkVersion ? transaction.apkVersion : '&nbsp;'}}</td>
                        <td width="4%">{{transaction.slotId && transaction.slotId.cabinetId ?
                            (transaction.slotId.cabinetId.cabinetNumber + transaction.slotId.slotNumber) : '&nbsp;'}}
                        </td>
                        <td width="9%">{{transaction.transactionAt ? (getLocalDate(transaction.transactionAt)) : '&nbsp;'}}</td>
                        <td width="5%">{{transaction.action ? (transaction.action | RemoveUnderscore) : '&nbsp;'}}</td>
                        <td width="25%">{{transaction.description}}</td>
                        <td width="6%">{{transaction.packageStatus ? (transaction.packageStatus | RemoveUnderscore) : '&nbsp;'}}</td>
                        <td width="5%">{{transaction.slotStatus ? (transaction.slotStatus | RemoveUnderscore) : '&nbsp;'}}</td>
                        <td width="7%">{{transaction.createdBy ? (transaction.createdBy | RemoveUnderscore) : '&nbsp;'}}</td>
                        <td width="5%">
                            <p class="m-b-0">{{transaction.userId ? transaction.userId['firstName'] + ' ' +
                                transaction.userId['lastName'] : ''}}</p>
                        </td>
                        <td width="4%">
                            <button type="button" class="btn btn-xs btn-primary"
                                    [routerLink]="['/feedback']"
                                    [queryParams]="{userId: transaction.userId ? transaction.userId._id : ''}">
                                View
                            </button>
                        </td>

                    </tr>
                    <tr class="cabinets-row" *ngIf="transaction.isShipments && transaction.shipmentId">
                        <td></td>
                        <td colspan="12">
                            <p *ngIf="!transaction.shipmentId" class="text-center">
                                No Shipment Details Found.
                            </p>

                            <table *ngIf="transaction.shipmentId">
                                <thead>
                                <tr>
                                    <th class="bg-primary carrier-name" (click)="fnSortBy('carrierName', 'carrier-name')">
                                        Carrier Name <i class="fa fa-sort"></i></th>
                                    <th class="bg-primary shipment-status" (click)="fnSortBy('status', 'shipment-status')">
                                        Status <i class="fa fa-sort"></i></th>
                                    <th class="bg-primary">Slot #</th>
                                    <th class="bg-primary">Packages</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <td>{{transaction.shipmentId.carrierName}}</td>
                                    <td>
                                        <p class="m-b-0">
                                            {{transaction.shipmentId.status ? (transaction.shipmentId.status | RemoveUnderscore) : '&nbsp;'}}
                                        </p>
                                        <small>{{transaction.packageId.updatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</small>
                                    </td>
                                    <td>{{ transaction.slotId
                                        && transaction.slotId.cabinetId
                                        && transaction.slotId.cabinetId.cabinetNumber + transaction.slotId.slotNumber}}</td>
                                    <td>
                                        <table class="packages-table">
                                            <thead>
                                            <tr>
                                                <th class="bg-info">Label</th>
                                                <th class="bg-info">Status</th>
                                                <th class="bg-info">Size</th>
                                                <!--<th class="bg-info">Weight</th>-->
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{{transaction.packageId.label ? transaction.packageId.label : '&nbsp;'}}</td>
                                                <td>{{transaction.packageId.status ? (transaction.packageId.status | RemoveUnderscore) : '&nbsp;'}}</td>
                                                <td>{{transaction.packageId.size ? (transaction.packageId.size | RemoveUnderscore) : '&nbsp;'}}</td>
                                                <!--<td>{{package.weight ? package.weight : '&nbsp;'}}</td>-->
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <!--<td>
                                        <span *ngIf="shipment.userId && shipment.userId['firstName']">{{shipment.userId && shipment.userId['firstName'] + '&nbsp;' + shipment.userId['lastName']}}</span>
                                        <span *ngIf="!shipment.userId">{{shipment.lockerId.name}}</span>
                                    </td>
                                    <td>{{shipment.lockerId && shipment.lockerId.name}}</td>-->
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-template>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !transactionsArr.length" class="text-center">No transactions found.</h3>
        <div class="col-lg-12 text-center" *ngIf="transactionsArr.length">
            <pagination [totalItems]="totTransactions" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true" [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
</div>
