import {Pipe, PipeTransform} from '@angular/core';
import * as momentTimezone from 'moment-timezone';
import * as jstz from 'jstz';

/**
 * Convert Date into local date string.
 */
@Pipe({name: 'LocalDate'})
export class LocalDate implements PipeTransform {
    private timezone = jstz.determine().name();
    transform(value: string): string {
        if (value) {
            const newValue = momentTimezone(value).tz(this.timezone).format('DD/MM/YYYY, HH:mm   z');
            return `${newValue}`;
        }
        return 'N/A';
    }
}
