import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import {AuthService} from '../../common/auth.service';
import {ShipmentsService} from '../shipments/shipments.service';
import {Shipment} from '../shipments/shipment';
import {User} from '../users/user';
import {Constant} from '../../common/constant';
import { ExportService } from '../../common/export.service';
import {Observable, mergeMap} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {UsersService} from '../users/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Locker} from '../lockers/locker';
import {Company} from '../companies/company';
import {LockersService} from '../lockers/lockers.service';
import {CompaniesService} from '../companies/companies.service';
import {ToastrService} from '../../common/toastr.service';
import {StorageService} from '../../common/storage.service';
import {OUTBOUND_SHIPMENT_STATUS} from '../../common/enums';
import {debounceTime, filter} from 'rxjs/operators';
import { of } from 'rxjs';
import {OutboundShipmentService} from './outbound-shipment.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'pp-outbound-shipment',
    templateUrl: './outbound-shipment.component.html',
    styleUrls: ['./outbound-shipment.component.css']
})

export class OutboundShipmentComponent implements OnInit, AfterViewInit {
    @ViewChild(DaterangePickerComponent, {static: false})
    private picker: DaterangePickerComponent;
    public currentUser: User;
    public selectedUser: any;
    public selectedCreatedAt: any;
    public filterObj: any;
    public isDataLoaded;
    public isExporting: boolean;
    public invalidDateRange: boolean;
    public isDevURL: boolean;
    public isAllShipmentChecked = false;
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public shipmentArr: Array<Shipment> = [];
    readonly CONSTANT = Constant;
    public totShipments: number;
    dataSource: Observable<any>;
    defaultDateRangeDays: number;
    maxDateRangeDays: number;
    private OUTBOUND_SHIPMENTS_FILTERS = 'outboundShipmentsFilter';
    public SHIPMENT_STATUS = OUTBOUND_SHIPMENT_STATUS;
    public shipmentStatusArr: Array<{key: string, value: string}> = [];
    public previousToken = '';
    public previousInputValue = '';
    searchLabelTxt = '';
    showDates: string;
    openDatesDropDown = false;

    public shipmentInfo: { label: string, dropOffCode: string, isCopied: boolean };
    public pickupCodeModelRef: BsModalRef;
    dateDetailsArray = [false, false, false, false];
    outboundPickupCode: boolean;

    constructor(private _bsModalService: BsModalService, private activatedRoute: ActivatedRoute,
                private shipmentService: ShipmentsService, private router: Router,
                private auth: AuthService, private exportService: ExportService, private usersService: UsersService,
                private lockersService: LockersService, private companiesService: CompaniesService,
                private toastr: ToastrService, private storage: StorageService, private outboundShipmentService: OutboundShipmentService, private domSanitizer: DomSanitizer) {

        this.currentUser = this.auth.fnGetCurrentUser();
        this.isDataLoaded = false;
        this.isExporting = false;
        this.invalidDateRange = false;
        this.isDevURL = false;
        this.totShipments = 0;
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.defaultDateRangeDays =  Constant.DEFAULT_DATE_RANGE_DAYS_ADMIN;
            this.maxDateRangeDays = Constant.MAX_DATE_RANGE_DAYS_ADMIN;
        } else {
            this.defaultDateRangeDays = Constant.DEFAULT_DATE_RANGE_DAYS;
            this.maxDateRangeDays = Constant.MAX_DATE_RANGE_DAYS;
        }
        this.getShipmentsFilters();
        this.selectedCreatedAt = this.filterObj.startDate && this.filterObj.endDate ?
            this.filterObj.startDate + ' - ' + this.filterObj.endDate : '';

        for (const key in OUTBOUND_SHIPMENT_STATUS) {
            if (OUTBOUND_SHIPMENT_STATUS.hasOwnProperty(key) && OUTBOUND_SHIPMENT_STATUS[key]) {
                this.shipmentStatusArr.push({
                    key: key,
                    value: OUTBOUND_SHIPMENT_STATUS[key]
                });
            }
        }
    }

    ngOnInit() {
        this.getInitialData();
    }

    ngAfterViewInit(): void {
        this.picker.datePicker.setStartDate(this.filterObj.startDate);
        this.picker.datePicker.setEndDate(this.filterObj.endDate);
    }

    async getShipmentsFilters() {
        let OUTBOUND_SHIPMENTS_FILTERS: any = this.storage.fnGetData(this.OUTBOUND_SHIPMENTS_FILTERS);
        const twoHoursDuration = 2 * 60 * 60 * 1000;
        if (OUTBOUND_SHIPMENTS_FILTERS && OUTBOUND_SHIPMENTS_FILTERS.lastUpdatedAt && (Date.now() - OUTBOUND_SHIPMENTS_FILTERS.lastUpdatedAt) >= twoHoursDuration) {
            OUTBOUND_SHIPMENTS_FILTERS = null;
        }
        this.filterObj = _.isEmpty(OUTBOUND_SHIPMENTS_FILTERS) ? {
            sortBy: 'createdAt',
            orderBy: 'desc',
            startDate: this.getDefaultDateRange().startDate,
            endDate: this.getDefaultDateRange().endDate,
            userId: '',
            companyId: '',
            lockerId: '',
            page: 1,
            limit: 10,
            selectedUser: '',
            label: '',
            status : OUTBOUND_SHIPMENT_STATUS.OUTBOUND
        } : OUTBOUND_SHIPMENTS_FILTERS;
        if (OUTBOUND_SHIPMENTS_FILTERS) {
            if (OUTBOUND_SHIPMENTS_FILTERS['invalidDateRange']) {
                this.filterObj.startDate = this.getDefaultDateRange().startDate;
                this.filterObj.endDate = this.getDefaultDateRange().endDate;
            }
        }

        this.selectedUser = (!_.isEmpty(OUTBOUND_SHIPMENTS_FILTERS) && OUTBOUND_SHIPMENTS_FILTERS.selectedUser) ? OUTBOUND_SHIPMENTS_FILTERS.selectedUser : '';
        this.searchLabelTxt = (!_.isEmpty(OUTBOUND_SHIPMENTS_FILTERS) && OUTBOUND_SHIPMENTS_FILTERS.label) ? OUTBOUND_SHIPMENTS_FILTERS.label : '';
        this.previousInputValue = this.selectedUser;
    }

    getDefaultDateRange() {
        const dateRange = {startDate: null, endDate: null};
        dateRange.startDate = moment().subtract(this.defaultDateRangeDays, 'd').format('MM/DD/YYYY');
        dateRange.endDate = moment().format('MM/DD/YYYY');
        return dateRange;
    }

    getInitialData() {
        this.fnCheckDevURL();
        this.fnGetCompanies();
        this.dataSource = new Observable((observer: any) => {
            observer.next(this.selectedUser);
        }).pipe(
            debounceTime(500),
            filter((data: string) => this.previousToken !== data.trim()),
            mergeMap((token: string) => {
                this.previousToken = token && token.trim();
                if (!this.filterObj.companyId) {
                    this.toastr.fnWarning('Please select company to search the users');
                    return of([]);
                }
                return this.previousToken ? this.filterResults(this.previousToken) : of([]);
            }));
    }

    filterResults(token: string) {
        const params: any = {companyId: this.filterObj.companyId, name: token};
        if (this.filterObj.lockerId) {
            params.preferredLockerId = this.filterObj.lockerId;
        }
        return this.usersService.fnGetUsers(params);
    }

    fnOnSelectUser(event: TypeaheadMatch): void {
        this.previousToken = '';
        this.filterObj.userId = event.item._id;
        this.selectedUser = event.item.firstName + ' ' + event.item.lastName;
        this.filterObj.selectedUser = this.selectedUser;
        this.fnChangeShipments(this.filterObj);
    }

    changeDateRange() {
        this.invalidDateRange = false;
        if (!this.selectedCreatedAt) {
            this.toastr.fnError('Please select the date range to load shipment history');
        }
    }

    fnSelectedDate(value: any) {
        if (value) {
            this.filterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
            this.filterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
            this.filterObj.lastUpdatedAt = Date.now();
            const dayDiff = moment(this.filterObj.endDate).diff(moment(this.filterObj.startDate), 'days');
            this.invalidDateRange = dayDiff > this.maxDateRangeDays;
        }
        this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        this.fnChangeShipments(this.filterObj);
    }

    fnClearDate() {
        this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        this.invalidDateRange = false;
        this.picker.datePicker.setStartDate(this.filterObj.startDate);
        this.picker.datePicker.setEndDate(this.filterObj.endDate);
        this.fnChangeShipments(this.filterObj);
    }

    fnChangeShipments(filterObj: any) {
        this.filterObj.page = 1;
        this.filterObj.invalidDateRange = this.invalidDateRange;
        this.storage.fnStoreData(this.OUTBOUND_SHIPMENTS_FILTERS, filterObj);
        this.fnGetShipments(filterObj);
    }

    fnTypeAheadOnBlur(event) {
        this.previousToken = '';
        const selectedUser = this.selectedUser && this.selectedUser.trim();
        const inputValue = event.target.value && event.target.value.trim();
        if (!selectedUser && !inputValue && this.previousInputValue !== inputValue) {
            delete this.filterObj.userId;
            this.filterObj.selectedUser = '';
            this.fnChangeShipments(this.filterObj);
        }
        this.previousInputValue = inputValue;
    }

    /**
     * Get companies
     * */
    async fnGetCompanies() {
        try {
            if (this.currentUser.role === Constant.ROLE_ADMIN) {
                this.companiesArr = [{_id: '', name: 'Select Company'}];
                this.companiesArr = <Array<Company>> await this.companiesService.fnGetCompanies({limitData: true});
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
                if (this.filterObj.companyId) {
                    this.fnGetLockers({companyId: this.filterObj.companyId});
                } else {
                    this.fnGetShipments(this.filterObj);
                }
            } else {
                this.filterObj.companyId = this.currentUser.companyId;
                this.fnGetLockers({companyId: this.filterObj.companyId});
            }
        } catch (error) {
            this.toastr.fnError(error.message);
        }
    }

    /**
     * Get list of lockers
     * @param {object} params
     * */
    async fnGetLockers(params?: object) {
        this.lockersArr = [{_id: '', name: 'Select Locker'}];
        if (params['companyId']) {
            this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber(params['companyId']);
            this.lockersArr.unshift({_id: '', name: 'Select Locker'});
            this.fnChangeShipments(this.filterObj);
        } else {
            this.fnChangeShipments(this.filterObj);
        }
    }

    fnClearFilters() {
        this.storage.fnRemove(this.OUTBOUND_SHIPMENTS_FILTERS);
        this.getShipmentsFilters();
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.lockersArr = [{_id: '', name: 'Select Locker'}];
        }
        this.fnClearDate();
        this.selectedUser = '';
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                /*this.filterObj.sortBy = 'companyId.name';*/
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        if (this.currentUser.role === Constant.ROLE_COMPANY_ADMIN) {
            const sortArr = this.filterObj.sortBy.split('.');
            if (sortArr.length === 2) {
                this.shipmentArr = _.orderBy(this.shipmentArr, (e) => e[sortArr[0]][sortArr[1]], [this.filterObj.orderBy]);
            } else if (sortArr.length === 1) {
                this.shipmentArr = _.orderBy(this.shipmentArr, (e) => e[sortArr[0]], [this.filterObj.orderBy]);
            }
        } else {
            this.fnGetShipments(this.filterObj);
        }
    }

    fnCheckDevURL() {
        if (window.location.host === Constant.DEV_API_URL) {
            this.isDevURL = true;
        } else {
            this.isDevURL = false;
        }
    }


    /**
     * Get Shipments
     * @param {object} params
     * */
    async fnGetShipments(params?: object) {
        if (this.invalidDateRange) {
            return;
        }
        this.isDataLoaded = false;
        let res;

        const requestParam: any = {...params};
        requestParam.isOutbound = true;
        delete requestParam.selectedUser;
        delete requestParam.lastUpdatedAt;
        try {
            res = await this.shipmentService.fnGetShipments(requestParam);
            this.isDataLoaded = true;
            this.totShipments = res.total;
            this.shipmentArr = res.data.map((o) => {
                o.isChecked = this.isAllShipmentChecked;
                return o;
            });
        } catch (err) {
            this.isDataLoaded = true;
            this.toastr.fnError(err.message);
        }
    }

    /**
     * Page changed event
     * @param {any} event
     * */
    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetShipments(this.filterObj);
    }

    fnExportCSV() {
        this.isExporting = true;
        const filterObj: any = {...this.filterObj};
        filterObj.page = 1;
        filterObj.limit = 20000;
        delete filterObj.lastUpdatedAt;
        delete filterObj.selectedUser;
        filterObj.isOutbound = true;
        this.shipmentService.fnGetShipments(filterObj)
            .then((res: any) => {
                const shipments = res.data;
                const csvArr = [];
                _.forEach(shipments, element => {
                    if (element.userId && element.userId.firstName) {
                        element.sender = element.userId.lastName
                            ? element.userId.firstName + ' ' + element.userId.lastName : element.userId.firstName;
                    } else {
                        element.sender = '';
                    }

                    if (element.outboundShipment && element.outboundShipment.outboundRecipient && element.outboundShipment.outboundRecipient.firstName) {
                        element.recipient = element.outboundShipment.outboundRecipient.lastName
                            ? element.outboundShipment.outboundRecipient.firstName + ' ' + element.outboundShipment.outboundRecipient.lastName : element.outboundShipment.outboundRecipient.firstName;
                    } else {
                        element.recipient = '';
                    }

                    if (element.packages.length && element.packages[0].slotId && element.packages[0].slotId.cabinetId
                        && element.packages[0].slotId.slotNumber && element.packages[0].slotId.cabinetId.cabinetNumber) {
                        element.slot = element.packages[0].slotId.cabinetId.cabinetNumber + element.packages[0].slotId.slotNumber;
                    } else {
                        element.slot = '';
                    }

                    if (!element.userId) {
                        element.createdBy = element.lockerId ? element.lockerId.name : '';
                    } else {
                        element.createdBy = element.userId.firstName ?
                            element.userId.firstName + ' ' + element.userId.lastName : element.userId;
                    }
                    element.outboundShipment = {};
                    csvArr.push(element);
                });
                const headers = ['companyId.name', 'carrierName', 'sender', 'recipient', 'status', 'slot', 'packages.label', 'packages.status',
                    'packages.size', 'createdAt', 'packages.droppedAt', 'packages.pickupEndAt', 'packages.cancelledAt',
                    'lockerId.name', 'updatedAt', 'createdAt'];
                this.exportService.fnDownloadCSV('Outbound Shipments', csvArr, headers);
                this.isExporting = false;
            });
    }

    public onLabelChange() {
        this.filterObj.label = this.searchLabelTxt;
        this.fnChangeShipments(this.filterObj);
    }

    public openDropOffCodeModel(template: TemplateRef<any>, shipment: any, isPickupCode?: boolean) {
        if (isPickupCode) {
            this.outboundPickupCode = true;
            this.shipmentInfo = {label: shipment.packages[0].label, dropOffCode: shipment.carrier[0].outboundPickupCode, isCopied: false };
        } else {
            this.outboundPickupCode = false;
            this.shipmentInfo = {label: shipment.packages[0].label, dropOffCode: shipment.outboundShipmentDropOffCode, isCopied: false };
        }
        this.pickupCodeModelRef = this._bsModalService.show(template, {'class': 'modal-sm'});
    }

    public closeDropOffCodeModel() {
        this.shipmentInfo = {label: '', dropOffCode: '', isCopied: false};
        this.pickupCodeModelRef.hide();
    }

    public copyDropOffCode(inputElement: any) {
        const tempElem = document.createElement('input');
        this.shipmentInfo.isCopied = true;
        document.body.appendChild(tempElem);
        tempElem.value = inputElement.innerHTML.trim();
        tempElem.select();
        document.execCommand('copy', false);
        tempElem.remove();
        setTimeout(() => {
            this.shipmentInfo.isCopied = false;
        }, 1000);
    }

    async getLabel(label: string, hostname = Constant.PROD_1_API_URL) {
        // Please change host name for local environment to localhost:3000
        try {
            const response: any = await this.outboundShipmentService.getShipmentLabel(label, this.isDevURL ? Constant.DEV_API_URL : hostname);
            this.openLabelPdf(response);
        } catch (error) {
            const errorResponse: any = new TextDecoder().decode(error.error);
            if (!this.isDevURL && hostname !== Constant.PROD_2_API_URL && errorResponse.includes('File does not exists for label number')) {
                return await this.getLabel(label, Constant.PROD_2_API_URL);
            }
            let errorRes: any = {};
            try {
                errorRes = JSON.parse(errorResponse);
            }  catch (error) {
                errorRes.message = 'Internal server error';
            }
            this.toastr.fnError(errorRes.message);
        }
    }

    async toggleDatesDropDown(shipmentId) {
        this.openDatesDropDown = this.showDates === shipmentId ? !this.openDatesDropDown : true;
        this.showDates = shipmentId;
        if (this.openDatesDropDown) {
            this.dateDetailsArray = [false, false, false, false];
            for (const arr in this.dateDetailsArray) {
                await this.fakePromise();
                this.dateDetailsArray[arr] = true;
            }
        } else {
            for (const arr in this.dateDetailsArray) {
                this.dateDetailsArray[arr] = false;
            }
        }
    }

    fakePromise() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }

    openLabelPdf(labelData) {
        const file = new Blob([labelData], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
    }
}


