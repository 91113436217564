
<div class="modal-header">
    <h4 class="modal-title pull-left">Activate Plan</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose({isClose: true})">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <p class="col-sm-12">There are {{isActivePlanModalObj.userCount}} of users still on this plan. Please choose
            different plan to assign to these users:</p>

        <div class="col-sm-12" *ngIf="activatedPlans && activatedPlans?.length > 1">
            <select id="plan" class="form-control" name="plan" [(ngModel)]="isActivePlanModalObj.newPlanId"
                    #plan="ngModel" required>
                <option [ngValue]="item._id" *ngFor="let item of activatedPlans">{{item.planName}}</option>
            </select>
            <div *ngIf="planRequiredErr">
                <small class="text-danger">Please select any plan.
                </small>
            </div>
        </div>
        <p *ngIf="activatedPlans && activatedPlans?.length <= 1" class="text-danger p-l-15 p-r-15">
            <strong>There is no active plan available.</strong>
        </p>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary pull-right m-l-2 m-r-2" (click)="fnChangeUsersPlans()">
        OK
    </button>
    <button class="btn btn-warning pull-right m-l-2 m-r-2" (click)="onClose({isClose: true})">Cancel</button>
</div>

