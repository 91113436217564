import { Pipe, PipeTransform } from '@angular/core';
import {AnalyticsService} from './analytics.service';

@Pipe({
  name: 'formatMillisecond'
})
export class FormatMillisecondPipe implements PipeTransform {

    constructor (private analyticsService: AnalyticsService) {

    }

    transform(value: any, args?: any): any {
    const updatedVal = this.analyticsService.fnMilliSecondsByUnit(value, args);
    return updatedVal;
  }

}
