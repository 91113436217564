import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { Daterangepicker } from 'ng2-daterangepicker';
import { RecaptchaModule  } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import {Cookie} from './common/cookie';
import {InterceptorService} from './common/interceptor.service';
import {AuthService} from './common/auth.service';
import {AuthenticateGuard} from './common/authenticate.guard';
import {NotAuthenticateGuard} from './common/not.authenticate.guard';
import {ToastrService} from './common/toastr.service';
import {GoogleMapsService} from './common/google.maps.service';
import {StorageService} from './common/storage.service';
import {ExportService} from './common/export.service';
import {ShipmentsService} from './authenticate/shipments/shipments.service';
import {UsersService} from './authenticate/users/users.service';
import {CompaniesService} from './authenticate/companies/companies.service';
import {LockersService} from './authenticate/lockers/lockers.service';
import {CabinetsService} from './authenticate/cabinets/cabinets.service';
import {SlotsService} from './authenticate/slots/slots.service';
import {ApkService} from './authenticate/manage-apk/apk.service';
import {TransactionsService} from './authenticate/transactions/transactions.service';
import {FeedbackService} from './authenticate/feedback/feedback.service';
import {RemoveUnderscore} from './common/pipes/remove.underscore.pipe';
import {LocalDate} from './common/pipes/local-date.pipe';
import {SpaceBeforeUppercase} from './common/pipes/space-before-uppercase.pipe';
import { GroupByPipe } from './common/pipes/group-by.pipe';
import { SocketIOService } from './common/socketio.service';

import {AppComponent} from './app.component';
import {SignInComponent} from './sign-in/sign-in.component';
import {VerifyOTPComponent} from './verify-otp/verify-otp.component';
import {AuthenticateComponent} from './authenticate/authenticate.component';
import {ShipmentsComponent} from './authenticate/shipments/shipments.component';
import {ShipmentComponent} from './authenticate/shipments/shipment/shipment.component';
import {ShipmentUserComponent} from './authenticate/shipments/shipment/shipment-user/shipment-user.component';
import {UserModalComponent} from './authenticate/shipments/user-modal/user-modal.component';
import {UsersComponent} from './authenticate/users/users.component';
import {UserComponent} from './authenticate/users/user/user.component';
import {CompaniesComponent} from './authenticate/companies/companies.component';
import {CompanyComponent} from './authenticate/companies/company/company.component';
import {LockersComponent} from './authenticate/lockers/lockers.component';
import {LockerComponent} from './authenticate/lockers/locker/locker.component';
import {LockerLogsComponent} from './authenticate/lockers/locker-logs/locker-logs.component';
import {CabinetsComponent} from './authenticate/cabinets/cabinets.component';
import {CabinetComponent} from './authenticate/cabinets/cabinet/cabinet.component';
import {SlotsComponent} from './authenticate/slots/slots.component';
import {SlotComponent} from './authenticate/slots/slot/slot.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {DeleteModalComponent} from './delete-modal/delete-modal.component';
import {LocationComponent} from './authenticate/location/location.component';
import {ManageAPKComponent} from './authenticate/manage-apk/manage-apk.component';
import {UploadAPKComponent} from './authenticate/manage-apk/upload-apk/upload-apk.component';
import {TransactionsComponent} from './authenticate/transactions/transactions.component';
import {FeedbackComponent} from './authenticate/feedback/feedback.component';

import {AppRoutingModule} from './app.route';
import {BuildingDataComponent} from './authenticate/building-data/building-data.component';
import {BuildingDataService} from './authenticate/building-data/building-data.service';
import {AnalyticsComponent} from './authenticate/analytics/analytics.component';
import {AnalyticsService} from './authenticate/analytics/analytics.service';
import { NotificationsComponent } from './authenticate/notifications/notifications.component';
import {NotificationsService} from './authenticate/notifications/notifications.service';
import {FormatMillisecondPipe} from './authenticate/analytics/format-millisecond.pipe';
import { DashboardComponent } from './authenticate/dashboard/dashboard.component';
import {DashboardService} from './authenticate/dashboard/dashboard.service';
import { ActivationStatusComponent } from './activation-status/activation-status.component';
import { SettingsComponent } from './authenticate/settings/settings.component';
import { SupportComponent } from './authenticate/support/support.component';
import { PaymentPlansComponent } from './authenticate/payment-plans/payment-plans.component';
import { PaymentPlanComponent } from './authenticate/payment-plans/payment-plan/payment-plan.component';
import {PaymentPlansService} from './authenticate/payment-plans/payment-plans.service';
import { UserPlanModalComponent } from './authenticate/payment-plans/user-plan-modal/user-plan-modal.component';
import { ProfileComponent } from './profile/profile.component';
import {RpConfirmModalComponent} from './authenticate/users/user/rp-confirm-modal/rp-confirm-modal.component';
import { EnrollmentUserComponent } from './authenticate/company-admin/enrollment-user/enrollment-user.component';
import {EnrollmentUserService} from './authenticate/company-admin/enrollment-user/enrollment-user.service';
import { CompanyLockersComponent } from './authenticate/company-admin/company-lockers/company-lockers.component';
import {CompanyLockersService} from './authenticate/company-admin/company-lockers/company-lockers.service';
import { PaymentDetailsComponent } from './authenticate/payment-details/payment-details.component';
import {PaymentDetailsService} from './authenticate/payment-details/payment-details.service';
import {PaymentPlanService} from './authenticate/payment-plans/payment-plan.service';
import { UserProfileComponent } from './authenticate/user-profile/user-profile.component';
import { LockerDirectoryComponent } from './authenticate/locker-directory/locker-directory.component';
import { UserShipmentsComponent } from './authenticate/user-shipments/user-shipments.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { PendingShipmentsComponent } from './authenticate/pending-shipments/pending-shipments.component';
import { ResetPasswordVerificationComponent } from './reset-password-verification/reset-password-verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PendingShipmentComponent} from './authenticate/pending-shipments/pending-shipment/pending-shipment.component';
import { BankComponent } from './authenticate/bank/bank.component';
import { OutboundShipmentComponent } from './authenticate/outbound-shipment/outbound-shipment.component';
import { OutboundShipmentService } from './authenticate/outbound-shipment/outbound-shipment.service';
import {CarrierNamePipe} from './common/pipes/carrier-name.pipe';

@NgModule({
    declarations: [
        AppComponent,
        SignInComponent,
        AuthenticateComponent,
        ShipmentsComponent,
        ShipmentComponent,
        ShipmentUserComponent,
        UsersComponent,
        UserComponent,
        CompaniesComponent,
        CompanyComponent,
        LockersComponent,
        LockerComponent,
        LockerLogsComponent,
        SlotsComponent,
        SlotComponent,
        SpinnerComponent,
        UserModalComponent,
        DeleteModalComponent,
        LocationComponent,
        CabinetsComponent,
        CabinetComponent,
        ManageAPKComponent,
        UploadAPKComponent,
        RemoveUnderscore,
        LocalDate,
        SpaceBeforeUppercase,
        TransactionsComponent,
        FeedbackComponent,
        VerifyOTPComponent,
        BuildingDataComponent,
        AnalyticsComponent,
        NotificationsComponent,
        FormatMillisecondPipe,
        DashboardComponent,
        ActivationStatusComponent,
        SettingsComponent,
        SupportComponent,
        PaymentPlansComponent,
        PaymentPlanComponent,
        UserPlanModalComponent,
        ProfileComponent,
        RpConfirmModalComponent,
        EnrollmentUserComponent,
        CompanyLockersComponent,
        PaymentDetailsComponent,
        UserProfileComponent,
        LockerDirectoryComponent,
        UserShipmentsComponent,
        PendingShipmentsComponent,
        ResetPasswordVerificationComponent,
        ResetPasswordComponent,
        GroupByPipe,
        PendingShipmentComponent,
        BankComponent,
        OutboundShipmentComponent,
        CarrierNamePipe
    ],
    entryComponents: [
        DeleteModalComponent,
        UserPlanModalComponent,
        UserModalComponent,
        RpConfirmModalComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgxJsonViewerModule,
        Daterangepicker,
        NgxSelectModule,
        ButtonsModule.forRoot(),
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        PaginationModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TabsModule.forRoot(),
        RecaptchaModule.forRoot(),
        RecaptchaFormsModule,
        TooltipModule.forRoot()
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
        Cookie,
        AuthService,
        AuthenticateGuard,
        NotAuthenticateGuard,
        ShipmentsService,
        UsersService,
        EnrollmentUserService,
        CompaniesService,
        LockersService,
        CompanyLockersService,
        CabinetsService,
        SlotsService,
        ToastrService,
        ApkService,
        GoogleMapsService,
        StorageService,
        TransactionsService,
        FeedbackService,
        BuildingDataService,
        ExportService,
        AnalyticsService,
        NotificationsService,
        DashboardService,
        PaymentPlansService,
        PaymentPlanService,
        FormatMillisecondPipe,
        PaymentDetailsService,
        OutboundShipmentService,
        SocketIOService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
