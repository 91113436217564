import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../common/auth.service';
import { CabinetsService } from '../../cabinets/cabinets.service';
import { CompaniesService } from '../../companies/companies.service';
import { LockersService } from '../../lockers/lockers.service';
import { SlotsService } from '../slots.service';
import { ToastrService } from '../../../common/toastr.service';

import { Cabinet } from '../../cabinets/cabinet';
import { Company } from '../../companies/company';
import {Locker} from '../../lockers/locker';
import { Slot } from '../slot';
import { User } from '../../users/user';

import { Constant } from '../../../common/constant';
import {DeleteModalComponent} from '../../../delete-modal/delete-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SearchResults} from '../../../common/search-results';

@Component({
    selector: 'pp-slot',
    templateUrl: './slot.component.html',
    styleUrls: ['./slot.component.css']
})

export class SlotComponent implements OnInit {

    public isDataLoaded;
    public currentUser: User;
    public currentSlot: Slot;
    public slotObj: Slot;
    public slotId: string;
    public selectedCompany: string;
    public selectedLocker: string;
    public selectedCabinet: string;
    public companiesArr: Array<Company> = [{ _id: '', name: 'Select Company' }];
    public lockersArr: Array<Locker> = [{ _id: '', name: 'Select Locker' }];
    public cabinetsArr: Array<Cabinet> = [{ _id: '', cabinetNumber: '' }];
    private slotNumberEnum: Array<string> = Constant.SLOT_NUMBER_ENUM;
    readonly CONSTANT = Constant;
    public isUpperSlot = 'False';
    public availableSlotNumbers = [];
    public slotNumber = '';
    public shipmentId: string;
    public preserveSlotStatus: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private cabinetsService: CabinetsService,
        private companiesService: CompaniesService,
        private lockersService: LockersService,
        private router: Router,
        private slotsService: SlotsService,
        private toastr: ToastrService,
        private _bsModalService: BsModalService,
    ) {
        this.isDataLoaded = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.slotObj = {
            companyId: '',
            lockerId: '',
            cabinetId: '',
            type: Constant.SLOT_TYPE_SMALL,
            status: Constant.SLOT_STATUS_AVAILABLE,
            slotNumber: ''
        };
        this.activatedRoute.params.subscribe(params => {
            this.slotId = params['id'];
        });
    }

    ngOnInit() {
        if (this.activatedRoute.snapshot.queryParams['shipmentId']) {
            this.shipmentId = this.activatedRoute.snapshot.queryParams['shipmentId'];
        }
        this.fnGetCompanies();
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.isDataLoaded = true;
        this.companiesService.fnGetCompanies({ limitData: true })
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({ _id: '', name: 'Select Company' });
                if (this.slotId !== 'add') {
                    this.fnGetSlot(this.slotId);
                } else {
                    this.isDataLoaded = false;
                    if (this.currentUser.role.endsWith('_' + Constant.ROLE_ADMIN)) {
                        this.fnGetLockers({ companyId: this.currentUser.companyId });
                    }
                }
            });
    }

    /**
     * Get lockers
     * */
    fnGetLockers(params?: object) {
        this.cabinetsArr = [{ _id: '', cabinetNumber: '' }];
        this.slotObj.slotNumber = this.slotNumber;
        this.availableSlotNumbers = [];
        if (params['companyId']) {
            this.lockersService.getLockers(params, false)
                .then((lockersArr: any) => {
                    this.lockersArr = [];
                    this.lockersArr = lockersArr.length && lockersArr;
                    const lockerData = this.lockersArr.find(locker => locker._id === this.slotObj.lockerId);
                    if (lockerData) {
                       this.selectedLocker = lockerData.lockerNumber;
                    }
                    this.lockersArr.unshift({ _id: '', name: 'Select Locker' });
                });
        } else {
            this.lockersArr = [{ _id: '', name: 'Select Locker' }];
        }
    }

    /**
     * Get cabinets
     * */
    fnGetCabinets(params) {
        this.slotObj.slotNumber = this.slotNumber;
        this.availableSlotNumbers = [];
        if (params.lockerId) {
            this.cabinetsService.fnGetCabinets(params)
                .then((resp: SearchResults<Cabinet>) => {
                    this.cabinetsArr = [];
                    this.cabinetsArr = resp.data;
                     const cabinetData = this.cabinetsArr.find(cabinet => cabinet._id === this.slotObj.cabinetId);
                     if (cabinetData) {
                         this.selectedCabinet = cabinetData.type + ' - ' + cabinetData.cabinetNumber;
                     }
                    this.cabinetsArr.unshift({ _id: '', cabinetNumber: '' });
                });
        } else {
            this.cabinetsArr = [{ _id: '', cabinetNumber: '' }];
        }
    }

    getAvailableSlotNumbers() {
        this.slotObj.slotNumber = this.slotNumber;
        this.availableSlotNumbers = [];
        if (this.slotObj.cabinetId) {
            const existingSlotNumbers = [];
            for (const cabinet of this.cabinetsArr) {
                if (cabinet._id === this.slotObj.cabinetId) {
                    for (const slot of cabinet.slots) {
                        existingSlotNumbers.push(slot.slotNumber);
                    }
                    for (let number of this.slotNumberEnum) {
                        if (!existingSlotNumbers.includes(number)){
                            this.availableSlotNumbers.push(number);
                        }
                    }
                }
            }
        }
    }

    /**
     * Get slot
     * @param {string} id
     * */
    fnGetSlot(id: string) {
        this.slotsService.fnGetSlot(id)
            .then((slotObj: Slot) => {
                this.slotNumber = slotObj.slotNumber;
                this.isDataLoaded = false;
                this.slotObj = slotObj;
                this.preserveSlotStatus = slotObj.status;
                if (slotObj.isUpperSlot) {
                    this.isUpperSlot = 'True';
                }
                this.fnGetLockers({ companyId: this.slotObj.companyId });
                this.fnGetCabinets({ lockerId: this.slotObj.lockerId });
                const companyData = this.companiesArr.find(company => company._id === this.slotObj.companyId);
                if (companyData) {
                    this.selectedCompany = companyData.name;
                }
            });
    }

    unlinkShipment() {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to clear shipment in the slot?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.slotsService.unlinkShipment(this.slotId, this.slotObj.packageId)
                    .then(() => {
                        this.toastr.fnSuccess('Shipment removed successfully!');
                        this.router.navigateByUrl('/slots');
                    })
                    .catch((error) => {
                        this.toastr.fnError(error.message);
                    });
            }
        });
    }

    /**
     * Create/Update slot
     * @param {object} slotObj
     * */
    fnSaveSlot(slotObj: Slot) {
        slotObj.isUpperSlot = this.isUpperSlot === 'True' ? true : false;
        if (slotObj._id) {
            if (this.shipmentId && this.preserveSlotStatus !== slotObj.status) {
                this.toastr.fnError('There is a shipment associated with the slot. Please change the shipment status by clicking the View Shipment button.');
            } else {
                this.slotsService.fnUpdateSlot(slotObj)
                    .then(() => {
                        this.toastr.fnSuccess('Slot updated successfully.');
                        this.router.navigateByUrl('/slots');
                    })
                    .catch((error) => {
                        this.toastr.fnError(error.message);
                    });
            }
        } else {
            if (slotObj.reason) {
                slotObj.reason = slotObj.reason.trim();
            }
            if (!slotObj.companyId) {
                slotObj.companyId = this.currentUser.companyId;
            }
            if (!slotObj.lockerId) {
                slotObj.lockerId = this.currentSlot.lockerId;
            }
            this.slotsService.fnCreateSlot(slotObj)
                .then(() => {
                    this.toastr.fnSuccess('Slot created successfully.');
                    this.router.navigateByUrl('/slots');
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        }
    }

    onSlotStatusUpdate(status: string) {
        this.slotObj.reason = [this.CONSTANT.SLOT_STATUS_REPAIR, this.CONSTANT.SLOT_STATUS_NOT_IN_USE, this.CONSTANT.SLOT_STATUS_RESERVED].indexOf(status) === -1 ? null : '';
    }
}
