import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../common/auth.service';
import {ToastrService} from '../common/toastr.service';

@Component({
    selector: 'pp-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    public userId: string;
    public token: string;
    public isMatched: boolean;
    public newPassword: string;
    public confirmPassword: string;

    constructor(private activatedRoute: ActivatedRoute,
                private auth: AuthService,
                private router: Router,
                private toastr: ToastrService) {
        this.isMatched = true;
    }

    ngOnInit() {
        this.isMatched = false;
        this.activatedRoute.params.subscribe((params: any) => {
            this.userId = params.userId;
        });
        this.activatedRoute.queryParams.subscribe((params: any) => {
            this.token = params.token;
        });
    }

    async setPassword() {
        if (this.newPassword === this.confirmPassword) {
            this.isMatched = true;
            const response = await this.auth.setPassword(this.userId, this.newPassword, this.token);
            if (response) {
                this.toastr.fnSuccess('Your password is set!');
                this.router.navigateByUrl('/dashboard');
            } else {
                this.toastr.fnError('Sorry - something went wrong. Please try again later!');
            }
        } else {
            this.isMatched = false;
        }
    }

}
