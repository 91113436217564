import {Pipe, PipeTransform} from '@angular/core';

/**
 * Convert Date into local date string.
 */
@Pipe({name: 'CarrierName'})
export class CarrierNamePipe implements PipeTransform {
    transform(value: string): string {
        return value.split('_').join(' ');
    }
}
