import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constant} from '../../common/constant';

@Injectable({
  providedIn: 'root'
})
export class OutboundShipmentService {

  constructor(private http: HttpClient) { }

    getShipmentLabel(label: string, hostname: string) {
        return this.http.get(window.location.protocol + '//' + hostname + '/api/shipment/outbound-shipment/label/' + label, { responseType: 'arraybuffer' }).toPromise();
    }
}
