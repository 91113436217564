<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Company</h1>
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);" [routerLink]="['/companies']">Companies</a></li>
            <li *ngIf="!isDataLoaded">{{companyObj._id ? 'Edit' : 'Add'}} Company</li>
        </ul>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <div class="col-lg-12" [hidden]="isDataLoaded">
        <form #companyForm="ngForm"
              (ngSubmit)="companyForm.valid && fnSaveCompany(companyObj)" novalidate>
            <div class="d-flex">
                <fieldset class="col-md-3 fieldset m-r-35">
                    <legend class="fieldset-legend">Basic</legend>
                    <div class="form-group"
                         [ngClass]="{'has-error': (companyForm.submitted || company.touched) && !company.valid}">
                        <label for="company">Company Name<span class="text-danger">*</span></label>
                        <input id="company" type="text" class="form-control" name="company"
                               placeholder="Enter a company name" [pattern]="CONSTANT.US_ASCII_NAME_REG_EX && CONSTANT.BLANK_SPACE_REG_EX"
                               [readonly]="companyObj.name === CONSTANT.COMPANY_PUROLATOR"
                               [(ngModel)]="companyObj.name" #company="ngModel" required/>
                        <div *ngIf="(companyForm.submitted || company.touched) && !company.valid">
                            <small class="text-danger" *ngIf="company.errors['required']">Company name is required.
                            </small>
                            <small class="text-danger" *ngIf="company.errors['pattern']">Company name should not contain
                                any special characters and should not be blank.
                            </small>
                        </div>
                    </div>
                    <div class="form-group"
                         [ngClass]="{'has-error': (companyForm.submitted || brand.touched) && !brand.valid}">
                        <label for="brand">Brand<span class="text-danger">*</span></label>
                        <select id="brand" class="form-control" name="brand"
                                [(ngModel)]="companyObj.brand" #brand="ngModel" required>
                            <option value="">Select Brand</option>
                            <option *ngFor="let item of lockerSkin" [ngValue]="item">{{item}}</option>
                        </select>
                        <div *ngIf="(companyForm.submitted || brand.touched) && !brand.valid">
                            <small class="text-danger" *ngIf="brand.errors['required']">Brand name is required.</small>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="col-md-8 fieldset">
                    <legend class="fieldset-legend">Shipment timings and notifications</legend>

                    <div class="form-group">
                        <label for="delayMinutes">First shipment notification delay, in minutes</label>
                        <input id="delayMinutes" type="number" class="form-control" name="notificationDelay"
                               placeholder="Enter a delay minutes"
                               [(ngModel)]="companyObj.notificationDelay" #notificationDelay="ngModel"/>
                        <div
                            *ngIf="(companyForm.submitted || notificationDelay.touched) && !fnCheckNotificationDelay(notificationDelay.value)">
                            <small class="text-danger">Notification Delay Time Should be Between 1 to 1440</small>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="companyId !== 'add'">
                        <input type="checkbox" name="notifyExpiredPickup" id="notifyExpiredPickup"
                               [(ngModel)]="companyObj.notifyExpiredPickup"
                               [ngModelOptions]="{standalone: true}">
                        <label for="notifyExpiredPickup" class="m-l-7"> Send Thank-you notification when customers picks
                            up expired parcels.</label>
                    </div>

                    <div class="form-group">
                        <label for="shipmentExpirationTime">Shipment expiration time, in hours</label>
                        <input id="shipmentExpirationTime" class='form-control' type="number"
                               name="shipmentExpirationTime"
                               placeholder="Enter shipment expiration hours"
                               [(ngModel)]="companyObj.shipmentExpirationTime" #shipmentExpirationTime="ngModel"/>
                        <div
                            *ngIf="(companyForm.submitted || shipmentExpirationTime.touched) && !checkNumberRange(shipmentExpirationTime.value, 1)">
                            <small class="text-danger">Shipment expiration time should not be less than 1.</small>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="companyId !== 'add'">
                        <input type="checkbox" name="excludeWeekends" id="excludeWeekends"
                               [(ngModel)]="companyObj.excludeWeekends"
                               [ngModelOptions]="{standalone: true}">
                        <label for="excludeWeekends" class="m-l-7"> Exclude weekends and holidays for calculating
                            expiration time.</label>
                    </div>

                    <div class="form-group">
                        <input type="checkbox" name="allowUserArchival" id="allowUserArchival"
                               [(ngModel)]="companyObj.allowUserArchival"
                               [ngModelOptions]="{standalone: true}">
                        <label for="allowUserArchival" class="m-l-7"> Archive users during directory import.</label>
                    </div>

                    <div class="form-group">
                        <label for="extendExpirationTime">Extend Expiration time</label>
                        <select id="extendExpirationTime" class="form-control" name="extendExpirationTime"
                                [(ngModel)]="companyObj.extendExpirationTime" #extendExpirationTime="ngModel">
                            <option value="">No</option>
                            <option value="21:00:00">09:00 PM</option>
                            <option value="23:55:00">11:55 PM</option>
                        </select>
                    </div>
                </fieldset>
            </div>
            <pp-location [locationId]="companyObj.locationId" [form]="companyForm" [placeholderText]="'Enter a company address'"></pp-location>

            <div class="form-group">
                <button class="btn btn-primary m-r-4" type="submit">
                    {{companyObj._id ? 'Update Company' : 'Create Company'}}
                </button>
                <button class="btn btn-warning" [routerLink]="['/companies']">Cancel</button>
            </div>
        </form>
    </div>
</div>
