import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AuthService} from './auth.service';

@Injectable()
export class NotAuthenticateGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url: string = state.url;
        return this.fnCheckAuthenticate(url);
    }

    fnCheckAuthenticate(url: string): boolean {

        if (!this.auth.fnGetToken()) {
            return true;
        } else {
            if (this.auth.fnGetCurrentUser() && this.auth.fnGetCurrentUser().role) {
                this.router.navigate(['/shipments']);
            } else {
                this.router.navigate(['/verify-otp']);
            }
        }

        return false;
    }

}
