import {Component, OnInit, Input, AfterViewInit, ViewChild, ChangeDetectorRef, HostListener} from '@angular/core';

import {Location} from './location';

declare let google: any;

@Component({
    selector: 'pp-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.css']
})

export class LocationComponent implements OnInit, AfterViewInit {

    @ViewChild('location', {static: false}) location: string;
    @Input() public locationId: Location;
    @Input() public form: any;
    @Input() public placeholderText: string;
    @Input() public isReadOnly: boolean;
    public autoComplete: any;
    public addressTypeArr = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'country', 'postal_code', 'postal_code_prefix'];

    constructor(private chRef: ChangeDetectorRef) {
        this.locationId = {formattedAddress: '', placeId: ''};
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if(event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.fnInitGMAutoComplete();
    }

    fnInitGMAutoComplete() {
        const self = this;
        this.autoComplete = new google.maps.places.Autocomplete(
            (document.getElementById('location')),
            {types: ['geocode']});
        google.maps.event.addListener(this.autoComplete, 'place_changed', () => {
            self.fnFillInAddress(self);
        });
    }

    fnFillInAddress(self) {
        // Get the place details from the auto complete object.
        const place = self.autoComplete.getPlace();
        self.locationId.formattedAddress = place.formatted_address;
        self.locationId.placeId = place.place_id;
        self.locationId.latitude = place.geometry.location.lat();
        self.locationId.longitude = place.geometry.location.lng();

        // Get each component of the address from the place details
        // and fill the corresponding field on the form.
        for (let intIndex = 0; intIndex < place.address_components.length; intIndex++) {
            const addressType = place.address_components[intIndex].types[0];
            if (self.addressTypeArr.indexOf(addressType) > -1) {
                const val = place.address_components[intIndex];
                if (addressType === 'street_number') {
                    self.locationId.streetNumber = val.short_name;
                } else if (addressType === 'route') {
                    self.locationId.route = val.long_name;
                } else if (addressType === 'locality') {
                    self.locationId.city = val.long_name;
                } else if (addressType === 'administrative_area_level_1') {
                    self.locationId.state = val.long_name;
                    self.locationId.stateCode = val.short_name;
                } else if (addressType === 'country') {
                    self.locationId.country = val.long_name;
                    self.locationId.countryCode = val.short_name;
                } else if (addressType === 'postal_code' || addressType === 'postal_code_prefix') {
                    self.locationId.postalCode = val.short_name;
                }
            }
        }
        self.chRef.detectChanges();
    }
}
