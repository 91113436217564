import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import {TransactionsService} from './transactions.service';
import {AuthService} from '../../common/auth.service';
import {StorageService} from '../../common/storage.service';
import {UsersService} from '../users/users.service';
import {User} from '../users/user';
import {Constant} from '../../common/constant';
import {LockersService} from '../lockers/lockers.service';
import {Locker} from '../lockers/locker';
import {CompaniesService} from '../companies/companies.service';
import {Company} from '../companies/company';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable , Subscription, mergeMap} from 'rxjs';
import {ExportService} from '../../common/export.service';
import * as moment from 'moment';
import {TRANSACTION_ACTION} from '../../common/enums';
import {Cabinet} from '../cabinets/cabinet';
import {Slot} from '../slots/slot';
import {CabinetsService} from '../cabinets/cabinets.service';
import {SlotsService} from '../slots/slots.service';
import {ToastrService} from '../../common/toastr.service';
import {SearchResultsWithMetadata} from '../../common/search-results';
import {debounceTime, filter, tap} from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'pp-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css']
})

export class TransactionsComponent implements OnInit, AfterViewInit {
    @ViewChild(DaterangePickerComponent, {static: false})
    private picker: DaterangePickerComponent;
    public filterObj: any;
    public selectedUser: any;
    public isDataLoaded: boolean;
    public isExporting: boolean;
    public invalidDateRange: boolean;
    public selectedCreatedAt: any;
    public perPageArr: Array<number> = [10, 25, 50, 100, 250];
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];
    public actions: Array<any> = [{_id: '', name: 'Select Action'}];
    public cabinetsArr: Array<Cabinet> = [{_id: '', cabinetNumber: 'Select Cabinet'}];
    public slotsArr: Array<Slot> = [{_id: '', slotNumber: 'Select Slot'}];
    public usersArr: Array<User> = [{_id: '', firstName: 'Select', lastName: 'User'}];
    public transactionsArr: Array<any> = [];
    public currentUser: User;
    public totTransactions: number;
    readonly CONSTANT = Constant;
    private TRAN_FILTER = 'tranFilter';
    dataSource: Observable<any>;
    public isAllShipments;
    paramsSubscription: Subscription;
    public previousToken = '';
    public previousInputValue = '';

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private transactionsService: TransactionsService,
                private auth: AuthService, private storage: StorageService, private lockersService: LockersService,
                private cabinetsService: CabinetsService, private slotsService: SlotsService, private toastr: ToastrService,
                private companiesService: CompaniesService, private usersService: UsersService, private exportService: ExportService) {
        this.isDataLoaded = false;
        this.isExporting = false;
        this.invalidDateRange = false;
        this.totTransactions = 0;
        this.isAllShipments = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.filterObj = {
            sortBy: '',
            orderBy: '',
            page: 0,
            limit: 0,
            userId: '',
            lockerId: '',
            action: '',
            companyId: '',
            cabinetId: '',
            slotId: '',
        };

        if (this.activatedRoute.snapshot.queryParams['startDate'] && this.activatedRoute.snapshot.queryParams['endDate']) {
            this.filterObj.startDate = this.activatedRoute.snapshot.queryParams['startDate'];
            this.filterObj.endDate = this.activatedRoute.snapshot.queryParams['endDate'];
            this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        } else {
            this.filterObj.startDate = this.getDefaultDateRange().startDate;
            this.filterObj.endDate = this.getDefaultDateRange().endDate;
            this.selectedCreatedAt = '';
        }
        if (!this.storage.fnGetData(this.TRAN_FILTER)) {
            this.filterObj.sortBy = 'transactionAt';
            this.filterObj.orderBy = 'desc';
            this.filterObj.limit = 10;
            this.filterObj.page = 1;
            this.filterObj.startDate = this.getDefaultDateRange().startDate;
            this.filterObj.endDate = this.getDefaultDateRange().endDate;
            this.storage.fnStoreData(this.TRAN_FILTER, this.filterObj);
        }
        if (this.storage.fnGetData('selectedUser')) {
            this.selectedUser = this.storage.fnGetData('selectedUser');
            this.selectedUser = Object.keys(this.selectedUser).length ? this.selectedUser : '';
        }
        this.getActions();
        this.fnMergeQueryParams();
        this.previousInputValue = this.selectedUser;
    }

    async ngOnInit() {
        this.fnGetCompanies();
        this.dataSource = Observable.create((observer: any) => {
            observer.next(this.selectedUser || '');
          }).pipe(
            debounceTime(500),
            filter((data: string) => this.previousToken !== data.trim()),
            mergeMap((token: string) => {
                this.previousToken = token && token.trim();
                if (!this.filterObj.companyId) {
                    this.toastr.fnWarning('Please select company to search the users');
                    return of([]);
                }
                return this.previousToken ? this.filterResults(token) : of([]);
            }));
        this.fnSetQueryParams(this.filterObj);
        this.fnGetTransactions(this.filterObj);
    }

    ngAfterViewInit(): void {
        this.picker.datePicker.setStartDate(this.filterObj.startDate);
        this.picker.datePicker.setEndDate(this.filterObj.endDate);
    }

    getDefaultDateRange() {
        const dateRange = {startDate: null, endDate: null};
        dateRange.startDate = moment().subtract(Constant.DEFAULT_DATE_RANGE_DAYS, 'd').format('MM/DD/YYYY');
        dateRange.endDate = moment().format('MM/DD/YYYY');
        return dateRange;
    }

    changeDateRange() {
        this.invalidDateRange = false;
        if (!this.selectedCreatedAt) {
            this.toastr.fnError('Please select the date range to load transactions history');
        }
    }

    getActions() {
        let actions = [];
        Object.keys(TRANSACTION_ACTION).map(key => {
            actions.push({_id: TRANSACTION_ACTION[key], name: TRANSACTION_ACTION[key]});
        });
        actions = actions.sort((a, b) => a.name.localeCompare(b.name));
        this.actions = this.actions.concat(actions);
    }

    filterResults(token: string) {
        return this.usersService.fnGetUsers({companyId: this.filterObj.companyId, name: token});
    }

    fnTypeAheadOnBlur(event: any): void {
        this.previousToken = '';
        const selectedUser = this.selectedUser && this.selectedUser.trim();
        const inputValue = event.target.value && event.target.value.trim();
        if (!selectedUser && !inputValue && this.previousInputValue !== inputValue) {
            delete this.filterObj.receiver;
            this.fnChangeUser(this.filterObj);
        }
        this.previousInputValue = inputValue;
    }

    /**
     * Merge local storage filter object into query params.
     * */
    fnMergeQueryParams() {
        this.paramsSubscription = this.activatedRoute.queryParams.subscribe((params) => {
            const storedFilterObj = this.storage.fnGetData(this.TRAN_FILTER);
            if (!_.isEmpty(storedFilterObj)) {
                _.forEach(params, (value, key) => {
                    if (key === 'limit') {
                        const limit = parseInt(value, 10);
                        this.filterObj[key] = this.perPageArr.indexOf(limit) > -1 ? limit : 10;
                    } else if (key === 'page') {
                        this.filterObj[key] = parseInt(value, 10);
                    } else if (key === 'name') {
                        this.selectedUser = value;
                        this.storage.fnStoreData('selectedUser', this.selectedUser);
                    } else {
                        this.filterObj[key] = value;
                    }
                });
                _.forEach(storedFilterObj, (value, key) => {
                    if (!this.filterObj[key] && Object.keys(params).indexOf(key) < 0) {
                        this.filterObj[key] = value;
                    }
                });
            } else {
                this.filterObj.sortBy = 'transactionAt';
                this.filterObj.orderBy = 'desc';
                this.filterObj.page = 1;
                this.filterObj.limit = 10;
            }
            if (this.filterObj.userId === this.currentUser._id) {
                this.filterObj.companyId = '';
            }
            if (storedFilterObj && Object.keys(storedFilterObj).length && params.userId === storedFilterObj.userId) {
                   this.filterObj.cabinetId = storedFilterObj.cabinetId;
                   this.filterObj.slotId = storedFilterObj.slotId;
            } else {
                this.filterObj.cabinetId = '';
                this.filterObj.slotId = '';
            }
            this.fnSetQueryParams(this.filterObj);
        });
        this.paramsSubscription.unsubscribe();
    }

    /**
     * Set filter object in url params
     * @param {any} filterObj
     * */
    fnSetQueryParams(filterObj: any) {
        this.router.navigate(['/transactions'], {
            skipLocationChange: true,
            queryParams: _.pickBy(filterObj, (value) => {
                return value;
            })
        });
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    fnSetSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    fnSortBy(sortBy: string) {
        this.isDataLoaded = false;
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'asc') {
                this.filterObj.sortBy = 'transactionAt';
                this.filterObj.orderBy = 'desc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }
        this.fnSetQueryParams(this.filterObj);
        this.storage.fnStoreData(this.TRAN_FILTER, this.filterObj);
        const paramForApi = _.assign({}, this.filterObj);
        paramForApi.startDate = new Date(paramForApi.startDate).toISOString();
        paramForApi.endDate = new Date(paramForApi.endDate).toISOString();
        this.transactionsService.fnGetTransactions(paramForApi)
            .then((res: any) => {
                this.isDataLoaded = true;
                this.totTransactions = res[0].metadata.length && res[0].metadata[0].total;
                this.transactionsArr = res[0].data.map((obj: any) => {
                    obj.isShipments = false;
                    return obj;
                });
            });
    }

    fnSelectedDate(value: any) {
        if (value) {
            this.filterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
            this.filterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
            const dayDiff = moment(this.filterObj.endDate).diff(moment(this.filterObj.startDate), 'days');
            this.invalidDateRange = dayDiff > Constant.MAX_DATE_RANGE_DAYS;
        } else {
            this.filterObj.startDate = this.getDefaultDateRange().startDate;
            this.filterObj.endDate = this.getDefaultDateRange().endDate;
            this.invalidDateRange = false;
        }
        this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        this.filterObj.page = 1;
        this.fnSetQueryParams(this.filterObj);
        this.fnGetTransactions(this.filterObj);
    }

    /**
     * Change company pull down
     * @param {any} filterObj
     * */
    fnChangeCompany(filterObj: any) {
        this.filterObj.page = 1;
        const params = {companyId: this.filterObj.companyId};
        this.selectedUser = '';
        this.filterObj.cabinetId = '';
        this.filterObj.slotId = '';
        // this.filterObj.userId = this.selectedUser ? '' : '';
        this.fnGetLockers(params);
        this.fnSetQueryParams(filterObj);
        this.fnGetTransactions(filterObj);
    }

    /**
     * Change user pull down
     * @param {any} filterObj
     * */
    fnChangeUser(filterObj: any) {
        this.filterObj.page = 1;
        this.fnSetQueryParams(filterObj);
        this.fnGetTransactions(filterObj);
    }

    /**
     * Change locker pull down
     * @param {any} filterObj
     * */
    fnChangeLocker(filterObj: any) {
        this.filterObj.page = 1;
        this.filterObj.cabinetId = '';
        this.filterObj.slotId = '';
        this.getCabinets();
        this.fnSetQueryParams(filterObj);
        this.fnGetTransactions(filterObj);
    }

    /**
     * Change action pull down
     * @param {any} filterObj
     * */
    changeAction(filterObj: any) {
        this.filterObj.page = 1;
        this.fnSetQueryParams(filterObj);
        this.fnGetTransactions(filterObj);
    }

     /** Change cabinet pull down
     * @param {any} params
     * */
    changeCabinet(params?: any) {
        this.filterObj.page = 1;
        this.filterObj.slotId = '';
        this.getSlots();
        this.fnSetQueryParams(this.filterObj);
        this.fnGetTransactions(this.filterObj);
    }

    /**
     * Change slot pull down
     * @param {any} params
     * */
    changeSlot(params?: any) {
        this.filterObj.page = 1;
        this.fnSetQueryParams(this.filterObj);
        this.fnGetTransactions(this.filterObj);
    }

    /**
     * Change pagination
     * @param {any} event
     * */
    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnSetQueryParams(this.filterObj);
        this.fnGetTransactions(this.filterObj);
    }

    /**
     * Change per page
     * @param {any} filterObj
     * */
    fnPerPageChanged(filterObj: any) {
        this.fnSetQueryParams(filterObj);
        this.fnGetTransactions(filterObj);
    }

    /**
     * Clear filter
     * */
    async fnClearFilters() {
        this.filterObj = {
            sortBy: 'transactionAt', orderBy: 'desc', page: 1, limit: 10,
            userId: '', lockerId: '', action: '', companyId: '',
            cabinetId: '', slotId: ''
        };
        this.fnClearDate(false);
        this.selectedUser = '';
        this.storage.fnRemove(this.TRAN_FILTER);
        this.filterObj.companyId = this.currentUser.role === Constant.ROLE_ADMIN ? '' : this.currentUser.companyId;
        this.fnMergeQueryParams();
        this.storage.fnRemove('selectedUser');
    }

    fnClearDate(doNavigation: boolean) {
        this.filterObj.startDate = this.getDefaultDateRange().startDate;
        this.filterObj.endDate = this.getDefaultDateRange().endDate;
        this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        this.invalidDateRange = false;
        this.picker.datePicker.setStartDate(this.filterObj.startDate);
        this.picker.datePicker.setEndDate(this.filterObj.endDate);
        this.filterObj.page = 1;
        if (doNavigation) {
            this.fnSetQueryParams(this.filterObj);
        }
        this.fnGetTransactions(this.filterObj);
    }

    /**
     * Get Transactions
     * @param {any} params
     * */
    fnGetTransactions(params?: any) {
        if (!this.invalidDateRange) {
            this.isDataLoaded = false;
            this.isAllShipments = false;
            this.storage.fnStoreData(this.TRAN_FILTER, params);
            this.storage.fnStoreData('selectedUser', this.selectedUser);
            const paramForApi = JSON.parse(JSON.stringify(params));
            paramForApi.startDate = new Date(params.startDate).toISOString();
            paramForApi.endDate = new Date(params.endDate).toISOString();
            this.transactionsService.fnGetTransactions(paramForApi)
                .then((res: any) => {
                    this.isDataLoaded = true;
                    this.totTransactions = res[0].metadata.length && res[0].metadata[0].total;
                    this.transactionsArr = res[0].data.length && res[0].data;
                    if (params.userId && (!this.selectedUser || this.selectedUser === '') && res[0].data.length > 0) {
                        this.selectedUser = res[0].data[0].userId.firstName + ' ' + res[0].data[0].userId.lastName;
                    }
                    if (params.startDate && params.endDate && (!this.selectedCreatedAt || this.selectedCreatedAt === '')) {
                        this.selectedCreatedAt = params.startDate + ' - ' + params.endDate;
                    }
                }).catch((error) => {
                    console.error('TransactionsComponent: fnGetTransactions(): error', error);
                    if (error && error.message) {
                        this.toastr.fnError(error.message);
                    } else {
                        this.toastr.fnError('Facing difficulties in fetching data');
                    }
                });
        }
    }

    /**
     * Get companies
     * */
    async fnGetCompanies() {
        const companiesArr: any = await this.companiesService.fnGetCompanies({limitData: true});
        this.companiesArr = companiesArr;
        this.companiesArr.unshift({_id: '', name: 'Select Company'});
        let params;
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            params = {companyId: this.filterObj.companyId};
        } else {
            this.filterObj.companyId = this.currentUser.companyId;
            params = {companyId: this.filterObj.companyId};
        }
        this.fnGetLockers(params);
    }

    /**
     * Get list of lockers
     * @param {any} params
     * */
    async fnGetLockers(params?: any) {
        if (!params.companyId) {
            this.lockersArr = [{_id: '', name: 'Select Locker'}];
        } else {
            const lockersArr = <Array<SearchResultsWithMetadata<Locker>>> await this.lockersService.getLockers(params, true);
            this.lockersArr = lockersArr[0].data;
            this.lockersArr.unshift({_id: '', name: 'Select Locker'});
            this.getCabinets();
        }
    }

    /**
     * Get list of cabinets
     * */
    getCabinets() {
        this.cabinetsArr = [{_id: '', cabinetNumber: 'Select Cabinet'}];
        _.filter(this.lockersArr, locker => {
            if (locker._id === this.filterObj.lockerId && locker.cabinets) {
                this.cabinetsArr = [...locker.cabinets];
                if (this.cabinetsArr) {
                    this.cabinetsArr.unshift({_id: '', cabinetNumber: 'Select Cabinet'});
                }
            }
        });
        this.getSlots();
    }

    /**
     * Get list of slots
     * */
    getSlots() {
        this.slotsArr = [{_id: '', slotNumber: 'Select Slot'}];
        _.filter(this.cabinetsArr, cabinet => {
            if (cabinet._id === this.filterObj.cabinetId && cabinet.slots) {
                this.slotsArr = [...cabinet.slots];
                if (this.slotsArr) {
                    this.slotsArr.unshift({_id: '', slotNumber: 'Select Slot'});
                }
            }
        });
    }

    /**
     * Get list of users
     * @param {any} params
     * */
    fnGetUsers(params?: any) {
        this.usersService.fnGetUsers(params)
            .then((usersArr: Array<User>) => {
                this.usersArr = usersArr;
            });
    }

    fnOnSelectUser(event: TypeaheadMatch): void {
        this.filterObj.userId = event.item._id;
        this.selectedUser = event.item.firstName + ' ' + event.item.lastName;
        this.fnChangeUser(this.filterObj);
    }

    fnToggleAllShipments() {
        this.isAllShipments = !this.isAllShipments;
        this.transactionsArr = this.transactionsArr.map((obj: any) => {
            obj.isShipments = this.isAllShipments;
            return obj;
        });
    }

    fnToggleShipments(transObj: any) {
        transObj.isShipments = !transObj.isShipments;
        // Check if all true
        this.isAllShipments = !!this.transactionsArr.map((obj: any) => obj.isShipments).reduce((a: any, b: any) => {
            return (a === b) ? a : NaN;
        });
    }

    fnExportCSV() {
        this.isExporting = true;
        const exportFilter = _.assign({}, this.filterObj);
        exportFilter.page = 1;
        exportFilter.limit = 10000;
        exportFilter.startDate = new Date(exportFilter.startDate).toISOString();
        exportFilter.endDate = new Date(exportFilter.endDate).toISOString();
        this.transactionsService.fnGetTransactions(exportFilter)
            .then((res: any) => {
                const transactions = res[0].data;
                const csvArr = [];
                _.forEach(transactions, element => {
                    if (element.slotId && element.slotId.cabinetId && element.slotId.cabinetId.cabinetNumber && element.slotId.slotNumber) {
                        element.slot = element.slotId.cabinetId.cabinetNumber + element.slotId.slotNumber;
                    } else {
                        element.slot = '';
                    }

                    if (element.userId) {
                        element.user = !element.userId.lastName
                            ? element.userId.firstName + ' ' + element.userId.lastName : element.userId.firstName;
                        element.userCode = element.userId.code ? element.userId.code : '';
                    } else {
                        element.user = '';
                        element.userCode = '';
                    }
                    element.shipmentSlot = element.slot;
                    csvArr.push(element);
                });
                const headers = ['lockerId.companyId.name', 'lockerId.name', 'slot',
                    'transactionAt', 'action', 'description', 'packageStatus',
                    'slotStatus', 'createdBy', 'user', 'userCode', 'shipmentId.carrierName',
                    'shipmentId.status', 'shipmentId.updatedAt', 'shipmentSlot',
                    'packageId.label', 'packageId.status', 'packageId.size'];
                this.exportService.fnDownloadCSV('Transactions', csvArr, headers);
                this.isExporting = false;
            });
    }

    fnOnDateChange(value: Date): void {
        if (value) {
            this.filterObj.startDate = moment(value[0]).format('MM/DD/YYYY');
            this.filterObj.endDate = moment(value[1]).format('MM/DD/YYYY');
        } else {
            delete this.filterObj.startDate;
            delete this.filterObj.endDate;
        }
        this.fnChangeTransactions(this.filterObj);
    }

    fnChangeTransactions(filterObj: any) {
        this.filterObj.page = 1;
        // this.fnSetQueryParams(filterObj);
        this.fnGetTransactions(filterObj);
    }

    getLocalDate(date) {
        return new Date(date).toLocaleString();
    }
}
