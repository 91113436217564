import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import {AuthService} from '../../common/auth.service';
import {User} from '../users/user';
import {Constant} from '../../common/constant';
import {ActivatedRoute, Router} from '@angular/router';
import {ShipmentsService} from '../shipments/shipments.service';
import {Shipment} from '../shipments/shipment';

@Component({
  selector: 'pp-user-shipments',
  templateUrl: './user-shipments.component.html',
  styleUrls: ['./user-shipments.component.css']
})
export class UserShipmentsComponent implements OnInit {
    public currentUser: User;
    public selectedCreatedAt: any;
    public selectedExpireDate: any;
    public filterObj: any;
    public isDataLoaded;
    public isDevURL: boolean;
    public shipmentArr: Array<Shipment> = [];
    readonly CONSTANT = Constant;
    public totShipments: number;

    constructor(private _bsModalService: BsModalService, private activatedRoute: ActivatedRoute,
                private shipmentService: ShipmentsService, private router: Router,
                private auth: AuthService) {

        this.currentUser = this.auth.fnGetCurrentUser();
        this.isDataLoaded = false;
        this.isDevURL = false;
        this.totShipments = 0;
        this.filterObj = {
            sortBy: 'createdAt',
            orderBy: 'desc',
            startDate: this.activatedRoute.snapshot.queryParams['startDate'],
            endDate: this.activatedRoute.snapshot.queryParams['endDate'],
            userId: '',
            companyId: '',
            lockerId: '',
            page: 1,
            limit: 10
        };
        this.selectedCreatedAt = this.filterObj.startDate && this.filterObj.endDate ?
            this.filterObj.startDate + '-' + this.filterObj.endDate : '';
    }

    ngOnInit() {
        this.getInitialData();
    }

    getInitialData() {
        this.checkDevURL();
        this.filterObj.userId = this.currentUser._id;
        this.getShipments(this.filterObj);
    }

    onSelectedDate(value: any) {
        if (value) {
            this.filterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
            this.filterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
            this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        } else {
            delete this.filterObj.startDate;
            delete this.filterObj.endDate;
        }
        this.changeShipments(this.filterObj);
    }

    clearDate() {
        delete this.filterObj.startDate;
        delete this.filterObj.endDate;
        this.selectedCreatedAt = '';
        this.changeShipments(this.filterObj);
    }

    changeShipments(filterObj: any) {
        this.filterObj.page = 1;
        this.setQueryParams(filterObj);
        this.getShipments(filterObj);
    }

    /**
     * Set filter object in url params
     * @param {any} filterObj
     * */
    setQueryParams(filterObj: any) {
        this.router.navigate(['/shipment-history'], {
            queryParams: _.pickBy(filterObj, (value) => {
                return value;
            })
        });
    }

    clearFilters() {
        this.selectedCreatedAt = '';
        this.filterObj = {
            sortBy: 'createdAt',
            orderBy: 'desc',
            startDate: '',
            endDate: '',
            page: 1,
            limit: 10
        };
        this.setQueryParams(this.filterObj);
        this.getShipments(this.filterObj);
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        this.shipmentService.getUserShipments(this.currentUser._id, this.filterObj)
            .then((res: any) => {
                this.isDataLoaded = true;
                this.totShipments = res.total;
                this.shipmentArr = res.data;
            });
    }

    checkDevURL() {
        if (window.location.host === Constant.DEV_API_URL) {
            this.isDevURL = true;
        } else {
            this.isDevURL = false;
        }
    }


    /**
     * Get Shipments
     * @param {object} params
     * */
    async getShipments(params?: object) {
        this.isDataLoaded = false;
        let res;
        res = await this.shipmentService.getUserShipments(this.currentUser._id, params);
        this.isDataLoaded = true;
        this.totShipments = res.total;
        this.shipmentArr = res.data;
    }

    /**
     * Page changed event
     * @param {any} event
     * */
    pageChanged(event: any) {
        this.filterObj.page = event.page;
        this.getShipments(this.filterObj);
    }
}
