import * as _ from 'lodash';

import {User} from '../authenticate/users/user';
import {Constant} from '../common/constant';
import {UserShipmentsComponent} from '../authenticate/user-shipments/user-shipments.component';

/** List of application screens */
export enum SCREEN {
    DASHBOARD = 'Dashboard',
    SETTINGS = 'Settings',
    SUPPORT = 'Support',
    COMPANIES = 'Companies',
    PAYMENT_PLANS = 'PaymentPlans',
    LOCKERS = 'Lockers',
    COMPANY_LOCKERS = 'CompanyLockers',
    CABINETS = 'Cabinets',
    SLOTS = 'Slots',
    SHIPMENTS = 'Shipments',
    USERS = 'Users',
    LOCKER_DIRECTORY = 'LockerDirectory',
    USER_SHIPMENTS = 'UserShipmentsComponent',
    PENDING_SHIPMENTS = 'PendingShipmentsComponent',
    ENROLLMENT_USERS = 'EnrollmentUsers',
    TRANSACTIONS = 'Transactions',
    ANALYTICS = 'Analytics',
    NOTIFICATIONS = 'Notifications',
    FEEDBACK = 'Feedback',
    BUILDING_DATA = 'BuildingData',
    MANAGE_APK = 'ManageAPK',
    PAYMENT_DETAILS = 'PaymentDetails',
    OUTBOUND_SHIPMENTS = 'OutboundShipments'
}

const userScreens = new Set<SCREEN>()
    .add(SCREEN.SETTINGS)
    .add(SCREEN.SUPPORT)
    .add(SCREEN.PAYMENT_DETAILS)
    .add(SCREEN.USER_SHIPMENTS);

const companyAdminScreens = new Set<SCREEN>()
    .add(SCREEN.SETTINGS)
    .add(SCREEN.COMPANY_LOCKERS)
    .add(SCREEN.SHIPMENTS)
    .add(SCREEN.PENDING_SHIPMENTS)
    .add(SCREEN.LOCKER_DIRECTORY)
    .add(SCREEN.ENROLLMENT_USERS);

const adminScreens = new Set<SCREEN>()
    .add(SCREEN.DASHBOARD)
    .add(SCREEN.FEEDBACK)
    .add(SCREEN.LOCKERS)
    .add(SCREEN.MANAGE_APK)
    .add(SCREEN.NOTIFICATIONS)
    .add(SCREEN.PAYMENT_PLANS)
    .add(SCREEN.SETTINGS)
    .add(SCREEN.SHIPMENTS)
    .add(SCREEN.PENDING_SHIPMENTS)
    .add(SCREEN.SLOTS)
    .add(SCREEN.TRANSACTIONS)
    .add(SCREEN.USERS)
    .add(SCREEN.LOCKER_DIRECTORY)
    .add(SCREEN.COMPANIES)
    .add(SCREEN.CABINETS)
    .add(SCREEN.ANALYTICS)
    .add(SCREEN.BUILDING_DATA)
    .add(SCREEN.OUTBOUND_SHIPMENTS)
    ;

const permissionMatrix = new Map<string, Set<SCREEN>>()
    .set(Constant.ROLE_ADMIN, adminScreens)
    .set(Constant.ROLE_COMPANY_ADMIN, companyAdminScreens)
    .set(Constant.ROLE_USER, userScreens);


export abstract class PermissionUtils {
    /**
     * @param USER user object - current user object.
     * @param SCREEN screen enum - screen name.
     * @Response return screen access permission based on USER role.
     */
    public static canSeeScreen(user: User, screen: SCREEN): boolean {
        if (_.isEmpty(user)) {
            return false;
        }

        const userRole = user.role;
        if (_.isEmpty(userRole)) {
            return false;
        }

        if (!permissionMatrix.has(userRole)) {
            return false;
        }

        const availableScreens: Set<SCREEN> = permissionMatrix.get(userRole);

        return availableScreens.has(screen);

    }
}
