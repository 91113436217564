import {Component, OnInit} from '@angular/core';
import {Constant} from '../../common/constant';
import {SLOT_SIZE} from '../../common/enums';
import {Company} from '../companies/company';
import {User} from '../users/user';
import {AuthService} from '../../common/auth.service';
import {CompaniesService} from '../companies/companies.service';
import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from '../../common/toastr.service';
import * as _ from 'lodash';
import {PaymentPlanService} from './payment-plan.service';
import {PaymentPlan} from './payment-plans';

@Component({
    selector: 'pp-payment-plans',
    templateUrl: './payment-plans.component.html',
    styleUrls: ['./payment-plans.component.css']
})
export class PaymentPlansComponent implements OnInit {
    selectedCompany: string;
    public companiesArr: Array<Company> = [];
    public planArr: Array<PaymentPlan> = [];
    public currentUser: User;
    public isDataLoaded;
    public filterObj: any;
    public CONSTANT = Constant;
    public SLOT_SIZE = SLOT_SIZE;

    constructor(private planService: PaymentPlanService,
                private companiesService: CompaniesService,
                private auth: AuthService, private toastr: ToastrService,
                private _bsModalService: BsModalService) {
        this.isDataLoaded = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.filterObj = {
            companyId: '',
            sortBy: 'name',
            orderBy: 'asc',
            page: 1,
            limit: 10
        };
    }

    async ngOnInit() {
        await this.getCompanies();
        this.filterObj.companyId = this.selectedCompany;
        await this.getPlans(this.filterObj);
    }

    /**
     * Get companies
     * */
    async getCompanies(): Promise<void> {
        this.isDataLoaded = false;
        try {
            switch (this.currentUser.role) {
                case this.CONSTANT.ROLE_ADMIN:
                    const companiesRes: any = await this.companiesService.fnGetCompanies();
                    this.companiesArr = companiesRes;
                    this.selectedCompany = this.companiesArr[0]._id;
                    break;
                case this.CONSTANT.ROLE_COMPANY_ADMIN:
                    this.selectedCompany = this.currentUser.companyId;
                    break;
            }
        } catch (e) {
            console.error('LockerDirectoryComponent: getCompanies(): error', e);
            this.isDataLoaded = true;
        }
    }

    /**
     * Get all plans
     * */
    getPlans(params?: object) {
        this.isDataLoaded = false;
        this.planService.getPlans(params)
            .then((res: any) => {
                this.planArr = res;
                this.isDataLoaded = true;
            });
    }

    /**
     * Change company
     * */
    async companyChanged() {
        this.filterObj.companyId = this.selectedCompany;
        await this.getPlans(this.filterObj);
    }

    /**
     * Delete plan
     * @param {string} id
     * */
    deletePlan(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, { 'class': 'modal-sm' });
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this plan?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.planService.deletePlan(id)
                    .then(() => {
                        _.remove(this.planArr, function (plan) {
                            return plan._id === id;
                        });
                    })
                    .catch((error) => {
                        this.toastr.fnError(error.message);
                    });
            }
        });
    }
}
