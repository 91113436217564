import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import { AuthService } from '../../../common/auth.service';
import { StorageService } from '../../../common/storage.service';
import { CompaniesService } from '../../companies/companies.service';
import { CompanyLockersService } from './company-lockers.service';
import { CabinetsService } from '../../cabinets/cabinets.service';
import { SlotsService } from '../../slots/slots.service';
import { Locker, LOCKER_CODES_TYPE } from '../../lockers/locker';
import { User } from '../../users/user';
import { Company } from '../../companies/company';
import { Constant } from '../../../common/constant';
import { ToastrService } from '../../../common/toastr.service';
import {ExportService} from '../../../common/export.service';
import {Router} from '@angular/router';
import {LockersService} from '../../lockers/lockers.service';
import {Slot} from '../../slots/slot';

@Component({
  selector: 'pp-company-lockers',
  templateUrl: './company-lockers.component.html',
  styleUrls: ['./company-lockers.component.css']
})
export class CompanyLockersComponent implements OnInit {
    public isDataLoaded;
    public isExporting: boolean;
    public isAllCabinets;
    public totalLockers: number;
    public filterObj: any;
    public currentUser: User;
    public lockersArr: Array<Locker> = [];
    public companiesArr: Array<Company> = [{ _id: '', name: 'Select Company' }];
    readonly CONSTANT = Constant;

    public alphaNumRegEx: string = '[a-zA-Z0-9]*';
    selectedAccessCodeIndex: number = -1;
    selectedLocker:any;
    accessCodeEditInProgressModel: BsModalRef;
    returnPickupCodeModel: BsModalRef;
    productReturnPickupCodeModel: BsModalRef;
    toggleReturnPickup: boolean = false;
    toggleProductReturnPickup: boolean = false;

    public slotReasonModelRef: BsModalRef;
    public slotInfo: { slotNumber: string, reason: string, status: string};

    constructor(private router: Router, private _bsModalService: BsModalService, private companiesService: CompaniesService,
                private companyLockersService: CompanyLockersService, private cabinetsService: CabinetsService,
                private slotsService: SlotsService, private auth: AuthService, private storage: StorageService,
                private toastr: ToastrService, private exportService: ExportService,
                private lockerService: LockersService) {
        this.currentUser = this.auth.fnGetCurrentUser();
        this.totalLockers = 0;
        this.isDataLoaded = false;
        this.isExporting = false;
        this.isAllCabinets = false;
        this.filterObj = { companyId: '', sortBy: 'name', orderBy: 'asc', page: 1, limit: 10 };
    }

    ngOnInit() {
        this.selectedAccessCodeIndex = -1;
        this.selectedLocker = null;
        this.filterObj.companyId = this.currentUser.companyId;
        this.fnGetLockers(this.filterObj);
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.sortBy = 'name';
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        this.companyLockersService.fnGetLockers(this.filterObj)
            .then((res: any) => {
                // Iterate over first array of objects
                this.lockersArr = _.map(res[0].data, (obj) => {
                    // add the properties from second array matching the _id
                    // to the object from first array and return the updated object
                    return _.assign(obj, _.find(this.lockersArr, { _id: obj._id }));
                });
            });
    }

    /**
     * Get list of lockers
     * @param {object} params
     * */
    async fnGetLockers(params?: object) {
        this.isDataLoaded = false;
        this.isAllCabinets = false;
        try {
            const res: any = await this.companyLockersService.fnGetLockers(params);
            this.totalLockers = res.length && res[0].metadata.length ? res[0].metadata[0].total : 0;
            this.isDataLoaded = true;
            this.lockersArr = _.map(res[0].data, (obj: any) => {
                obj.isAllSlots = false;
                obj.isCabinets = false;
                obj.totalSlots = 0;
                obj.cabinets = _.map(obj.cabinets, (cabinetObj: any) => {
                    cabinetObj.totAvailableSlots = _.filter(cabinetObj.slots, (slotObj) => {
                        return slotObj.status === Constant.SLOT_STATUS_AVAILABLE;
                    }).length;
                    cabinetObj.isAllShipments = false;
                    cabinetObj.slots = _.map(cabinetObj.slots, (slotObj: any) => {
                        slotObj.isShipments = false;
                        return slotObj;
                    });
                    cabinetObj.isSlots = false;
                    obj.totalSlots += cabinetObj.slots.length;
                    return cabinetObj;
                });
                if (!obj.accessCodes) {
                    obj.accessCodes = [];
                }
                if (obj.accessCodes.length < this.CONSTANT.MAX_ACCESS_CODES_PER_LOCKER) {
                    for (let startIndex = obj.accessCodes.length; startIndex < this.CONSTANT.MAX_ACCESS_CODES_PER_LOCKER; startIndex++) {
                        obj.accessCodes.push('');
                    }
                }
                if(!obj.returnPickupCode){
                    obj.returnPickupCode = '';
                }
                obj.hasAccessCodes = false;
                obj.hasReturnPickupCode = false;
                return obj;
            });
        } catch (e) {
            this.toastr.fnError('Something went wrong. Please try again later.');
        }
    }


    /**
     * Expand/Collapse all cabinets table.
     * */
    fnToggleAllCabinets() {
        this.isAllCabinets = !this.isAllCabinets;
        this.lockersArr = this.lockersArr.map((obj: any) => {
            obj.isCabinets = this.isAllCabinets;
            obj.hasAccessCodes = this.isAllCabinets;
            obj.hasReturnPickupCode = this.isAllCabinets;
            return obj;
        });
    }

    /**
     * Expand/Collapse cabinets table.
     * @param {object} lockerObj
     * */
    fnToggleCabinets(lockerObj: any) {
        lockerObj.isCabinets = !lockerObj.isCabinets;
        lockerObj.hasAccessCodes = !lockerObj.hasAccessCodes;
        lockerObj.hasReturnPickupCode = !lockerObj.hasReturnPickupCode;
        // Check if all true
        this.isAllCabinets = !!this.lockersArr.map((obj: any) => obj.isCabinets).reduce((a: any, b: any) => {
            return (a === b) ? a : NaN;
        });
    }


    /**
     * Expand/Collapse all slots table.
     * @param {object} lockerObj
     * */
    fnToggleAllSlots(lockerObj: any) {
        lockerObj.isAllSlots = !lockerObj.isAllSlots;
        lockerObj.cabinets = lockerObj.cabinets.map((obj: any) => {
            obj.isSlots = lockerObj.isAllSlots;
            return obj;
        });
    }

    /**
     * Expand/Collapse slots table.
     * @param {object} lockerObj
     * @param {object} cabinetObj
     * */
    fnToggleSlots(lockerObj: any, cabinetObj: any) {
        cabinetObj.isSlots = !cabinetObj.isSlots;
        // Check if all true
        lockerObj.isAllSlots = !!lockerObj.cabinets.map((obj: any) => obj.isSlots).reduce((a: any, b: any) => {
            return (a === b) ? a : NaN;
        });
    }

    /**
     * Expand/Collapse all shipments table.
     * @param {object} cabinetObj
     * */
    fnToggleAllShipments(cabinetObj: any) {
        cabinetObj.isAllShipments = !cabinetObj.isAllShipments;
        cabinetObj.slots = cabinetObj.slots.map((obj: any) => {
            obj.isShipments = cabinetObj.isAllShipments;
            return obj;
        });
    }

    /**
     * Expand/Collapse slots table.
     * @param {object} cabinetObj
     * @param {object} slotObj
     * */
    fnToggleShipments(cabinetObj: any, slotObj: any) {
        slotObj.isShipments = !slotObj.isShipments;
        // Check if all true
        cabinetObj.isAllShipments = !!cabinetObj.slots.map((obj: any) => obj.isShipments).reduce((a: any, b: any) => {
            return (a === b) ? a : NaN;
        });
    }

    /**
     * Change pagination
     * @param {any} event
     * */
    pageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetLockers(this.filterObj);
    }

    async fnExportCSV() {
        this.isExporting = true;
        const res: any = await this.companyLockersService.fnGetLockers({
            page: 1,
            limit: 10000,
            companyId: this.currentUser.companyId
        });
        this.isDataLoaded = true;
        const lockers = _.map(res[0].data, (obj: any) => {
            obj.isAllSlots = false;
            obj.isCabinets = false;
            obj.totalSlots = 0;
            obj.cabinets = _.map(obj.cabinets, (cabinetObj: any) => {
                cabinetObj.totAvailableSlots = _.filter(cabinetObj.slots, (slotObj) => {
                    return slotObj.status === Constant.SLOT_STATUS_AVAILABLE;
                }).length;
                cabinetObj.isAllShipments = false;
                cabinetObj.slots = _.map(cabinetObj.slots, (slotObj: any) => {
                    slotObj.isShipments = false;
                    return slotObj;
                });
                cabinetObj.isSlots = false;
                obj.totalSlots += cabinetObj.slots.length;
                return cabinetObj;
            });
            return obj;
        });
        const csvArr = [];
        _.forEach(lockers, element => {
            element.totalCabinets = element.cabinets.length;
            csvArr.push(element);
        });
        const headers = ['lockerNumber', 'name',
            'locationId.country', 'locationId.postalCode', 'isOnline',
            'isMultiBuilding', 'totalCabinets', 'totalSlots', 'updatedAt',
            'createdAt'];
        this.exportService.fnDownloadCSV(lockers[0].companyId.name + ' Lockers', csvArr, headers);
        this.isExporting = false;
    }

    toggleAccessCodeSaveBtn(lockerObj: any, index: number, unsavedAccessCode: TemplateRef<any>, unsavedReturnPickup: TemplateRef<any>, unsavedProductReturnPickupCodeDialog: TemplateRef<any>) {
        if (this.toggleProductReturnPickup) {
            this.productReturnPickupCodeModel = this._bsModalService.show(unsavedProductReturnPickupCodeDialog, {'class': 'modal-md'});
            return;
        }
        if (this.selectedAccessCodeIndex !== -1 && this.selectedAccessCodeIndex !== index) {
            this.accessCodeEditInProgressModel = this._bsModalService.show(unsavedAccessCode, { 'class': 'modal-sm' })
            return;
        }
        if (this.toggleReturnPickup) {
            this.returnPickupCodeModel = this._bsModalService.show(unsavedReturnPickup, {'class': 'modal-sm'});
            return;
        }
        if (!this.selectedLocker) {
            this.selectedLocker = lockerObj;
        }
        if (this.selectedLocker._id !== lockerObj._id) {
            console.error('Different locker is clicked. do not enable Edit button');
            this.accessCodeEditInProgressModel = this._bsModalService.show(unsavedAccessCode, {'class': 'modal-sm'});
            return;
        }
        this.selectedAccessCodeIndex = index;
    }

    async saveAccessCodes(lockerObj: Locker) {
        try {
            const accessCodes = lockerObj.accessCodes.filter(code => {
                return code && code.length > 0;
            });
            const response = await this.lockerService.setCodes(lockerObj._id, LOCKER_CODES_TYPE.ACCESS_CODES , accessCodes);
            this.toastr.fnSuccess(response['message']);
            this.selectedAccessCodeIndex = -1;
            this.selectedLocker = null;
        } catch (err) {
            console.error('Error saveAccessCodes():', err.error.message);
            this.toastr.fnError(err.error.message);
        }
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    closeModal() {
        this.accessCodeEditInProgressModel.hide();
    }

    toggleReturnPickupCodeBtn(lockerObj: Locker, unsavedReturnPickup: TemplateRef<any>, unsavedAccessCode: TemplateRef<any>, unsavedProductReturnPickupCodeDialog: TemplateRef<any>) {
        if (this.selectedAccessCodeIndex > -1) {
            this.accessCodeEditInProgressModel = this._bsModalService.show(unsavedAccessCode, {'class': 'modal-sm'});
            return;
        }
        if (!this.selectedLocker) {
            this.selectedLocker = lockerObj;
        }
        if (this.selectedLocker._id !== lockerObj._id) {
            this.returnPickupCodeModel = this._bsModalService.show(unsavedReturnPickup, {'class': 'modal-sm'});
            return;
        }
        if (this.toggleProductReturnPickup) {
            this.productReturnPickupCodeModel = this._bsModalService.show(unsavedProductReturnPickupCodeDialog, {'class': 'modal-md'})
            return;
        }
        this.toggleReturnPickup = !this.toggleReturnPickup;
    }

    toggleProductReturnPickupCode = (lockerObj: Locker, unsavedReturnPickup: TemplateRef<any>, unsavedAccessCode: TemplateRef<any>) => {
        if (this.selectedAccessCodeIndex > -1) {
            this.accessCodeEditInProgressModel = this._bsModalService.show(unsavedAccessCode, {'class': 'modal-sm'});
            return;
        }
        if (!this.selectedLocker) {
            this.selectedLocker = lockerObj;
        }
        if (this.selectedLocker._id !== lockerObj._id) {
            this.returnPickupCodeModel = this._bsModalService.show(unsavedReturnPickup, {'class': 'modal-sm'});
            return;
        }
        if (this.toggleReturnPickup) {
            this.returnPickupCodeModel = this._bsModalService.show(unsavedReturnPickup, {'class': 'modal-sm'});
            return;
        }
        this.toggleProductReturnPickup = !this.toggleProductReturnPickup;
    }

    async saveProductReturnPickupCode(lockerObj: Locker) {
        try {
            const productReturnPickupCode = lockerObj.productReturnPickupCode;
            const response = await this.lockerService.setCodes(lockerObj._id, LOCKER_CODES_TYPE.PRODUCT_RETURN_PICKUP_CODE, productReturnPickupCode);
            this.toastr.fnSuccess(response['message']);
            this.toggleProductReturnPickup = false;
            this.selectedLocker = null;
        } catch (err) {
            this.toastr.fnError(err.error.message);
        }
    }

    closeReturnPickupCodeModal() {
        this.returnPickupCodeModel.hide();
    }

    closeProductReturnPickupCodeModal() {
        this.productReturnPickupCodeModel.hide();
    }

    async saveReturnPickupCode(lockerObj: Locker) {
        try {
            const returnPickupCode = lockerObj.returnPickupCode;
            const response = await this.lockerService.setCodes(lockerObj._id, LOCKER_CODES_TYPE.RETURN_PICKUP_CODE , returnPickupCode);
            this.toastr.fnSuccess(response['message']);
            this.toggleReturnPickup = false;
            this.selectedLocker = null;
        } catch (err) {
            this.toastr.fnError(err.error.message);
        }
    }

    showSlotReason(template: any, cabinetName: string, slotInfo: Slot) {
        this.slotInfo = {
            slotNumber: cabinetName + slotInfo.slotNumber,
            reason: slotInfo.reason ? slotInfo.reason : 'No repair reason added.',
            status: slotInfo.status
        };
        this.slotReasonModelRef = this._bsModalService.show(template, {'class': 'modal-sm'});
    }

    closeReasonModal() {
        this.slotReasonModelRef.hide();
        this.slotInfo = {slotNumber: '', reason: '', status: ''};
    }
}
