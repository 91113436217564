import {Pipe, PipeTransform} from '@angular/core';

/**
 * Groups data by 'field' passed in pipe
 */
@Pipe({name: 'groupBy'})
export class GroupByPipe implements PipeTransform {
    transform(values: Array<any>, field: string): Array<any> {
        if(values) {
            const groupedObj = values.reduce((prev, cur) => {
                if (!prev[cur[field]]) {
                    prev[cur[field]] = [cur];
                } else {
                    prev[cur[field]].push(cur);
                }
                return prev;
            }, {});
            return Object.keys(groupedObj).sort().map(key => ({key, value: groupedObj[key]}));
        }
        return [];
    }
}
