import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import {Shipment} from './shipment';
import * as jstz from 'jstz';
import {SHIPMENT_MESSAGE_TYPE} from '../../common/enums';

@Injectable()
export class ShipmentsService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get shipments
     * @param {object} params
     * */
    fnGetShipments(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/shipments' + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get User shipments
     * @param {object} params
     * */
    getUserShipments(userId: string, params?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/user/' + userId + '/shipments' + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get Pending shipments
     * @param {object} params
     * */
    getPendingShipments(params?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/pending-shipments' + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get shipment
     * @param {string} id
     * */
    fnGetShipment(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/shipments/' + id)
                .subscribe((response: Shipment) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create a new shipment for registered user.
     * @param {object} shipmentObj
     * */
    fnCreateShipmentForRegisteredUser(shipmentObj) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/registered-user-shipment', shipmentObj)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create a new shipment for not registered user.
     * @param {object} shipmentObj
     * */
    fnCreateShipmentForNotRegisteredUser(shipmentObj) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/not-registered-user-shipment', shipmentObj)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete shipment
     * @param {string} id
     * @param {object} params
     **/
    fnDeleteShipment(id: string, params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/shipments/' + id + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete multiple shipment
     * @param {any} params
     **/
    fnDeleteMultipleShipment(params?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/shipments' + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get carriers
     * @param {object} params
     * */
    fnGetCarriers(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/carriers' + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get carrier
     * @param {object} params
     * */
    fnGetCarrier(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/carrier' + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create a new shipment while scan package label.
     * @param {object} shipmentObj
     * */
    fnCreateShipping(shipmentObj) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/shipping', shipmentObj)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }


    fnUpdateShipmentStatus(shipmentObj, params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/shipment/' + shipmentObj._id  + '/status/' + (qString ? '?' + qString : ''), shipmentObj)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Set shipment new expiration Date.
     * @param {string} shipmentId
     * @param {string} newExpirationDate
     * */
    setShipmentExpirationDate(shipmentId, newExpirationDate){
        return new Promise((resolve, reject) => {
            this.http
                .patch(Constant.API_URL + 'api/shipment/' + shipmentId  + '/expiration_date/' + newExpirationDate + '?timezone=' + jstz.determine().name(), {})
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Re-send shipment notification to user.
     * @param {string} shipmentId
     * @param {SHIPMENT_MESSAGE_TYPE} type
     * */
    resendShipmentNotification(shipmentId: string, type: SHIPMENT_MESSAGE_TYPE ) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/shipment/' + shipmentId + '/notification/' + type, {})
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * delete pending shipment.
     * @param {string} pendingShipmentId
     * */
    deletePendingShipment(pendingShipmentId: string) {
        return new Promise((resolve, reject) => {
            this.http.delete(Constant.API_URL + 'api/pending-shipment/' + pendingShipmentId)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    createPendingShipment(data: any) {
        return new Promise((resolve, reject) => {
            this.http.post(Constant.API_URL + 'api/pending-shipments', data).subscribe((response) => {
                resolve(response);
            }, (error) => {
                reject(error.error);
            });
        });
    }

    getBarcode(trackingNumber: string) {
        return new Promise((resolve, reject) => {
            this.http.get(Constant.API_URL + 'api/pending-shipment-barcode/' + trackingNumber).subscribe((response) => {
                resolve(response);
            }, (error) => {
                reject(error.error);
            });
        });
    }

    getReport(params) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/pending-shipments/report' + (qString ? '?' + qString : ''), { responseType: 'blob'})
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
}
