import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {Company} from '../company';
import {CompaniesService} from '../companies.service';
import {ToastrService} from '../../../common/toastr.service';
import {Constant} from '../../../common/constant';
import {SKIN} from '../../lockers/locker';

@Component({
    selector: 'pp-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.css']
})

export class CompanyComponent implements OnInit {

    public isDataLoaded;
    public companyObj: Company;
    public companyId: string;
    readonly CONSTANT = Constant;
    public lockerSkin: Array<string>;

    constructor(private router: Router, private activatedRoute: ActivatedRoute,
                private companiesService: CompaniesService, private toastr: ToastrService) {
        this.companyObj = {locationId: {formattedAddress: '', placeId:''}, brand: '', extendExpirationTime:''};
        this.isDataLoaded = false;
        this.activatedRoute.params.subscribe(params => {
            this.companyId = params['id'];
        });
        this.lockerSkin = Object.keys(SKIN);
    }

    ngOnInit() {
        if (this.companyId !== 'add') {
            this.fnGetCompany(this.companyId);
        }
    }

    /**
     * Get company
     * @param {string} id
     * */
    fnGetCompany(id: string) {
        this.isDataLoaded = true;
        this.companiesService.fnGetCompany(id)
            .then((companyObj: Company) => {
                this.isDataLoaded = false;
                this.companyObj = companyObj;
                if (!this.companyObj.locationId) {
                    this.companyObj.locationId = {formattedAddress: '', placeId: ''};
                }
                if (!this.companyObj.brand) {
                    this.companyObj.brand = '';
                } else if (!this.lockerSkin.includes(this.companyObj.brand)) {
                    this.companyObj.brand = SKIN.PARCELPORT;
                }
            });
    }

    /**
     * Create/Update company
     * @param {object} companyObj
     * */
    fnSaveCompany(companyObj: Company) {
        if (!this.fnCheckNotificationDelay(companyObj.notificationDelay)) {
            return;
        }
        if (companyObj._id) {
            this.companiesService.fnUpdateCompany(companyObj)
                .then(() => {
                    this.toastr.fnSuccess('Company updated successfully.');
                    this.router.navigateByUrl('/companies');
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        } else {
            this.companiesService.fnCreateCompany(companyObj)
                .then(() => {
                    this.toastr.fnSuccess('Company created successfully.');
                    this.router.navigateByUrl('/companies');
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        }
    }

    fnCheckNotificationDelay(notificationDelayMinute) {
       return !(notificationDelayMinute < 1 || notificationDelayMinute > 1440);
   }

   checkNumberRange(value: number, minVal?: number, maxVal?: number) {
       if (minVal && maxVal) {
           return !(value < minVal || value > maxVal);
       } else if (!minVal) {
           return !(value > maxVal);
       } else if (!maxVal) {
           return !(value < minVal);
       } else {
          return false;
       }
   }
}
