import {Component, OnInit} from '@angular/core';
import {ToastrService} from '../common/toastr.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {AuthService} from '../common/auth.service';

@Component({
    selector: 'pp-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    resetPassObj: {
        password: string,
        confirmPassword: string
    };

    resetToken: string;

    constructor(
        private router: Router,
        private auth: AuthService,
        private route: ActivatedRoute,
        private toastService: ToastrService
    ) {
        this.resetToken = '';
        this.resetPassObj = {
            password: '',
            confirmPassword: ''
        };

        this.resetToken = this.route.snapshot.paramMap.get('code');
    }

    async ngOnInit() {
        if (!this.resetToken) {
            this.toastService.fnError('This link has been expired.');
            this.fnGoToSignIn();
            return;
        }

        this.auth.fnVerifyLink(this.resetToken.toString()).then((success: any) => {
            if (!success) {
                this.toastService.fnError('This link has been expired.');
                this.fnGoToSignIn();
            }
        }).catch((error) => {
            console.error(error);
            if (error.message) {
                this.toastService.fnError(error.message);
            }
            this.fnGoToSignIn();
        });
    }

    fnUpdateNewPassword(form: any) {
        if (this.resetPassObj.password && this.resetPassObj.confirmPassword) {
            if (this.resetPassObj.password !== this.resetPassObj.confirmPassword) {
                this.toastService.fnWarning('Confirmation password does not match with the password.');
            } else {
                this.auth.fnResetPassword(this.resetToken.toString(), this.resetPassObj.password).then((success: any) => {
                    this.toastService.fnSuccess(success.message);
                    form.resetForm();
                    this.fnGoToSignIn();
                }).catch((error) => {
                    this.toastService.fnError(error.message);
                });
            }
        }
    }

    fnGoToSignIn() {
        this.router.navigate(['sign-in']);
    }
}
