<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Payment Details</h1>
    </div>

    <div class="col-lg-10" *ngIf="this.cardList && this.cardList.length">
        <fieldset class="col-md-12 fieldset">
            <legend class="fieldset-legend">Your credit cards</legend>
            <div class="panel panel-default panel-radius m-b-10" *ngFor="let card of cardList">
                <div class="panel-body p-t-10 p-l-10 p-b-10 p-r-10">
                    <div class="col-md-4">
                        Name on card: <label *ngIf="card.name">{{card.name}}</label>
                    </div>
                    <div class="col-md-4" *ngIf="card.last4Digits">
                        Card #: <label>**** **** **** {{card.last4Digits}}</label>
                    </div>
                    <div class="col-md-1">
                        <button type="button" class="btn btn-sm btn-danger pull-right" *ngIf="card.id"
                                (click)="deleteCard(card.id)">
                            <i class="fa fa-trash-o"></i>
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>

    <div class="col-lg-10">
        <form novalidate #creditDetailsForm="ngForm" (ngSubmit)="creditDetailsForm.valid && saveCard(creditDetailsForm)">
            <fieldset class="col-md-12 fieldset" [disabled]="isFormDisabled">
                <legend class="fieldset-legend">Add a New Payment Method</legend>
                <div class="row">
                    <div class="form-group col-md-6"
                             [ngClass]="{'has-error': (creditDetailsForm.submitted || nameOnCard.touched) && !nameOnCard.valid}">
                            <label for="nameOnCard">Name on card </label>
                            <input id="nameOnCard" type="text" class="form-control" name="nameOnCard"
                                   [(ngModel)]="creditCardInfo.nameOnCard"
                                   #nameOnCard="ngModel" required
                                   [pattern]="cardNameRegEx" autocomplete="false"/>
                            <div *ngIf="(creditDetailsForm.submitted || nameOnCard.touched) && !nameOnCard.valid">
                                <small class="text-danger" *ngIf="nameOnCard.errors['pattern']">Name on card can only include letters and numbers.</small>
                            </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6"
                             [ngClass]="{'has-error': (creditDetailsForm.submitted || cardNumber.touched) && !cardNumber.valid}">
                            <label for="cardNumber">Card number</label>
                            <input id="cardNumber" type="text" class="form-control" name="cardNumber"
                                    [(ngModel)]="creditCardInfo.number"
                                    #cardNumber="ngModel" required
                                    [pattern]="cardNumberRegEx" autocomplete="false"/>
                            <div *ngIf="(creditDetailsForm.submitted || cardNumber.touched) && !cardNumber.valid">
                                <small class="text-danger" *ngIf="cardNumber.errors['pattern']">Please provide valid credit card number.</small>
                            </div>
                    </div>
                    <div class="form-group col-sm-6 p-l-0 p-r-0">
                        <div class="col-sm-4"
                             [ngClass]="{'has-error': (creditDetailsForm.submitted || expMonth.touched) && !expMonth.valid}">
                            <label for="expMonth">Expiry month</label>
                            <div>
                                <select id="expMonth" class="form-control" name="expMonth" [(ngModel)]="creditCardInfo.expirationMonth"
                                        #expMonth="ngModel" required>
                                    <option [ngValue]="item.id" *ngFor="let item of months">{{item.id}} - {{item.name}}</option>
                                </select>
                                <div *ngIf="isInvalidMonth">
                                    <small class="text-danger" >Expiration month is invalid</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4"
                             [ngClass]="{'has-error': (creditDetailsForm.submitted || expYear.touched) && !expYear.valid}">
                            <label for="expYear">Expiry Year</label>
                            <div>
                                <select id="expYear" class="form-control" name="planType" [(ngModel)]="creditCardInfo.expirationYear"
                                        #expYear="ngModel" required>
                                    <option [ngValue]="item.year" *ngFor="let item of year">{{item.year}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-4"
                             [ngClass]="{'has-error': (creditDetailsForm.submitted || cvcCode.touched) && !cvcCode.valid}">
                            <label for="cvcCode">CVC</label>
                            <input id="cvcCode" type="password" class="form-control" name="cvcCode"
                                   [(ngModel)]="creditCardInfo.cvCode"
                                   #cvcCode="ngModel" required
                                   [pattern]="cvcRegEx"/>
                            <div *ngIf="(creditDetailsForm.submitted || cvcCode.touched) && !cvcCode.valid">
                                <small class="text-danger" *ngIf="cvcCode.errors['pattern']">CVC is invalid.</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-sm-12 m-t-5">
                        <input type="checkbox" name="isActive" id="isActive"
                               [(ngModel)]="isHomeAddress"
                               [ngModelOptions]="{standalone: true}"
                               (change)="toggleAddress()">
                        <label for="isActive"> Billing address is the same as my home address.</label>
                        <label>{{homeAddress ? '(' + homeAddress + ')' : ''}}</label>
                    </div>
                </div>
                <div *ngIf="isToggleAddress">
                    <div class="form-group row">
                        <div class="col-sm-12 m-t-15">
                            <label for="addressLine">Address</label>
                            <input name="addressLine" id="addressLine"
                                class="form-control" [(ngModel)]="creditCardInfo.billingAddress.address"
                                type="text"
                                #addressLine="ngModel"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3" [ngClass]="{'has-error': (creditDetailsForm.submitted || city.touched) && !city.valid}">
                            <label for="city">City</label>
                            <input id="city" type="text" class="form-control" name="city"
                                [(ngModel)]="creditCardInfo.billingAddress.city"
                                #city="ngModel" required/>
                        </div>
                        <div class="col-sm-3" [ngClass]="{'has-error': (creditDetailsForm.submitted || state.touched) && !state.valid}">
                            <label>Province / State</label>
                            <div>
                                <select id="state" class="form-control" name="state" [(ngModel)]="creditCardInfo.billingAddress.province"
                                            #state="ngModel" required>
                                    <option [ngValue]="item.name" *ngFor="let item of stateList">{{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3" [ngClass]="{'has-error': (creditDetailsForm.submitted || postalCode.touched) && !postalCode.valid}">
                            <label for="postalCode">Postal / Zip Code</label>
                            <input id="postalCode" type="text" class="form-control" name="postalCode"
                                [(ngModel)]="creditCardInfo.billingAddress.postalCode"
                                #postalCode="ngModel" required/>
                        </div>
                        <div class="col-sm-3" [ngClass]="{'has-error': (creditDetailsForm.submitted || country.touched) && !country.valid}">
                            <label>Country</label>
                            <div>
                                <select id="country" class="form-control" name="country" [(ngModel)]="creditCardInfo.billingAddress.country"
                                        #country="ngModel" required (change)="getStateList(creditCardInfo.billingAddress.country)">
                                    <option [ngValue]="item.id" *ngFor="let item of countryList">{{item.name}}</option>
                                </select>
                                <div *ngIf="(creditDetailsForm.submitted || country.touched) && !country.valid">
                                    <small class="text-danger" *ngIf="country.errors['required']">Country is required.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class="row form-group">
                <div class="col-sm-12">
                    <button class="btn btn-primary" type="submit">
                        Add your card
                    </button>
                </div>
            </div>
        </form>
    </div>

</div>

