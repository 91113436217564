import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BillingAddressInfo, CreditCardInfo} from './credit_card';
import * as moment from 'moment';
import * as _ from 'lodash';
import {int} from 'aws-sdk/clients/datapipeline';
import {COUNTRY, CANADA_PROVINCE, USA_STATES} from '../../common/enums';
import {PaymentDetailsService} from './payment-details.service';
import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {ToastrService} from '../../common/toastr.service';
import {User} from '../users/user';
import {AuthService} from '../../common/auth.service';
import {NgForm} from '@angular/forms';
import {AES} from 'crypto-js';

@Component({
    selector: 'pp-payment-details',
    templateUrl: './payment-details.component.html',
    styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {
    public creditCardInfo: CreditCardInfo = new CreditCardInfo();
    public billingAddressInfo: BillingAddressInfo = new BillingAddressInfo();
    public months;
    public year;
    public countryList;
    public stateList;
    public isToggleAddress;
    public homeAddress: string;
    public isInvalidMonth;
    public isHomeAddress;
    public isFormDisabled;
    public cardList;
    public cvcRegEx: any = '^[0-9]{3,4}$';
    public cardNameRegEx = '^[a-zA-Z0-9\\s]+$';
    public cardNumberRegEx = '^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$';
    public currentUser: User;

    constructor(private _bsModalService: BsModalService,
                private paymentDetailsService: PaymentDetailsService,
                private toastr: ToastrService,
                private auth: AuthService) {
        this.isToggleAddress = false;
        this.isInvalidMonth = false;
        this.isFormDisabled = false;
        this.isHomeAddress = true;
        this.creditCardInfo.billingAddress = this.billingAddressInfo;
        this.getMonths();
        this.getYears();
        this.getCountryList();
    }

    ngOnInit() {
        this.getUser();
    }

    getCountryList(): Array<any> {
        this.countryList = [];
        for (const j in COUNTRY) {
            this.countryList.push({id: j, name: <any>COUNTRY[j]});
        }
        return this.countryList;
    }

    getStateList(countryId?: string): Array<any> {
        this.stateList = [];

        if (!countryId) {
            return this.stateList;
        }

        if (countryId === COUNTRY.CA) {
            for (const j in CANADA_PROVINCE) {
                this.stateList.push({id: j, name: <any>CANADA_PROVINCE[j]});
            }
        } else if (countryId === COUNTRY.US) {
            for (const j in USA_STATES) {
                this.stateList.push({id: j, name: <any>USA_STATES[j]});
            }
        }
        return this.stateList;
    }

    getMonths() {
        this.months = [
            {id: '01', name: 'January'},
            {id: '02', name: 'February'},
            {id: '03', name: 'March'},
            {id: '04', name: 'April'},
            {id: '05', name: 'May'},
            {id: '06', name: 'June'},
            {id: '07', name: 'July'},
            {id: '08', name: 'August'},
            {id: '09', name: 'September'},
            {id: '10', name: 'October'},
            {id: '11', name: 'November'},
            {id: '12', name: 'December'},
        ];
    }

    getYears() {
        this.year = [
            {year: moment(new Date()).format('YYYY')},
            {year: this.addYear(1)},
            {year: this.addYear(2)},
            {year: this.addYear(3)},
            {year: this.addYear(4)},
            {year: this.addYear(5)},
            {year: this.addYear(6)},
            {year: this.addYear(7)},
            {year: this.addYear(8)},
            {year: this.addYear(9)},
            {year: this.addYear(10)},
        ];
    }

    addYear(amount: int) {
        return moment(new Date()).add(amount, 'y').format('YYYY');
    }

    toggleAddress() {
        if (this.isToggleAddress) {
            this.setHomeAddress(true);
        } else {
            this.setHomeAddress(false);
        }

        if (this.creditCardInfo && this.creditCardInfo.billingAddress && this.creditCardInfo.billingAddress.country) {
            this.getStateList(this.creditCardInfo.billingAddress.country);
        }

        this.isToggleAddress = !this.isToggleAddress;
    }

    setHomeAddress(isHomeAdr: boolean) {
        const billingAddressInfo: BillingAddressInfo = new BillingAddressInfo();
        const location = this.currentUser.locationId;
        if (isHomeAdr) {
            billingAddressInfo.country = location && location.countryCode === COUNTRY.CA ? COUNTRY.CA : COUNTRY.US;
            billingAddressInfo.province = location && location.state || '';
            billingAddressInfo.city = location && location.city || '';
            billingAddressInfo.postalCode = location && location.postalCode || '';
            billingAddressInfo.address = location && location.formattedAddress || '';
        } else {
            billingAddressInfo.country = COUNTRY.CA;
            billingAddressInfo.province = '';
            billingAddressInfo.city = '';
            billingAddressInfo.postalCode = '';
            billingAddressInfo.address = '';
        }
        this.creditCardInfo.billingAddress = billingAddressInfo;

        if (this.currentUser && this.currentUser.locationId) {
            this.homeAddress = (location.city ? location.city + ', ' : '')
                + (location.state ? location.state + ', ' : '')
                + (location.country ? location.country : '')
                + (location.postalCode ? ' - ' + location.postalCode : '');
        }
    }

    resetForm(cardDetailsForm: NgForm) {
        cardDetailsForm.form.clearValidators();
        cardDetailsForm.resetForm();
        this.isToggleAddress = false;
        this.isInvalidMonth = false;
        this.isHomeAddress = true;
        this.setHomeAddress(true);
    }

    disableForm(cardDetailsForm: NgForm) {
        this.isInvalidMonth = false;
        cardDetailsForm.form.clearValidators();
        // cardDetailsForm.form.disable();
        this.isFormDisabled = true;
    }

    saveCard(cardDetailsForm: NgForm) {
        const currentMonth = moment(new Date()).format('MM');
        const currentYear = moment(new Date()).format('YYYY');
        if (this.creditCardInfo.expirationMonth < currentMonth && this.creditCardInfo.expirationYear === currentYear) {
            this.isInvalidMonth = true;
        } else {
            this.isInvalidMonth = false;
            this.creditCardInfo.number = this.creditCardInfo.number + '';
            const encryptDetails = AES.encrypt(JSON.stringify( this.creditCardInfo), 'ParcelPortSecretSession').toString();
            this.paymentDetailsService.saveCard(encryptDetails, this.currentUser._id)
                .then(() => {
                    this.toastr.fnSuccess('Thanks! Your credit card has been succesfully registered.');
                    this.getCardListByUser(this.currentUser._id);
                    // this.disableForm(cardDetailsForm);
                    this.resetForm(cardDetailsForm);
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        }
    }

    getUser() {
        this.paymentDetailsService.getUserById(this.auth.fnGetCurrentUser()._id)
            .then((user) => {
                this.currentUser = user;
                this.setHomeAddress(true);
                this.getCardListByUser(this.currentUser._id);
            })
            .catch((error) => {
                this.toastr.fnError(error.message);
            });
    }

    getCardListByUser(userId) {
        this.cardList = [];
        this.paymentDetailsService.getCreditCards(userId)
            .then((creditCards: any) => {
                this.cardList = creditCards && creditCards.cards;
            })
            .catch((error) => {
                this.toastr.fnError(error.message);
            });
    }

    /**
     * Delete card
     * @param {string} id
     * */
    deleteCard(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this card?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(async result => {
            if (!result) {
                return;
            }

            try {
                const res: any = await this.paymentDetailsService.deleteCard(id, this.currentUser._id);
                _.remove(this.cardList, function (card: any) {
                    return card.id === id;
                });
                this.toastr.fnSuccess(res.message);
            } catch (e) {
                console.log('Error occurred while deleting the card.', e);
                this.toastr.fnError(e.message);
            }
        });
    }
}
