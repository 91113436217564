<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header ">Analytics
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <div class="col-lg-12">
        <button class="btn btn-primary pull-right" (click)="fnClearFilters()">Clear Filters</button>
        <tabset>
            <tab heading="Users" id="tab1" class="m-t-10" (selectTab)="fnSelectTab('Users', $event)">
                <div *ngIf="currentTab === 'Users'">
                    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <ng-template #customUserAnalyticsTemp let-model="item" let-index="index">
                                    {{model.firstName}}&nbsp;{{model.lastName}}
                                </ng-template>
                                <input type="text" placeholder="Select User" class="form-control" autocomplete="off"
                                       [(ngModel)]="userAnalyticsUserSearch"
                                       [typeahead]="dataSource" (typeaheadLoading)="changeTypeAheadLoading($event)"
                                       (typeaheadOnSelect)="fnOnSelectUser($event)"
                                       [typeaheadItemTemplate]="customUserAnalyticsTemp"
                                       [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body"
                                       [typeaheadMinLength]="0" [typeaheadOptionsInScrollableView]="10"
                                       (typeaheadOnBlur)="fnTypeAheadOnBlur($event)">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <input type="text" name="daterangeInput" class="form-control" daterangepicker
                                       placeholder="Select Date Range"
                                       [(ngModel)]="userAnalyticsDateRangeSearch"
                                       [options]="{autoUpdateInput: false}"
                                       (applyDaterangepicker)="fnSelectedDate($event)"
                                       (cancelDaterangepicker)="fnClearDate()"/>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select id="lockerId" class="form-control" name="lockerId"
                                        [(ngModel)]="userAnalyticsLockerSearch" (change)="fnLockerChange()">
                                    <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select id="carrierName" class="form-control" name="carrierName"
                                        [(ngModel)]="userAnalyticsCarrierSearch" (change)="fnCarrierChange()">
                                    <option value="" selected>Select Carrier</option>
                                    <option value="PUROLATOR">Purolator</option>
                                    <option value="FEDEX">FedEx</option>
                                    <option value="UPS">UPS</option>
                                    <option value="DHL">DHL</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive" *ngIf="userArr.length">
                        <table>
                            <thead>
                            <tr>
                                <th class="bg-primary cursor-auto">User</th>
                                <th class="bg-primary cursor-auto">Avg parcel lifetime</th>
                                <th class="bg-primary cursor-auto">Avg pickup duration</th>
                                <th class="bg-primary cursor-auto">Avg extraction duration</th>
                                <th class="bg-primary cursor-auto">Expired-PickedUp parcel ratio</th>
                                <th class="bg-primary cursor-auto">Single-Multi parcel ratio</th>
                                <th class="bg-primary cursor-auto">Avg pickup time of day</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let user of userArr">
                                <td>
                                    <p class="m-b-0" *ngIf="user.user && user.user.firstName && user.user.lastName">
                                        {{user.user.firstName + ' ' + user.user.lastName}}
                                    </p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{user.avgTotalLifetime | formatMillisecond}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{user.avgPickupDuration | formatMillisecond}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{user.avgExtractionDuration | formatMillisecond}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{user.expiredParcelAmt}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0"></p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{user.avgTimeOfDay}}</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3 *ngIf="isDataLoaded && !userArr.length" class="text-center">No Users found.</h3>
                    <div class="col-lg-12 text-center" *ngIf="userArr.length">
                        <pagination [totalItems]="totUsers" [(ngModel)]="userFilterObj.page"
                                    [itemsPerPage]="userFilterObj.limit"
                                    [boundaryLinks]="true" [maxSize]="5"
                                    (pageChanged)="fnUserPageChanged($event)"></pagination>
                    </div>
                </div>
            </tab>
            <tab heading="Parcels" class="m-t-10" (selectTab)="fnSelectTab('Parcels', $event)">
                <div *ngIf="currentTab === 'Parcels'">
                    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <ng-template #customParcelAnalyticsTemp let-model="item" let-index="index">
                                    {{model.firstName}}&nbsp;{{model.lastName}}
                                </ng-template>
                                <input type="text" placeholder="Select User" class="form-control" autocomplete="off"
                                       [(ngModel)]="parcelAnalyticsUserSearch"
                                       [typeahead]="dataSource" (typeaheadLoading)="changeTypeAheadLoading($event)"
                                       (typeaheadOnSelect)="fnOnSelectUser($event)"
                                       [typeaheadItemTemplate]="customParcelAnalyticsTemp"
                                       [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body"
                                       [typeaheadMinLength]="0" [typeaheadOptionsInScrollableView]="10"
                                       (typeaheadOnBlur)="fnTypeAheadOnBlur($event)">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <input type="text" name="daterangeInput" class="form-control" daterangepicker
                                       placeholder="Select Date Range"
                                       [(ngModel)]="parcelAnalyticsDateRangeSearch"
                                       [options]="{autoUpdateInput: false}"
                                       (applyDaterangepicker)="fnSelectedDate($event)"
                                       (cancelDaterangepicker)="fnClearDate()"/>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select id="parcelAnalyticsLockerId" class="form-control" name="lockerId"
                                        [(ngModel)]="parcelAnalyticsLockerSearch" (change)="fnLockerChange()">
                                    <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select id="parcelAnalyticsCarrierName" class="form-control" name="carrierName"
                                        [(ngModel)]="parcelAnalyticsCarrierSearch" (change)="fnCarrierChange()">
                                    <option value="" selected>Select Carrier</option>
                                    <option value="PUROLATOR">Purolator</option>
                                    <option value="FEDEX">FedEx</option>
                                    <option value="UPS">UPS</option>
                                    <option value="DHL">DHL</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive" *ngIf="packageArr.length">
                        <table>
                            <thead>
                            <tr>
                                <th class="bg-primary cursor-auto">Label #</th>
                                <th class="bg-primary cursor-auto">Carrier Deposit duration</th>
                                <th class="bg-primary cursor-auto">Total lifetime in locker</th>
                                <th class="bg-primary cursor-auto">Pickup duration</th>
                                <th class="bg-primary cursor-auto">Extraction duration</th>
                                <th class="bg-primary cursor-auto">Pickup time of day</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let parcel of packageArr">
                                <td>
                                    <p class="m-b-0">{{parcel.label}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{parcel.depositeDuration | formatMillisecond}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{parcel.totalLifetime | formatMillisecond}} </p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{parcel.pickupDuration | formatMillisecond}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{parcel.extractionDuration | formatMillisecond}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{parcel.timeOfDay}}</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3 *ngIf="isDataLoaded && !packageArr.length" class="text-center">No Parcels found.</h3>
                    <div class="col-lg-12 text-center" *ngIf="packageArr.length">
                        <pagination [totalItems]="totPackages" [(ngModel)]="filterObj.page"
                                    [itemsPerPage]="filterObj.limit"
                                    [boundaryLinks]="true" [maxSize]="5"
                                    (pageChanged)="fnPageChanged($event)"></pagination>
                    </div>
                </div>
            </tab>
            <tab heading="Days" class="m-t-10" (selectTab)="fnSelectTab('Days', $event)">
                <div *ngIf="currentTab === 'Days'">
                    <pp-spinner
                        [isSpinnerLoad]="!isDataLoadedForNotificationCounts || !isDataLoadedForUserCounts || !isDataLoadedForDaysAnalytics"></pp-spinner>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <ng-template #customDaysAnalyticsTemp let-model="item" let-index="index">
                                    {{model.firstName}}&nbsp;{{model.lastName}}
                                </ng-template>
                                <input type="text" placeholder="Select User" class="form-control" autocomplete="off"
                                       [(ngModel)]="daysAnalyticsUserSearch"
                                       [typeahead]="dataSource" (typeaheadLoading)="changeTypeAheadLoading($event)"
                                       (typeaheadOnSelect)="fnOnSelectUser($event)"
                                       [typeaheadItemTemplate]="customDaysAnalyticsTemp"
                                       [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body"
                                       [typeaheadMinLength]="0" [typeaheadOptionsInScrollableView]="10"
                                       (typeaheadOnBlur)="fnTypeAheadOnBlur($event)">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <input type="text" name="daterangeInput" class="form-control" daterangepicker
                                       placeholder="Select Date Range"
                                       [(ngModel)]="daysAnalyticsDateRangeSearch"
                                       [options]="{autoUpdateInput: false}"
                                       (applyDaterangepicker)="fnSelectedDate($event)"
                                       (cancelDaterangepicker)="fnClearDate()"/>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select id="daysAnalyticsLockerId" class="form-control" name="lockerId"
                                        [(ngModel)]="daysAnalyticsLockerSearch" (change)="fnLockerChange()">
                                    <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <select id="daysAnalyticsCarrierName" class="form-control" name="carrierName"
                                        [(ngModel)]="daysAnalyticsCarrierSearch" (change)="fnCarrierChange()">
                                    <option value="" selected>Select Carrier</option>
                                    <option value="PUROLATOR">Purolator</option>
                                    <option value="FEDEX">FedEx</option>
                                    <option value="UPS">UPS</option>
                                    <option value="DHL">DHL</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive" *ngIf="daysArr.length">
                        <table>
                            <thead>
                            <tr>
                                <th class="bg-primary cursor-auto">Day</th>
                                <th class="bg-primary cursor-auto">New parcels</th>
                                <!--<th class="bg-primary">Stored parcels</th>-->
                                <th class="bg-primary cursor-auto">Picked up parcels</th>
                                <th class="bg-primary cursor-auto">Avg deposit duration</th>
                                <th class="bg-primary cursor-auto">Avg pickup duration</th>
                                <th class="bg-primary cursor-auto">Avg extraction duration</th>
                                <!--<th class="bg-primary">New users</th>
                                <th class="bg-primary">Returning users</th>
                                <th class="bg-primary">Single parcel shipments</th>
                                <th class="bg-primary">Multi-parcel shipments</th>-->
                                <th class="bg-primary cursor-auto">QR code pickups</th>
                                <th class="bg-primary cursor-auto">Code entry pickups</th>
                                <th class="bg-primary cursor-auto">Actions</th>
                                <!--<th class="bg-primary">Exceptions / Jams / Errors</th>-->
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let day of daysArr">
                                <td>
                                    <p class="m-b-0" *ngIf="day._id && day._id.day && day._id.month && day._id.year">
                                        {{day._id.day + '/' + day._id.month + '/' + day._id.year}}
                                    </p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{day.newParcelCount}}</p>
                                </td>
                                <!--<td></td>-->
                                <td>
                                    <p class="m-b-0">{{day.pickParcelCount}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{day.avgDepositeDuration | formatMillisecond}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{day.avgPickupDuration | formatMillisecond}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{day.avgExtractionDuration | formatMillisecond}}</p>
                                </td>
                                <!--<td></td>
                                <td></td>
                                <td></td>
                                <td></td>-->
                                <td>
                                    <p class="m-b-0">{{day.pickupTypeQRScan}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{day.pickupTypeManual}}</p>
                                </td>
                                <!--<td></td>-->
                                <td>
                                    <div class="btn-group btn-group-xs">
                                        <button type="button" class="btn btn-primary"
                                                [routerLink]="['/shipments']"
                                                [queryParams]="{startDate: day._id.month + '/' + day._id.day + '/' + day._id.year, endDate: day._id.month + '/' + day._id.day + '/' + day._id.year}">
                                            <i class="fa fa-truck"></i>
                                        </button>
                                        <button type="button" class="btn btn-primary"
                                                [routerLink]="['/transactions']"
                                                [queryParams]="{startDate: day._id.month + '/' + day._id.day + '/' + day._id.year, endDate: day._id.month + '/' + day._id.day + '/' + day._id.year}">
                                            <i class="fa fa-exchange"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <h3 *ngIf="isDataLoadedForDaysAnalytics && !daysArr.length" class="text-center">No Days data found.</h3>
                    <div class="col-lg-12 text-center" *ngIf="daysArr.length">
                        <pagination [totalItems]="totDays" [(ngModel)]="dayFilterObj.page"
                                    [itemsPerPage]="dayFilterObj.limit"
                                    [boundaryLinks]="true" [maxSize]="5"
                                    (pageChanged)="fnDaysPageChanged($event)"></pagination>
                    </div>
                    <br><br><br><br><br>
                    <div class="row">
                        <div class="table-responsive col-sm-6" >
                            <table>
                                <thead>
                                <tr>
                                    <th class="bg-primary cursor-auto">Day</th>
                                    <th class="bg-primary cursor-auto">New Users</th>
                                    <!--<th class="bg-primary">Exceptions / Jams / Errors</th>-->
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let day of userCountsArr">
                                    <td>
                                        <p class="m-b-0"
                                           *ngIf="day._id && day._id.day && day._id.month && day._id.year">
                                            {{day._id.day + '/' + day._id.month + '/' + day._id.year}}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="m-b-0">{{day.totalCount}}</p>
                                    </td>
                                </tr>
                                <tr *ngIf="isDataLoadedForUserCounts && !userCountsArr.length" >
                                    <td colspan="2"  >
                                     <div class="text-center"> No user found.</div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div class="col-lg-12 text-center" *ngIf="userCountsArr.length">
                                <pagination [totalItems]="totUserCounts" [(ngModel)]="userCountFilterObj.page"
                                            [itemsPerPage]="userCountFilterObj.limit"
                                            [boundaryLinks]="true" [maxSize]="5"
                                            (pageChanged)="fnUserCountPageChanged($event)"></pagination>
                            </div>
                        </div>


                        <div class="table-responsive col-sm-6" >
                            <table>
                                <thead>
                                <tr>
                                    <th class="bg-primary cursor-auto">Day</th>
                                    <th class="bg-primary cursor-auto">Notifications</th>
                                    <!--<th class="bg-primary">Exceptions / Jams / Errors</th>-->
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let day of notificationCountsArr">
                                    <td>
                                        <p class="m-b-0"
                                           *ngIf="day._id && day._id.day && day._id.month && day._id.year">
                                            {{day._id.day + '/' + day._id.month + '/' + day._id.year}}
                                        </p>
                                    </td>
                                    <td>
                                    <p class="m-b-0">{{day.totalCount}}</p>
                                </td>
                                </tr>
                                <tr *ngIf="isDataLoadedForNotificationCounts && !notificationCountsArr.length">
                                    <td colspan="2"  class=" text-center">
                                        <div class="text-center"> No Notification found.</div>

                                </td>
                                </tr>
                                </tbody>
                            </table>
                            <!--<h3 *ngIf="isDataLoaded && !notificationCountsArr.length" class="text-center"></h3>-->
                            <div class="col-lg-12 text-center" *ngIf="notificationCountsArr.length">
                                <pagination [totalItems]="totNotificationCounts" [(ngModel)]="notificationCountFilterObj.page"
                                            [itemsPerPage]="notificationCountFilterObj.limit"
                                            [boundaryLinks]="true" [maxSize]="5"
                                            (pageChanged)="fnNotificationCountPageChanged($event)"></pagination>
                            </div>
                        </div>
                    </div>

                </div>
            </tab>
        </tabset>
    </div>
</div>
