import { Injectable} from '@angular/core';
import {connect} from 'socket.io-client';
import {Observable} from 'rxjs';

@Injectable()
export class SocketIOService {
    socket: any;

    constructor() {
    }

    connectSocket(hostname: string) {
        this.socket = connect('https://' + hostname, {
            query: {mainApp: true}
        });
    }

    listenEvent(event: string) {
        if (!this.socket.connected) {
            throw new Error('Socket not connected');
        }
        return new Observable(observer => {
            this.socket.off(event).on(event, (data) => {
                observer.next(data);
            });
        });
    }

    emitEvent(event: string, data: any) {
        if (!this.socket.connected) {
            throw new Error('Socket not connected');
        }
        this.socket.emit(event, data);
    }

    closeConnection() {
        this.socket.disconnect();
    }
}
