<form (ngSubmit)="onConfirm()" *ngIf="active">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Reset Password Confirmation</h4>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to reset password for this user?</p>
        <p>New password will be sent to the user in email.</p>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="submit" class="btn btn-primary">Send</button>
            <button type="button" class="btn btn-default" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>
