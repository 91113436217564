<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Shipments</h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-10">
                <div class="form-group">
                    <input type="text" name="daterangeInput" class="form-control" daterangepicker
                           placeholder="Select Date Range"
                           [(ngModel)]="selectedCreatedAt"
                           [options]="{autoUpdateInput: false}"
                           (applyDaterangepicker)="onSelectedDate($event)"
                           (cancelDaterangepicker)="clearDate()"/>
                </div>
            </div>

            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="clearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div *ngIf="shipmentArr.length">
            <table class="shipments-table">
                <thead>
                <tr>
                    <th class="bg-primary carrier-name" (click)="sortBy('carrierName')">
                        Carrier Name <i [class]="setSortIcon('carrierName')"></i></th>
                    <th class="bg-primary shipment-status" (click)="sortBy('status')">
                        Status <i [class]="setSortIcon('status')"></i></th>
                    <th class="bg-primary created-at" (click)="sortBy('createdAt')">
                        Created <i [class]="setSortIcon('createdAt')"></i>
                    </th>
                    <th class="bg-primary expire-at cursor-auto">Expires</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let shipment of shipmentArr">
                    <td>{{shipment.carrierName}}</td>
                    <td>
                        <p class="m-b-0">
                            {{shipment.status ? (CONSTANT.READABLE_SHIPMENT_STATUS[shipment.status] | RemoveUnderscore) : '&nbsp;'}}
                        </p>
                        <small>{{shipment.packages[0].updatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</small>
                    </td>
                    <td>{{shipment.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>{{shipment.packages[0].expireAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !shipmentArr.length" class="text-center">No shipments found.</h3>
        <div class="col-lg-12 text-center" *ngIf="shipmentArr.length">
            <pagination [totalItems]="totShipments" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="pageChanged($event)"></pagination>
        </div>
    </div>
</div>

