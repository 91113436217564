<div class="container-fluid sign-in-page">
    <div class="row">
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <img class="img-logo" src="assets/images/pp_logo.png" alt="Parcel Port" width="100%"/>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <div class="login-panel panel panel-default whiteframe-10dp">
                <div class="panel-heading">
                    Reset Password
                </div>
                <div class="panel-body">
                    <form #resetPasswordForm="ngForm" role="form"
                          (ngSubmit)="resetPasswordForm.valid && fnUpdateNewPassword(resetPasswordForm)">
                        <fieldset>
                            <div class="form-group"
                                 [ngClass]="{'has-error': (resetPasswordForm.submitted || password.touched) && !password.valid}">
                                <input class="form-control" placeholder="New password" name="password" type="password"
                                       [(ngModel)]="resetPassObj.password" #password="ngModel" required minlength="8"/>
                                <div *ngIf="(resetPasswordForm.submitted || password.touched) && !password.valid">
                                    <small class="text-danger" *ngIf="password.errors['required']">
                                        Password is required.
                                    </small>
                                    <small class="text-danger" *ngIf="password.errors['minlength']">
                                        Password must be at least 8 character long.
                                    </small>
                                </div>
                            </div>
                            <div class="form-group"
                                 [ngClass]="{'has-error': (resetPasswordForm.submitted || confirmPassword.touched) && !confirmPassword.valid}">
                                <input class="form-control" placeholder="Confirm password" name="confirmPassword" type="password"
                                       [(ngModel)]="resetPassObj.confirmPassword" #confirmPassword="ngModel" required minlength="8" [pattern]="resetPassObj.password"/>
                                <div *ngIf="(resetPasswordForm.submitted || confirmPassword.touched) && !confirmPassword.valid">
                                    <small class="text-danger" *ngIf="confirmPassword.errors['required']">
                                        Confirm password is required.
                                    </small>
                                    <small class="text-danger" *ngIf="confirmPassword.errors['minlength']">
                                        Confirm password must be at least 8 character long.
                                    </small>
                                    <small class="text-danger" *ngIf="!confirmPassword.errors['minlength'] && confirmPassword.errors['pattern']">
                                        Password and Confirm password must be the same.
                                    </small>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block">Update Password</button>
                        </fieldset>
                    </form>
                </div>
                <div class="panel-footer text-center">
                    <a href="javascript:void(0);" (click)="fnGoToSignIn()">Sign In</a>
                </div>
            </div>
        </div>
    </div>
</div>
