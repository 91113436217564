<div class="row page">
    <div class="col-sm-12">
        <h1 class="page-header">Payment Plan</h1>
    </div>
    <div class="col-sm-10">
        <form #planForm="ngForm" (ngSubmit)="planForm.valid && savePlan(planForm)" novalidate>
            <fieldset class="row fieldset">
                <legend class="fieldset-legend">Create new payment plan</legend>
                <div class="row form-group">
                    <div class="col-sm-12"
                         [ngClass]="{'has-error': (planForm.submitted || company.touched) && !company.valid}">
                        <label for="company"> Company </label>
                        <select id="company" class="form-control" name="company"
                                [(ngModel)]="planObj.company"
                                #company="ngModel" required>
                            <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label> Plan Type </label>
                    <div class="col-sm-12 p-0 m-b-15">
                        <div class="btn-group">
                            <label class="btn btn-success" name="planType" [(ngModel)]="planType" *ngFor="let type of planTypeArr"
                                   [btnRadio]="type.name" (click)="setPlanType(planObj)">{{type.name | SpaceBeforeUppercase}}</label>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-sm-12"
                         [ngClass]="{'has-error': (planForm.submitted || planName.touched) && !planName.valid}">
                        <label for="planName"> Plan Name </label>
                        <input id="planName" type="text" class="form-control" name="planName"
                               placeholder="Enter plan name" [(ngModel)]="planObj.name"
                               #planName="ngModel" required/>
                    </div>
                </div>
                <div class="row" *ngIf="planType === planTypeArr[0].name">
                    <div class="form-group col-sm-4"
                         [ngClass]="{'has-error': (planForm.submitted || currency.touched) && !currency.valid}">
                        <label for="currency">Currency</label>
                        <select id="currency" class="form-control" name="currency"
                                [(ngModel)]="planObj.currency"
                                #currency="ngModel" required>
                            <option [ngValue]="item.id" *ngFor="let item of currencyArr">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-4"
                         [ngClass]="{'has-error': (planForm.submitted || fee.touched) && !fee.valid}">
                        <label for="fee">Fee</label>
                        <input type="number" step="any" id="fee" name="fee" class="form-control" placeholder="Enter plan fee" [(ngModel)]="planObj.fee"
                               #fee="ngModel" [pattern]="numRegEx" required>
                        <div *ngIf="(planForm.submitted || fee.touched) && !fee.valid">
                            <small class="text-danger" *ngIf="fee.errors['pattern']">Invalid fee amount.</small>
                        </div>
                    </div>
                    <div class="form-group col-sm-4"
                         [ngClass]="{'has-error': (planForm.submitted || billOnDayOfMonth.touched) && !billOnDayOfMonth.valid}">
                        <label for="billOnDayOfMonth">Bill On Day Of The Month</label>
                        <select id="billOnDayOfMonth" class="form-control" name="billOnDayOfMonth"
                                [(ngModel)]="planObj.billOnDayOfMonth"
                                #billOnDayOfMonth="ngModel" required>
                            <option [ngValue]="item" *ngFor="let item of days">{{item}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="planType === planTypeArr[1].name">
                    <div class="row form-group" >
                        <div class="col-sm-12">
                            <label>Charge Time</label>
                            <div class="col-sm-12 p-0 m-b-15">
                                <div class="btn-group">
                                    <label class="btn btn-success" name="afterDropOff" [(ngModel)]="planObj.chargeTime" btnRadio="AFTER_DROPOFF">After DropOff</label>
                                    <label class="btn btn-success" name="afterPickUp" [(ngModel)]="planObj.chargeTime" btnRadio="AFTER_PICKUP">After PickUp</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group table-responsive p-l-15 p-r-15">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-primary">Slot Size</th>
                                    <th class="bg-primary">Fees</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let slot of planObj.slotFees; let i = index">
                                    <td>{{slot.size | RemoveUnderscore}}</td>
                                    <td [ngClass]="{'has-error': (planForm.submitted || slotSizeFee.touched) && !slotSizeFee.valid}">
                                        <input type="number" step="any" name="slotSizeFee-{{i}}" class="form-control" [(ngModel)]="slot.fee"
                                               #slotSizeFee="ngModel" placeholder="Enter slot fee" [pattern]="numRegEx" required>
                                        <div *ngIf="(planForm.submitted || slotSizeFee.touched) && !slotSizeFee.valid">
                                            <small class="text-danger" *ngIf="slotSizeFee.errors['pattern']">Invalid fee amount.</small>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </fieldset>
            <div class="row form-group">
                <div class="col-sm-12">
                    <button class="btn btn-primary m-r-4" type="submit" >{{planObj._id ? 'Update' : 'Create'}} Plan</button>
                    <button class="btn btn-warning" [routerLink]="['/payment-plans']">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>


