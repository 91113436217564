<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header ">Users
            <button *ngIf="this.currentUser.role === CONSTANT.ROLE_ADMIN" class="pull-right btn btn-primary" [routerLink]="['/users/add']">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">New User</span>
            </button>
                <button class="pull-right btn btn-primary m-r-2"
                        *ngIf="filterObj.companyId && usersToUpdate.length"
                        (click)="fnOpenUpdateUsersPlanModal(updateUsersPlanTemplate)">
                    <i class="fa fa-pencil m-r-4"></i> <span class="hidden-xs">Update User's Plan</span>
                </button>
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="usersArr.length" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-1">
                <div class="form-group">
                    <select id="limit" class="form-control" name="pageLimit" [(ngModel)]="filterObj.limit" (change)="fnPerPageChanged(filterObj)">
                        <option [ngValue]="item" *ngFor="let item of perPageArr">{{item}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" [(ngModel)]="filterObj.companyId" (change)="filterObj.selectedUser = '';filterObj._id = '';selectedUser = '';filterObj.phone = '';searchPhoneTxt = '';fnChangeCompany(filterObj)">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <ng-template #customItemTemplate let-model="item" let-index="index">
                        {{model.firstName}}&nbsp;{{model.lastName}}
                    </ng-template>
                    <input id="users" name="users" class="form-control" autocomplete="off" [(ngModel)]="selectedUser" [typeahead]="dataSource"
                           (typeaheadOnSelect)="fnOnSelectUser($event)" [typeaheadItemTemplate]="customItemTemplate"
                           [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body" [typeaheadMinLength]="0"
                           [typeaheadOptionsInScrollableView]="10" (blur)="fnTypeAheadOnBlur($event)" placeholder="Select User" autocomplete="off">
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <input type="text" placeholder="Search By Phone #" class="form-control" [(ngModel)]="searchPhoneTxt"
                           (blur)="onPhoneInput()" autocomplete="off">
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <input type="email" placeholder="Search By Email" class="form-control" [(ngModel)]="searchEmailTxt"
                           (blur)="onEmailInput()" autocomplete="off">
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="fnClearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="table-responsive" *ngIf="usersArr.length">
            <table>
                <thead>
                    <tr>
                        <th class="bg-primary">
                            <input type="checkbox" class="form-check-input" name="isAllUsersChecked"
                                   [(ngModel)]="isAllUsersChecked"
                                   (change)="fnOnAllUsersChanged(isAllUsersChecked)"/>
                        </th>
                        <th class="bg-primary user-name" (click)="sortBy('firstName')">
                            Name <i [class]="setSortIcon('firstName')"></i></th>
                        <th class="bg-primary user-email" (click)="sortBy('email')">
                            Email <i [class]="setSortIcon('email')"></i></th>
                        <th class="bg-primary user-phone" (click)="sortBy('phone')">
                            Phone <i [class]="setSortIcon('phone')"></i></th>
                        <th class="bg-primary user-role" (click)="sortBy('role')">
                            Role <i [class]="setSortIcon('role')"></i></th>
                        <th class="bg-primary company-name" (click)="sortBy('companyId.name')">
                            Company <i [class]="setSortIcon('companyId.name')"></i></th>
                        <th class="bg-primary user-locker" (click)="sortBy('preferredLockerId.name')">
                            Locker <i [class]="setSortIcon('preferredLockerId.name')"></i>
                        </th>
                        <th class="bg-primary cursor-auto">City</th>
                        <th class="bg-primary cursor-auto" *ngIf="CONSTANT.ROLE_ADMIN === currentUser.role">Transactions</th>
                        <th class="bg-primary cursor-auto">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of usersArr" [ngClass]="{'user-details-text': user.status === USER_STATUS.ARCHIVED}">
                        <td><input type="checkbox" class="form-check-input" [(ngModel)]="user.isChecked"
                                   (change)="fnOnUserChanged()"/></td>
                        <td>
                            <span>
                                {{(user.firstName || '') + '&nbsp;' + (user.lastName || '')}}
                            </span>
                        </td>
                        <td>{{user.email ? user.email : '&nbsp;'}}</td>
                        <td>{{user.phone ? user.phone : '&nbsp;'}}</td>
                        <td>{{user.role ? user.role : '&nbsp;'}}</td>
                        <td>{{user.companyId ? user.companyId['name'] : '&nbsp;'}}</td>
                        <td>{{user.preferredLockerId ? user.preferredLockerId['name'] : '&nbsp;'}}</td>
                        <td>{{user.locationId ? user.locationId.city : '&nbsp;'}}</td>
                        <td *ngIf="CONSTANT.ROLE_ADMIN === currentUser.role">
                            <button type="button" class="btn btn-xs btn-primary" [routerLink]="['/transactions']" skipLocationChange [queryParams]="{sortBy:'transactionAt', orderBy:'desc', page:1, limit:10, userId:user._id, name:user.firstName + ' ' +
                                user.lastName, companyId: user.companyId ? user.companyId['_id'] : '',
                                                lockerId: user.preferredLockerId ? user.preferredLockerId._id : ''}">
                                View
                            </button>
                        </td>
                        <td>
                            <div class="btn-group btn-group-xs" *ngIf="fnCheckUserRoleForEdit(user)">
                                <button type="button" class="btn btn-primary" [routerLink]="['/users/'+user._id]" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button type="button" class="btn btn-danger" (click)="fnDeleteUser(user._id)">
                                    <i class="fa fa-trash-o"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !usersArr.length" class="text-center">No users found.</h3>
        <div class="col-lg-12 text-center" *ngIf="usersArr.length">
            <pagination [totalItems]="totUsers" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit" [boundaryLinks]="true"
                [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
</div>

<ng-template #updateUsersPlanTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Update users plan</h4>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="filterObj.companyId">
            <div class="col-sm-12" *ngIf="plansArr.length > 1">
                <label for="plans"><strong>Payment Plan:</strong></label>
                <div class="form-group" *ngIf="filterObj.companyId">
                    <select id="plans" class="form-control" name="plans" [disabled]="!usersToUpdate.length || plansArr.length <= 1"
                            [(ngModel)]="selectedPlanId">
                        <option [ngValue]="item._id" *ngFor="let item of plansArr">{{item.planName}}</option>
                    </select>
                    <div *ngIf="!usersToUpdate.length">
                        <small class="text-danger">Please select at least one user to update plan.</small>
                    </div>
                </div>
            </div>
            <div class="m-l-15 m-r-15" *ngIf="plansArr.length <= 1 && usersToUpdate.length">
                <strong class="text-danger">There is no active plan for now.</strong>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button class="btn btn-primary" type="submit" (click)="fnUpdateMultiUsersPlan()">
                OK
            </button>
            <button class="btn btn-default" type="button" (click)="updatePlanModalRef.hide()">
                Cancel
            </button>
        </div>
    </div>
</ng-template>
