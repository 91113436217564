<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Lockers
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="lockersArr.length" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12 m-t-10">
        <div class="table-responsive" *ngIf="lockersArr.length">
            <!-- START : Lockers Table -->
            <form  #lockersForm="ngForm" novalidate>
            <table>
                <thead>
                <tr class="lockers-table-headers">
                    <th class="bg-primary" style="width: 33px">
                        <a class="m-l-4" href="javascript:void(0);" (click)="fnToggleAllCabinets()">
                            <span class="fa" [ngClass]="isAllCabinets ? 'fa-minus' : 'fa-plus'"></span></a></th>
                    <th class="bg-primary locker-number" (click)="sortBy('lockerNumber')">
                        Locker No. <i [class]="setSortIcon('lockerNumber')"></i></th>
                    <th class="bg-primary locker-name" (click)="sortBy('name')">
                        Name <i [class]="setSortIcon('name')"></i></th>
                    <th class="bg-primary country" (click)="sortBy('locationId.country')">
                        Country <i [class]="setSortIcon('locationId.country')"></i></th>
                    <th class="bg-primary postalCode" (click)="sortBy('locationId.postalCode')">
                        Postal Code <i [class]="setSortIcon('locationId.postalCode')"></i></th>
                    <th class="bg-primary is-online" (click)="sortBy('isOnline')">
                        Online <i [class]="setSortIcon('isOnline')"></i></th>
                    <th class="bg-primary cursor-auto">APK ver.</th>
                    <th class="bg-primary cursor-auto">Total Cabinets</th>
                    <th class="bg-primary cursor-auto">Total Slots</th>
                </tr>
                </thead>
                <tbody>
                <ng-template ngFor let-locker [ngForOf]="lockersArr" let-i="index">
                    <tr>
                        <td><a class="m-l-4" href="javascript:void(0);" (click)="fnToggleCabinets(locker)">
                            <i class="fa" [ngClass]="locker.isCabinets ? 'fa-minus' : 'fa-plus'"></i></a></td>
                        <td>{{locker.lockerNumber ? locker.lockerNumber : '&nbsp;'}}</td>
                        <td>{{locker.name ? locker.name : '&nbsp;'}}</td>
                        <td>{{locker.locationId.country ? locker.locationId.country : '&nbsp;'}}</td>
                        <td>{{locker.locationId.postalCode ? locker.locationId.postalCode : '&nbsp;'}}</td>
                        <td>{{locker.isOnline ? 'Yes' : 'No'}}</td>
                        <td>{{locker.lastKnownVersion || ' '}}</td>
                        <td>{{locker.cabinets.length}}</td>
                        <td>{{locker.totalSlots}}</td>
                    </tr>

                    <!-- Start : Access codes Table -->
                    <tr class="access-codes-row" width="100%" *ngIf="locker.hasAccessCodes && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                        <td></td>
                        <td colspan="12">
                            <table width="100%">
                                <thead>
                                <tr width="100%">
                                    <th class="bg-primary cursor-auto" colspan="12">
                                        Front Desk Access Codes
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr width="100%">
                                    <td *ngFor="let code of locker.accessCodes; let j = index; trackBy: trackByFn"
                                        [width]="(100/locker.accessCodes.length)+'%'">
                                        <input type="text" name="access_code_{{i}}_{{j}}" #accessCodeInput="ngModel"
                                               [(ngModel)]="locker.accessCodes[j]"
                                               placeholder="Enter access code {{j+1}}"
                                               [disabled]="!selectedLocker || (locker._id !== selectedLocker._id || ( locker._id === selectedLocker._id && j !== selectedAccessCodeIndex))"
                                               maxlength="30" [pattern]="alphaNumRegEx"/>
                                        <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                                (click)="toggleAccessCodeSaveBtn(locker, j, UnsavedAccessCodesDialog, UnsavedReturnPickupCodeDialog, UnsavedProductReturnPickupCodeDialog)"
                                                *ngIf="!selectedLocker || (locker._id !== selectedLocker._id || ( locker._id === selectedLocker._id && j !== selectedAccessCodeIndex))"
                                                [disabled]="selectedLocker && locker._id === selectedLocker._id && !accessCodeInput.valid && accessCodeInput.touched">
                                            Edit
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm rounded-pill"
                                                (click)="saveAccessCodes(locker)"
                                                *ngIf="selectedLocker && locker._id === selectedLocker._id && j === selectedAccessCodeIndex"
                                                [disabled]="!accessCodeInput.valid">
                                            Save
                                        </button>
                                        <div
                                            *ngIf="!accessCodeInput.valid && accessCodeInput.touched && accessCodeInput.errors && accessCodeInput.errors['pattern']">
                                            <small class="text-danger">Access codes must be alpha-numeric</small>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <!-- End : Access codes Table -->

                    <!-- Start : Return pickup codes Table -->
                    <tr class="access-codes-row" width="100%" *ngIf="locker.hasReturnPickupCode && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                        <td></td>
                        <td colspan="12">
                            <table width="100%">
                                <thead>
                                <tr width="100%">
                                    <th class="bg-primary cursor-auto">
                                        Expired Shipment Pickup Code
                                    </th>
                                    <th class="bg-primary cursor-auto" *ngIf="locker.isOutboundEnabled">
                                        Product Return / Outbound Shipment Pickup Code
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr width="100%">
                                    <td width="50%">
                                        <input id="returnPickupCode" type="text" class="input-sm" [ngClass]="locker.isOutboundEnabled ? 'w-55' : 'w-20'"
                                               name="returnPickupCode_{{locker._id}}"
                                               [pattern]="alphaNumRegEx" #returnPickupCode="ngModel" minlength="6"
                                               maxlength="30"
                                               [disabled]="!selectedLocker || (selectedLocker && ((selectedLocker._id !== locker._id) || (selectedLocker._id === locker._id && !toggleReturnPickup)))"
                                               placeholder="Enter expired shipment pickup code"
                                               [(ngModel)]="locker.returnPickupCode" autocomplete="off"/>
                                        <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                                *ngIf="!selectedLocker || (selectedLocker && (selectedLocker._id !== locker._id || (selectedLocker._id === locker._id && !toggleReturnPickup)))"
                                                (click)="toggleReturnPickupCodeBtn(locker, UnsavedReturnPickupCodeDialog, UnsavedAccessCodesDialog, UnsavedProductReturnPickupCodeDialog)">
                                            Edit
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm rounded-pill"
                                                [disabled]="!returnPickupCode.valid"
                                                *ngIf="selectedLocker && selectedLocker._id === locker._id && toggleReturnPickup"
                                                (click)="saveReturnPickupCode(locker)">
                                            Save
                                        </button>
                                        <div *ngIf="returnPickupCode.touched && !returnPickupCode.valid">
                                            <p *ngIf="returnPickupCode.errors && returnPickupCode.errors['pattern']">
                                                <small class="text-danger">Expired shipment pickup code must be alpha-numeric</small>
                                            </p>
                                            <p *ngIf="returnPickupCode.errors && returnPickupCode.errors['minlength']">
                                                <small class="text-danger">Expired shipment pickup code must be at least 6 characters</small>
                                            </p>
                                        </div>
                                    </td>

                                    <td width="50%" *ngIf="locker.isOutboundEnabled">
                                        <input id="productReturnPickupCode" type="text"  class="input-sm w-55" #productReturnPickupCode="ngModel"
                                               name="productReturnPickupCode_{{locker._id}}"
                                               [disabled]="!toggleProductReturnPickup"
                                               [pattern]="'(^$)|(^[0-9]{6}$)'"
                                               placeholder="Enter product return / outbound shipment pickup code"
                                               [(ngModel)]="locker.productReturnPickupCode" autocomplete="off"/>
                                        <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                                *ngIf="!toggleProductReturnPickup"
                                                (click)="toggleProductReturnPickupCode(locker, UnsavedReturnPickupCodeDialog, UnsavedAccessCodesDialog)">
                                            Edit
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm rounded-pill"
                                                [disabled]="!productReturnPickupCode.valid"
                                                *ngIf=" toggleProductReturnPickup"
                                                (click)="saveProductReturnPickupCode(locker)">
                                            Save
                                        </button>
                                        <div *ngIf="productReturnPickupCode.touched && !productReturnPickupCode.valid">
                                            <p *ngIf="productReturnPickupCode.errors && productReturnPickupCode.errors['pattern']">
                                                <small class="text-danger">Product return / outbound shipment pickup code must be alpha-numeric</small>
                                            </p>
                                            <p *ngIf="productReturnPickupCode.errors && productReturnPickupCode.errors['minlength']">
                                                <small class="text-danger">Product return / outbound shipment pickup code must be at least 6 characters</small>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <!-- End : Return pickup codes Table -->

                    <!-- START : Cabinets Table -->
                    <tr class="cabinets-row" *ngIf="locker.isCabinets">
                        <td></td>
                        <td colspan="12">
                            <table *ngIf="locker.cabinets.length">
                                <thead>
                                <tr>
                                    <th class="bg-primary" style="width: 33px">
                                        <a class="m-l-4" href="javascript:void(0);" (click)="fnToggleAllSlots(locker)">
                                            <span class="fa"
                                                  [ngClass]="locker.isAllSlots ? 'fa-minus' : 'fa-plus'"></span>
                                        </a>
                                    </th>
                                    <th class="bg-primary cursor-auto">Cabinet Type</th>
                                    <th class="bg-primary cursor-auto">Cabinet Number</th>
                                    <th class="bg-primary cursor-auto">Available Slots</th>
                                    <th class="bg-primary cursor-auto">Total Slots</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-template ngFor let-cabinet [ngForOf]="locker.cabinets" let-j="index">
                                    <tr>
                                        <td><a class="m-l-4" href="javascript:void(0);"
                                               (click)="fnToggleSlots(locker, cabinet)">
                                            <i class="fa" [ngClass]="cabinet.isSlots ? 'fa-minus' : 'fa-plus'"></i></a>
                                        </td>
                                        <td>{{cabinet.type ? cabinet.type : '&nbsp;'}}</td>
                                        <td>{{cabinet.cabinetNumber ? cabinet.cabinetNumber : '&nbsp;'}}</td>
                                        <td>{{cabinet.totAvailableSlots}}</td>
                                        <td>{{cabinet.slots.length}}</td>
                                    </tr>
                                    <!-- START : Slots Table -->
                                    <tr class="slots-row" *ngIf="cabinet.isSlots">
                                        <td colspan="5">
                                            <table *ngIf="cabinet.slots.length">
                                                <thead>
                                                <tr>
                                                    <th class="bg-primary" width="2%">
                                                        <a class="m-l-4" href="javascript:void(0);" (click)="fnToggleAllShipments(cabinet)">
                                                        <span class="fa"
                                                              [ngClass]="cabinet.isAllShipments ? 'fa-minus' : 'fa-plus'"></span>
                                                        </a>
                                                    </th>
                                                    <th class="bg-primary cursor-auto" width="10%">Slot Number</th>
                                                    <th class="bg-primary cursor-auto" width="15%">Size</th>
                                                    <th class="bg-primary cursor-auto" width="25%">Status</th>
                                                    <th class="bg-primary cursor-auto" width="10%">Door</th>
                                                    <th class="bg-primary cursor-auto" width="10%">Occupancy</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <ng-template ngFor let-slot [ngForOf]="cabinet.slots" let-k="index">
                                                    <tr>
                                                        <td width="2%">
                                                            <a class="m-l-4" href="javascript:void(0);"
                                                               *ngIf="((slot.occupied !== undefined) && slot.occupied) || (slot.occupied === undefined && slot.packageId.shipmentId !== '' && (slot.packageId.shipmentId | json) !== '{}')"
                                                               (click)="fnToggleShipments(cabinet, slot)">
                                                                <i class="fa"
                                                                   [ngClass]="slot.isShipments ? 'fa-minus' : 'fa-plus'"></i>
                                                            </a>
                                                        </td>
                                                        <td width="10%">{{slot.slotNumber}}</td>
                                                        <td width="15%">{{slot.type | RemoveUnderscore}}</td>
                                                        <td width="25%">
                                                            <div class="pull-left">
                                                                {{slot.status | RemoveUnderscore}}
                                                            </div>
                                                            <div class="pull-right btn-group btn-group-xs" *ngIf="slot.status === CONSTANT.SLOT_STATUS_REPAIR">
                                                                <button type="button" class="btn btn-default" (click)="showSlotReason(ReasonModal, cabinet.cabinetNumber, slot);">
                                                                    <i class="fa fa-info-circle"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td width="10%">{{(slot.open !== undefined) ? slot.open ? 'Open' : 'Close' : ''}}</td>
                                                        <td width="15%">{{(slot.occupied !== undefined) ? slot.occupied ? 'Yes' : 'No' : ''}}</td>
                                                    </tr>

                                                    <!-- START : Shipments Table -->
                                                    <tr class="slots-row" *ngIf="slot.isShipments && slot.packageId">
                                                        <td></td>
                                                        <td colspan="5">
                                                            <table *ngIf="slot.packageId && slot.status === CONSTANT.SLOT_STATUS_UNAVAILABLE">
                                                                <thead>
                                                                <tr>
                                                                    <th class="bg-primary">Package Label</th>
                                                                    <th class="bg-primary">Carrier Name</th>
                                                                    <th class="bg-primary">Receiver</th>
                                                                    <th class="bg-primary">Shipment Status</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>{{slot.packageId.label}}</td>
                                                                    <td>{{slot.packageId.shipmentId ? slot.packageId.shipmentId.carrierName : ''}}</td>
                                                                    <td>
                                                                        <div *ngIf="slot.packageId.shipmentId && slot.packageId.shipmentId.receiver">
                                                                            <p class="m-b-0">
                                                                                {{slot.packageId.shipmentId.receiver['firstName'] + ' ' + slot.packageId.shipmentId.receiver['lastName']}}
                                                                            </p>
                                                                            <small>{{slot.packageId.shipmentId.receiver['accessCode']}}</small>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <p class="m-b-0">
                                                                            {{slot.packageId.shipmentId && slot.packageId.shipmentId.status ? (slot.packageId.shipmentId.status | RemoveUnderscore) : ''}}
                                                                        </p>
                                                                        <small>{{slot.packageId.updatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</small>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <!-- END : Shipments Table -->
                                                </ng-template>
                                                </tbody>
                                            </table>
                                            <h5 *ngIf="!cabinet.slots.length" class="text-center">No slots found.</h5>
                                        </td>
                                    </tr>
                                    <!-- END : Slots Table -->
                                </ng-template>
                                </tbody>
                            </table>
                            <h5 *ngIf="!locker.cabinets.length" class="text-center">No cabinets found.</h5>
                        </td>
                    </tr>
                    <!-- END : Cabinets Table -->
                </ng-template>
                </tbody>
            </table>
            <!-- END : Lockers Table -->
            </form>
        </div>
        <h3 *ngIf="isDataLoaded && !lockersArr.length" class="text-center">No lockers found.</h3>
        <div class="col-lg-12 text-center" *ngIf="lockersArr.length">
            <pagination [totalItems]="totalLockers" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true" [maxSize]="5" (pageChanged)="pageChanged($event)"></pagination>
        </div>
    </div>
</div>

<ng-template #UnsavedAccessCodesDialog>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Unsaved Access codes</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            You have update access code of <b>{{ selectedLocker.name }}</b>, Please save it first then edit another access code.
        </p>
    </div>
</ng-template>

<ng-template #UnsavedReturnPickupCodeDialog>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Unsaved Return pickup code</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeReturnPickupCodeModal();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            You have update return pickup code of <b>{{ selectedLocker.name }}</b>, Please save it first then edit another return pickup code.
        </p>
    </div>
</ng-template>

<ng-template #UnsavedProductReturnPickupCodeDialog>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Unsaved Product Return / Outbound Shipment pickup code</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeProductReturnPickupCodeModal();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            You have update product return / outbound shipment pickup code of <b>{{ selectedLocker.name }}</b>, Please save it first then edit another product return / outbound shipment pickup code.
        </p>
    </div>
</ng-template>

<ng-template #ReasonModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            <b>{{ slotInfo.status === CONSTANT.SLOT_STATUS_REPAIR ? 'Repair' : 'Not In Use'}} : {{ slotInfo.slotNumber }}</b>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeReasonModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            This Slot {{ slotInfo.status === CONSTANT.SLOT_STATUS_REPAIR ? 'needs to be repair' : 'is currently not in use'}} due to the following reason:
        </p>
        <p>
            <b>{{ slotInfo.reason }}</b>
        </p>
    </div>
</ng-template>
