import { Component, OnInit } from '@angular/core';
import {ShipmentsService} from '../shipments/shipments.service';
import {User} from '../users/user';
import {AuthService} from '../../common/auth.service';
import {USER_ROLE} from '../../../../server/model/user';
import {Company} from '../companies/company';
import {CompaniesService} from '../companies/companies.service';
import {LockersService} from '../lockers/lockers.service';
import {Locker} from '../lockers/locker';
import {Observable, mergeMap} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {UsersService} from '../users/users.service';
import {ToastrService} from '../../common/toastr.service';
import {StorageService} from '../../common/storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Constant} from '../../common/constant';
import {debounceTime, filter} from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'pp-pending-shipments',
  templateUrl: './pending-shipments.component.html',
  styleUrls: ['./pending-shipments.component.css']
})

export class PendingShipmentsComponent implements OnInit {
    public currentUser: User;
    public perPageArr: Array<number> = [10, 50, 100, 500];
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];
    public shipmentArr: any = [];
    public totShipments: number;
    public isDataLoaded: boolean;
    public selectedUser: any;
    readonly CONSTANT = Constant;
    public filterObj: any;
    readonly USER_ROLE = USER_ROLE;
    private PENDING_SHIPMENTS_FILTERS = 'pendingShipmentsFilters';
    dataSource: Observable<any>;
    public previousToken = '';
    public previousInputValue = '';
    public isExporting: boolean;
    public isReportAllowed;

    constructor(private shipmentService: ShipmentsService, private auth: AuthService, private usersService: UsersService,
                private companiesService: CompaniesService, private lockersService: LockersService,
                private toastr: ToastrService, private storage: StorageService, private router: Router,
                private activatedRoute: ActivatedRoute,
                private _bsModalService: BsModalService) {
        this.currentUser = this.auth.fnGetCurrentUser();
        this.filterObj = {sortBy: '', orderBy: '', page: 0, limit: 10, companyId: '', lockerId: ''};
        this.isDataLoaded = false;
        this.totShipments = 0;
        this.getStoredData();
    }

    async ngOnInit() {
        if (this.filterObj.receiver) {
            const userId: string = this.filterObj.receiver;
            const receiverInfo: User = await this.usersService.fnGetUser(userId);
            this.selectedUser = receiverInfo.firstName + ' ' + receiverInfo.lastName;
        }
        this.previousInputValue = this.selectedUser;
        await this.getCompanies();
        if (this.filterObj.companyId) {
            await this.getLockers(this.filterObj.companyId);
        }
        await this.getShipments(this.filterObj);
        this.previousInputValue = this.selectedUser;
        this.dataSource = new Observable((observer: any) => {
            observer.next(this.selectedUser || '');
        }).pipe(
            debounceTime(500),
            filter((data: string) => this.previousToken !== data.trim()),
            mergeMap((token: string) => {
                this.previousToken = token && token.trim();
                if (!this.filterObj.companyId) {
                    this.toastr.fnWarning('Please select company to search the users');
                    return of([]);
                }
                return this.previousToken ? this.filterResults(token) : of([]);
            }));
    }

    setDefaultData() {
        this.filterObj.lockerId = '';
        this.filterObj.sortBy = 'createdAt';
        this.filterObj.orderBy = 'desc';
        this.filterObj.page = 1;
        this.filterObj.limit = 10;
        if (this.filterObj.trackingNumber) {
            delete this.filterObj.trackingNumber;
        }
        if (this.filterObj.receiver) {
            delete this.filterObj.receiver;
        }
        this.selectedUser = '';
    }

    filterResults(token: string) {
        return this.usersService.fnGetUsers({companyId: this.filterObj.companyId, preferredLockerId: this.filterObj.lockerId, name: token});
    }

    onSelectUser(event: TypeaheadMatch): void {
        this.filterObj.receiver = event.item._id;
        this.selectedUser = event.item.firstName + ' ' + event.item.lastName;
        this.getShipments(this.filterObj);
    }

    typeAheadOnBlur(event: any): void {
        this.previousToken = '';
        const selectedUser = this.selectedUser && this.selectedUser.trim();
        const inputValue = event.target.value && event.target.value.trim();
        if (!selectedUser && !inputValue && this.previousInputValue !== inputValue) {
            delete this.filterObj.receiver;
            this.getShipments(this.filterObj);
        }
        this.previousInputValue = inputValue;
    }

    /**
     * Get Shipments
     * @param {object} params
     * */
    async getShipments(params: any) {
        this.isDataLoaded = false;
        try {
            this.storage.fnStoreData(this.PENDING_SHIPMENTS_FILTERS, params);
            const res: any = await this.shipmentService.getPendingShipments(params);
            this.shipmentArr = res.data;
            this.totShipments = res.total;
        } catch (e) {
            console.error('Error occurred in getting pending shipments', e);
        } finally {
            this.isDataLoaded = true;
        }
    }

    /**
     * Get companies
     * */
    async getCompanies(): Promise<void> {
        this.isDataLoaded = false;
        try {
            switch (this.currentUser.role) {
                case USER_ROLE.ADMIN:
                    const companiesRes: any = await this.companiesService.fnGetCompanies();
                    this.companiesArr = companiesRes;
                    this.companiesArr.unshift({_id: '', name: 'Select Company'});
                    break;
                case USER_ROLE.COMPANY_ADMIN:
                    this.filterObj.companyId = this.currentUser.companyId;
                    break;
            }
        } catch (e) {
            console.error('Error occurred while getting companies.', e);
        } finally {
            this.isDataLoaded = true;
        }
    }

    /**
     * Get list of lockers
     * */
    async getLockers(companyId: string): Promise<void> {
        this.isDataLoaded = false;
        this.lockersArr = [{_id: '', name: 'Select Locker'}];
        try {
            if (this.filterObj.companyId) {
                const res = <Locker[]> await this.lockersService.getLockersNameAndNumber(companyId);
                this.lockersArr.push(...res);
            }
            const index = this.lockersArr.findIndex(locker => locker.name.toLowerCase().indexOf('barrie') > -1);
            this.isReportAllowed = index > -1;
        } catch (e) {
            console.error('Error occurred while getting lockers for the company id' + this.filterObj.companyId, e);
        } finally {
            this.isDataLoaded = true;
        }
    }

    /**
     * Company change
     * */
    async changeCompany() {
        this.filterObj.trackingNumber = '';
        this.setDefaultData();
        await this.getLockers(this.filterObj.companyId);
        await this.getShipments(this.filterObj);
    }

    async changeLocker(params) {
        params.trackingNumber = '';
        params.receiver = '';
        this.selectedUser = '';
        await this.getShipments(params);
    }

    /**
     * Change pagination
     * @param {any} event
     * */
    async pageChanged(event: any) {
        this.filterObj.page = event.page;
        await this.getShipments(this.filterObj);
    }

    async clearFilters() {
        this.storage.fnRemove(this.PENDING_SHIPMENTS_FILTERS);
        this.setDefaultData();
        await this.getCompanies();
        this.filterObj.companyId = this.currentUser.role === this.USER_ROLE.COMPANY_ADMIN ?
            this.currentUser.companyId : this.companiesArr[0]._id;
        await this.getLockers(this.filterObj.companyId);
        await this.getShipments(this.filterObj);
    }

    async onLabelInput() {
        this.filterObj.trackingNumber ?  this.filterObj.trackingNumber.trim() : delete this.filterObj.trackingNumber;
        this.filterObj.page = 1;
        await this.getShipments(this.filterObj);
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    async sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }
        this.getStoredData();
        const res: any = await this.shipmentService.getPendingShipments(this.filterObj);
        this.shipmentArr = res.data;
        this.totShipments = res.total;
        this.isDataLoaded = true;
    }

    getStoredData = () => {
        const filters = this.storage.fnGetData(this.PENDING_SHIPMENTS_FILTERS);
        if (filters) {
            for (const key in this.filterObj) {
                if (this.filterObj.hasOwnProperty(key) && this.filterObj[key]) {
                    this.filterObj[key] = this.filterObj[key];
                }
            }
            for (const key in filters) {
                if (!this.filterObj[key]) {
                    this.filterObj[key] = filters[key];
                }
            }
        } else {
            this.setDefaultData();
        }
    }

    deletePendingShipment(pendingShipmentId: string) {
        if (pendingShipmentId) {
            const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
            (<DeleteModalComponent>modal.content).showConfirmationModal(
                'Delete',
                'Are you sure you want to delete this pending shipment?'
            );

            (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
                if (result) {
                    this.shipmentService.deletePendingShipment(pendingShipmentId)
                        .then(async () => {
                            this.filterObj.page = 1;
                            this.getShipments(this.filterObj);
                            this.toastr.fnSuccess('Pending shipment deleted successfully.');
                        })
                        .catch((error) => {
                            this.toastr.fnError(error.message);
                        });
                }
            });
        }
    }

    getBarcode(trackingNumber: string) {
        this.shipmentService.getBarcode(trackingNumber).then((barcodeBuffer: any) => {
            const buffer = new Uint8Array(barcodeBuffer.data);
            const text = String.fromCharCode.apply(null, buffer);

            const arrayBuffer = new ArrayBuffer(text.length);
            const int8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < text.length; i++) {
                int8Array[i] = text.charCodeAt(i);
            }
            const blob = new Blob([int8Array], { type: 'image/png' });

            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        }).catch((error) => {
            this.toastr.fnError(error.message);
        });
    }

    async generateReport() {
        this.isExporting = true;
        try {
            let params = {
                companyId: this.currentUser.companyId,
                date: new Date().toLocaleDateString("en-US")
            };
            if (this.filterObj.lockerId) {
                params = {...params, ...{lockerId: this.filterObj.lockerId}};
            }
            const data = <Blob> await this.shipmentService.getReport(params);
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url= window.URL.createObjectURL(blob);
            window.open(url);
        } catch (error) {
            const err = JSON.parse(await error.text());
            // console.log('error', error.text());
            this.toastr.fnError(err.message);
        } finally {
            this.isExporting = false;
        }
    }
}
