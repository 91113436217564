import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../users/user';
import {Constant} from '../../common/constant';
import {AuthService} from "../../common/auth.service";
import {ExportService} from "../../common/export.service";
import {NotificationsService} from "./notifications.service";
import {Observable, mergeMap} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {UsersService} from "../users/users.service";
import * as moment from 'moment';
import * as _ from 'lodash';
import {DaterangePickerComponent} from 'ng2-daterangepicker';
import {ToastrService} from '../../common/toastr.service';


@Component({
    selector: 'pp-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, AfterViewInit {
    @ViewChild(DaterangePickerComponent, {static: false})
    private picker: DaterangePickerComponent;
    public currentUser: User;
    public selectedUser: any;
    public selectedCreatedAt: any;
    public labelSearch: any;
    public filterObj: any;
    public isDataLoaded;
    public isExporting: boolean;
    public notificationCounts: any;
    public notificationArr = [];
    readonly CONSTANT = Constant;
    public totNotifications: number;
    public invalidDateRange: boolean;
    public notificationDetail;
    defaultDateRangeDays: number;
    maxDateRangeDays: number;
    typeAheadLoading: boolean;
    typeaheadNoResults: boolean;
    dataSource: Observable<any>;

    constructor(private auth: AuthService, private exportService: ExportService, private notificationService: NotificationsService,
                private usersService: UsersService, private toastr: ToastrService) {
        this.currentUser = this.auth.fnGetCurrentUser();
        this.isDataLoaded = false;
        this.isExporting = false;
        this.defaultDateRangeDays =  Constant.DEFAULT_DATE_RANGE_DAYS_ADMIN;
        this.maxDateRangeDays = Constant.MAX_DATE_RANGE_DAYS_ADMIN;
        this.totNotifications = 0;
        this.notificationCounts = {
            sentToday: '',
            errorsToday: ''
        };
        this.invalidDateRange = false;
        this.filterObj = {
            sortBy: 'createdAt',
            orderBy: 'desc',
            page: 1,
            limit: 10,
            startDate: this.getDefaultDateRange().startDate,
            endDate: this.getDefaultDateRange().endDate,
        };
        this.selectedCreatedAt = this.filterObj.startDate && this.filterObj.endDate ?
            this.filterObj.startDate + ' - ' + this.filterObj.endDate : '';
        this.notificationDetail  = {};
    }

    ngOnInit() {
        this.fnGetNotifications(this.filterObj);
        this.dataSource = new Observable((observer: any) => {
            observer.next(this.selectedUser);
        }).pipe(mergeMap((token: string) => this.filterResults(token)));
    }

    ngAfterViewInit(): void {
        this.picker.datePicker.setStartDate(this.filterObj.startDate);
        this.picker.datePicker.setEndDate(this.filterObj.endDate);
    }

    getDefaultDateRange() {
        const dateRange = {startDate: null, endDate: null};
        dateRange.startDate = moment().subtract(this.defaultDateRangeDays, 'd').format('MM/DD/YYYY');
        dateRange.endDate = moment().format('MM/DD/YYYY');
        return dateRange;
    }

    changeDateRange() {
        this.invalidDateRange = false;
        if (!this.selectedCreatedAt) {
            this.toastr.fnError('Please select the date range to load shipment history');
        }
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                /*this.filterObj.sortBy = 'companyId.name';*/
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        if (this.currentUser.role === Constant.ROLE_COMPANY_ADMIN) {
            const sortArr = this.filterObj.sortBy.split('.');
            if (sortArr.length === 2) {
                this.notificationArr = _.orderBy(this.notificationArr, (e) => e[sortArr[0]][sortArr[1]], [this.filterObj.orderBy]);
            } else if (sortArr.length === 1) {
                this.notificationArr = _.orderBy(this.notificationArr, (e) => e[sortArr[0]], [this.filterObj.orderBy]);
            }
        } else {
            this.notificationService.fnGetNotifications(this.filterObj)
                .then((res: any) => {
                    this.isDataLoaded = true;
                    this.totNotifications = res.metadata.length ? res.metadata[0].total : 0;
                    this.notificationArr = res.data;
                });
        }
    }

    fnGetNotifications(params?: object) {
        if (this.invalidDateRange) {
            return;
        }
        this.isDataLoaded = false;
        this.notificationService.fnGetNotifications(params)
            .then((res: any) => {
                this.isDataLoaded = true;
                this.totNotifications = res.metadata.length ? res.metadata[0].total : 0;
                this.notificationCounts.sentToday = res.sent_today.toString();
                this.notificationCounts.errorsToday = res.errors_today.toString();
                this.notificationArr = res.data;
            });
    }

    fnOnLabelInput() {
        this.labelSearch ? this.filterObj.label = this.labelSearch.trim() : delete this.filterObj.label;
        this.filterObj.page = 1;
        this.fnGetNotifications(this.filterObj);
    }

    selectedDate(value: any) {
        if (value) {
            this.filterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
            this.filterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
            const dayDiff = moment(this.filterObj.endDate).diff(moment(this.filterObj.startDate), 'days');
            this.invalidDateRange = dayDiff > this.maxDateRangeDays;
        }
        this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        this.fnChangeNotifications(this.filterObj);
    }

    fnClearDate() {
        this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        this.invalidDateRange = false;
        this.picker.datePicker.setStartDate(this.filterObj.startDate);
        this.picker.datePicker.setEndDate(this.filterObj.endDate);
        this.fnChangeNotifications(this.filterObj);
    }

    // fnOnDateChange(value: Date): void {
    //     if (value) {
    //         this.filterObj.startDate = moment(value[0]).format('DD/MM/YYYY');
    //         this.filterObj.endDate = moment(value[1]).format('DD/MM/YYYY');
    //     } else {
    //         delete this.filterObj.startDate;
    //         delete this.filterObj.endDate;
    //     }
    //     this.fnChangeNotifications(this.filterObj);
    // }

    filterResults(token: string) {
        return this.usersService.fnGetUsers({companyId: this.filterObj.companyId, name: token});
    }

    changeTypeAheadLoading(e: boolean): void {
        this.typeAheadLoading = e;
    }

    fnTypeAheadOnBlur(event: any): void {
        if (!this.selectedUser) {
            delete this.filterObj.userId;
        } else {
            this.filterObj.userId = event.item._id;
        }
        this.fnChangeNotifications(this.filterObj);
    }

    fnOnSelectUser(event: TypeaheadMatch): void {
        this.filterObj.userId = event.item._id;
        this.selectedUser = event.item.firstName + ' ' + event.item.lastName;
        this.fnChangeNotifications(this.filterObj);
    }

    fnChangeNotifications(filterObj: any) {
        this.filterObj.page = 1;
        this.fnGetNotifications(filterObj);
    }

    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetNotifications(this.filterObj);
    }

    fnClearFilters() {
        this.labelSearch = '';
        this.selectedUser = '';
        this.filterObj = {
            sortBy: 'createdAt',
            orderBy: 'desc',
            page: 1,
            limit: 10,
            startDate: this.getDefaultDateRange().startDate,
            endDate: this.getDefaultDateRange().endDate,
        };
        this.fnClearDate();
    }

    fnExportCSV() {
        this.isExporting = true;
        this.notificationService.fnGetNotifications({ page: 1, limit: 10000 })
            .then((res: any) => {
                const notifications = res.data;
                const csvArr = [];
                _.forEach(notifications, element => {
                    if (element.userId && element.userId.firstName) {
                        element.user = element.userId.lastName
                            ? element.userId.firstName + ' ' + element.userId.lastName : element.userId.firstName;
                    } else {
                        element.user = '';
                    }

                    csvArr.push(element);
                });
                const headers = ['createdAt', 'user', 'packageId.label', 'subject', 'sendGridStatus', 'twilioStatus'];
                this.exportService.fnDownloadCSV('Notifications', csvArr, headers);
                this.isExporting = false;
            });
    }

    async toggleNotificationUpdates(isVisible: boolean, notificationObj?: any) {
        if (isVisible) {
            if (notificationObj && notificationObj._id) {
                try {
                    this.notificationDetail  = await this.notificationService.getNotificationStatusDetail(notificationObj._id);
                    this.notificationDetail.toEmail = notificationObj.toEmail;
                    this.notificationDetail.toNumber = notificationObj.toNumber;

                    if (this.notificationDetail['statusUpdates'].length === 0) {
                        this.toastr.fnWarning('No status updates information found.');
                    } else {
                        document.getElementById('notificationUpdates').style.width = '30%';
                        document.getElementById('overlay').style.display = 'block';
                    }
                }catch (err) {
                    this.toastr.fnError(err.message);
                }
            }
        } else {
            document.getElementById('notificationUpdates').style.width = '0';
            document.getElementById('overlay').style.display = 'none';
        }
    }
}
