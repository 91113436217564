<form #userForm="ngForm"
      (ngSubmit)="userForm.valid && fnSave(userObj)" novalidate>
    <div class="modal-header">
        <button type="button" class="close" (click)="fnCancel()">
            <span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Add User</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-md-6">
                    <fieldset class="fieldset">
                        <legend class="fieldset-legend">Basic</legend>

                        <div class="form-group" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN"
                             [ngClass]="{'has-error': (userForm.submitted || company.touched) && !company.valid}">
                            <label for="company{{componentId}}">Select company<span class="text-danger">*</span></label>
                            <select id="company{{componentId}}" class="form-control" name="company"
                                    [(ngModel)]="userObj.companyId"
                                    #company="ngModel" required>
                                <option [ngValue]="item._id" *ngFor="let item of companiesArr">
                                    {{item.name}}
                                </option>
                            </select>
                            <div *ngIf="(userForm.submitted || company.touched) && !company.valid">
                                <small class="text-danger" *ngIf="company.errors['required']">
                                    Company is required.</small>
                            </div>
                        </div>
                        <div class="form-group"
                             [ngClass]="{'has-error': (userForm.submitted || firstName.touched) && !firstName.valid}">
                            <label for="firstName">First Name<span class="text-danger">*</span></label>
                            <input id="firstName" type="text" class="form-control" name="firstName"
                                   placeholder="Enter a first name"
                                   [(ngModel)]="userObj.firstName" #firstName="ngModel" required/>
                            <div *ngIf="(userForm.submitted || firstName.touched) && !firstName.valid">
                                <small class="text-danger" *ngIf="firstName.errors['required']">
                                    First name is required.</small>
                            </div>
                        </div>
                        <div class="form-group"
                             [ngClass]="{'has-error': (userForm.submitted || lastName.touched) && !lastName.valid}">
                            <label for="lastName">Last Name<span class="text-danger">*</span></label>
                            <input id="lastName" type="text" class="form-control" name="lastName"
                                   placeholder="Enter a last name"
                                   [(ngModel)]="userObj.lastName" #lastName="ngModel" required/>
                            <div *ngIf="(userForm.submitted || lastName.touched) && !lastName.valid">
                                <small class="text-danger" *ngIf="lastName.errors['required']">
                                    Last name is required.</small>
                            </div>
                        </div>
                        <div class="form-group"
                             [ngClass]="{'has-error': (userForm.submitted || email.touched) && !email.valid}">
                            <label for="email">Email<span class="text-danger">*</span></label>
                            <input id="email" type="email" class="form-control" name="email"
                                   placeholder="Enter a email"
                                   [(ngModel)]="userObj.email" #email="ngModel"
                                   [pattern]="emailRegEx" required/>
                            <div *ngIf="(userForm.submitted || email.touched) && !email.valid">
                                <small class="text-danger" *ngIf="email.errors['required']">
                                    Email is required.</small>
                                <small class="text-danger" *ngIf="email.errors['pattern']">
                                    Email is invalid.</small>
                            </div>
                        </div>
                        <div class="form-group"
                             [ngClass]="{'has-error': (userForm.submitted || phone.touched) && !phone.valid}">
                            <label for="phone">Phone<span class="text-danger">*</span></label>
                            <input id="phone" type="phone" class="form-control" name="phone"
                                   placeholder="Enter a phone" [(ngModel)]="userObj.phone" #phone="ngModel"
                                   required/>
                            <div *ngIf="(userForm.submitted || phone.touched) && !phone.valid">
                                <small class="text-danger" *ngIf="phone.errors['required']">
                                    Phone is required.</small>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-6">
                    <fieldset class="fieldset">
                        <legend class="fieldset-legend">Location</legend>

                        <div class="form-group"
                             [ngClass]="{'has-error': (userForm.submitted || location.touched) && !location.valid}">
                            <label for="location">Search Location<span class="text-danger">*</span></label>
                            <input type="text" id="location" class="form-control" name="location"
                                   [(ngModel)]="userObj.locationId.formattedAddress"
                                   placeholder="Enter locker address" #location="ngModel" required/>
                            <div *ngIf="(userForm.submitted || location.touched) && !location.valid">
                                <small class="text-danger" *ngIf="location.errors['required']">
                                    Location is required.</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="street">Street</label>
                            <div id="street" class="row">
                                <div class="col-xs-4 p-r-0">
                                    <input class="form-control" name="streetNumber"
                                           [(ngModel)]="userObj.locationId.streetNumber"
                                           placeholder="Street no."
                                           [disabled]="true" [readonly]="true"/>
                                </div>
                                <div class="col-xs-8 p-l-5">
                                    <input class="form-control" name="route"
                                           [(ngModel)]="userObj.locationId.route"
                                           placeholder="Street route."
                                           [disabled]="true" [readonly]="true"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <label for="city">City</label>
                                <input id="city" type="text" class="form-control" name="city"
                                       [(ngModel)]="userObj.locationId.city"
                                       placeholder="City"
                                       [disabled]="true" [readonly]="true"/>
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="state">State</label>
                                <input id="state" type="text" class="form-control" name="state"
                                       [(ngModel)]="userObj.locationId.state"
                                       placeholder="State"
                                       [disabled]="true" [readonly]="true"/>

                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <label for="country">Country</label>
                                <input id="country" type="text" class="form-control" name="country"
                                       [(ngModel)]="userObj.locationId.country"
                                       placeholder="Country"
                                       [disabled]="true" [readonly]="true"/>
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="postalCode">Postal Code</label>
                                <input id="postalCode" type="text" class="form-control" name="postalCode"
                                       [(ngModel)]="userObj.locationId.postalCode"
                                       placeholder="Postal code"
                                       [disabled]="true" [readonly]="true"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="latitudeLongitude">Latitude & Longitude</label>
                            <div id="latitudeLongitude" class="row">
                                <div class="col-xs-6">
                                    <input class="form-control" name="latitude"
                                           [(ngModel)]="userObj.locationId.latitude"
                                           placeholder="Latitude."
                                           [disabled]="true" [readonly]="true"/>
                                </div>
                                <div class="col-xs-6">
                                    <input class="form-control" name="=longitude"
                                           [(ngModel)]="userObj.locationId.longitude"
                                           placeholder="Longitude."
                                           [disabled]="true" [readonly]="true"/>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-12">
                    <fieldset class="fieldset">
                        <legend class="fieldset-legend">Password</legend>

                        <div class="row">
                            <div class="form-group col-sm-6"
                                 [ngClass]="{'has-error': (userForm.submitted || password.touched) && !password.valid}">
                                <label for="password">Password<span class="text-danger">*</span></label>
                                <input id="password" type="password" class="form-control" name="password"
                                       placeholder="Enter a password"
                                       [(ngModel)]="userObj.password" #password="ngModel" required/>
                                <div *ngIf="(userForm.submitted || password.touched) && !password.valid">
                                    <small class="text-danger" *ngIf="password.errors['required']">
                                        Password is required.</small>
                                </div>
                            </div>
                            <div class="form-group col-sm-6"
                                 [ngClass]="{'has-error': (userForm.submitted || cPassword.touched) && !cPassword.valid}">
                                <label for="cPassword">Confirm Password<span
                                    class="text-danger">*</span></label>
                                <input id="cPassword" type="password" class="form-control" name="cPassword"
                                       placeholder="Enter a confirm password"
                                       [(ngModel)]="confirmPassword" #cPassword="ngModel" required/>
                                <div *ngIf="(userForm.submitted || cPassword.touched) && !cPassword.valid">
                                    <small class="text-danger" *ngIf="cPassword.errors['required']">
                                        Confirm Password is required.</small>
                                </div>
                                <div *ngIf="(userForm.submitted || cPassword.touched) && cPassword.valid">
                                    <small class="text-danger" *ngIf="userObj.password !== confirmPassword">
                                        Password doesn't match.</small>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="fnCancel()">Close</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
