import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {

    private PP_DB_NAME = 'PP_DB';
    private PP_DB: any;

    constructor() {
        this.PP_DB = {};
    }

    /**
     * Store data in local storage.
     * @param {string} key
     * @param {object} data
     * */
    fnStoreData(key: string, data: object) {
        this.PP_DB = localStorage.getItem(this.PP_DB_NAME) ? JSON.parse(atob(localStorage.getItem(this.PP_DB_NAME))) : {};
        this.PP_DB[key] = data;
        localStorage.setItem(this.PP_DB_NAME, btoa(JSON.stringify(this.PP_DB)));
    }

    /**
     * Get JSON data
     * @param {string} key
     * */
    fnGetData(key: string) {
        if (localStorage.getItem(this.PP_DB_NAME)) {
            const PP_DB = JSON.parse(atob(localStorage.getItem(this.PP_DB_NAME)));
            return PP_DB[key];
        } else {
            return {};
        }
    }

    /**
     * Remove data from JSON
     * @param {string} key
     * */
    fnRemove(key: string) {
        this.PP_DB = localStorage.getItem(this.PP_DB_NAME) ? JSON.parse(atob(localStorage.getItem(this.PP_DB_NAME))) : {};
        delete this.PP_DB[key];
        localStorage.setItem(this.PP_DB_NAME, btoa(JSON.stringify(this.PP_DB)));
    }

    /**
     * Remove all the data from local storage.
     * */
    removeAllData() {
        localStorage.removeItem(this.PP_DB_NAME);
    }
}
