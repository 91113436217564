import {Component, OnInit , ChangeDetectorRef , AfterViewChecked} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import {Constant} from '../../../common/constant';
import {Company} from '../../companies/company';
import {Locker} from '../../lockers/locker';
import {User} from '../user';

import {AuthService} from '../../../common/auth.service';
import {CompaniesService} from '../../companies/companies.service';
import {PaymentPlansService} from '../../payment-plans/payment-plans.service';
import {LockersService} from '../../lockers/lockers.service';
import {ToastrService} from '../../../common/toastr.service';
import {UsersService} from '../users.service';
import {RpConfirmModalComponent} from './rp-confirm-modal/rp-confirm-modal.component';
import {USER_STATUS_ENUM} from '../../../common/common_enums';

@Component({
    selector: 'pp-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit , AfterViewChecked {

    public isDataLoaded;
    public currentUser: User;
    public userObj: User;
    public userId: string;
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];
    public emailRegEx: any = Constant.EMAIL_REG_EX;
    public blankSpaceRegEx = Constant.BLANK_SPACE_REG_EX;
    readonly CONSTANT = Constant;
    public USER_STATUS = USER_STATUS_ENUM;
    constructor(
        private _bsModalService: BsModalService,
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private companiesService: CompaniesService,
        private paymentplansService: PaymentPlansService,
        private lockersService: LockersService,
        private router: Router,
        private toastr: ToastrService,
        private usersService: UsersService,
        private cdr: ChangeDetectorRef,
        private location: Location
    ) {
        this.isDataLoaded = false;
        this.userObj = {
            locationId: {formattedAddress: '', placeId:''},
            companyId: '',
            role: '',
            preferredLockerId: '',
            language: '',
            isHeightRestricted: false
        };
        this.currentUser = this.auth.fnGetCurrentUser();
        this.activatedRoute.params.subscribe(params => {
            this.userId = params['id'];
        });
    }

    ngOnInit() {
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.fnGetCompanies();
        } else {
            if (this.userId !== 'add') {
                this.isDataLoaded = true;
                this.fnGetUser(this.userId);
            }
        }
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
    /**
     * Get user
     * @param {string} id
     * */
    fnGetUser(id: string) {
        this.usersService.fnGetUser(id)
            .then((userObj: User) => {
                this.isDataLoaded = false;
                this.userObj = userObj;
                if (!this.userObj.locationId) {
                    this.userObj.locationId = {formattedAddress: '', placeId: ''};
                } else {
                    userObj.locationId.floor = userObj.rbcFloor;
                    userObj.locationId.department = userObj.department;
                }
                if (!this.userObj.preferredLockerId) {
                    this.userObj.preferredLockerId = '';
                }
                this.fnGetLockers({companyId: this.userObj.companyId});
            });
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.isDataLoaded = true;
        this.companiesService.fnGetCompanies({limitData: true})
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
                if (this.userId !== 'add') {
                    this.fnGetUser(this.userId);
                } else {
                    this.isDataLoaded = false;
                }
            });
    }

    /**
     * Get lockers
     * */
    async fnGetLockers(params?: object) {
        if (!params['companyId']) {
            this.lockersArr = [{_id: '', name: 'Select Locker'}];
            return;
        }
        this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber(params['companyId']);
        this.lockersArr.unshift({_id: '', name: 'Select Locker'});
    }

    navigateToUsers() {
        this.router.navigateByUrl('/users');
    }

    /**
     * Create/Update user
     * @param {object} userObj
     * */
    fnSaveUser(userObj: User) {
        userObj.rbcFloor = userObj.locationId ? userObj.locationId.floor : '';
        userObj.department = userObj.locationId ? userObj.locationId.department : '';
        userObj.status = this.userObj.status;
        if (userObj._id) {
            if (this.userObj.preferredLockerId === '') {
                this.userObj.preferredLockerId = null;
            }
            this.usersService.fnUpdateUser(userObj)
                .then(() => {
                    this.toastr.fnSuccess('User updated successfully.');
                    this.navigateToUsers();
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        } else {
            if (!userObj.companyId) {
                userObj.companyId = this.currentUser.companyId;
            }
            if (this.currentUser.role === Constant.ROLE_CARRIER_ADMIN) {
                userObj.role = Constant.ROLE_CARRIER_USER;
            }
            this.usersService.fnCreateUser(userObj)
                .then(() => {
                    this.toastr.fnSuccess('User created successfully.');
                    this.navigateToUsers();
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        }
    }

    fnOpenRPConfirmModal() {
        const modal = this._bsModalService.show(RpConfirmModalComponent, {'class': 'modal-sm'});
        (<RpConfirmModalComponent>modal.content).showConfirmationModal();

        (<RpConfirmModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.usersService.resetPassword(this.userId)
                    .then((res: any) => {
                        this.toastr.fnSuccess(res.message);
                    });
            }
        });
    }

    setUserStatus(userStatus: USER_STATUS_ENUM) {
        this.userObj.status = userStatus;
    }
}
