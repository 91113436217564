<div id="wrapper">
    <!-- Navigation -->
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header pull-left">
            <a class="navbar-brand p-0" href="javascript:void(0);">
                <img class="navbar-logo" src="assets/images/pp_logo.png" alt="Parcel Port"/>
            </a>
        </div>
        <!-- /.navbar-header -->
    </nav>
    <!-- /.navbar-static-side -->
    <div id="page-wrapper">
        <div class="row">
            <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
                <div class="panel panel-default whiteframe-10dp m-t-50">
                    <div class="panel-heading">
                        <div class="panel-title text-center"><strong>Set Your Password</strong></div>
                    </div>
                    <div class="panel-body">
                        <form #setPasswordForm="ngForm" role="form" (ngSubmit)="setPasswordForm.valid && setPassword()">
                            <fieldset>
                                <div class="form-group"
                                     [ngClass]="{'has-error': (setPasswordForm.submitted || password.touched) && !password.valid}">
                                    <input class="form-control" placeholder="New Password" name="password"
                                           type="password"
                                           [(ngModel)]="newPassword" #password="ngModel" minlength="8" required/>
                                    <div *ngIf="(setPasswordForm.submitted || password.touched) && !password.valid">
                                        <small class="text-danger" *ngIf="password.errors['required']">
                                            Password is required.
                                        </small>
                                    </div>
                                    <div *ngIf="(setPasswordForm.submitted || password.touched) && !password.valid">
                                        <small class="text-danger" *ngIf="password.errors['minlength']">
                                            Password length should be at least 8 characters long.
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group"
                                     [ngClass]="{'has-error': (setPasswordForm.submitted || cpassword.touched) && !cpassword.valid}">
                                    <input class="form-control" placeholder="Confirm Password" name="cpassword"
                                           type="password"
                                           [(ngModel)]="confirmPassword" #cpassword="ngModel" required/>
                                    <div *ngIf="(setPasswordForm.submitted || cpassword.touched) && !cpassword.valid">
                                        <small class="text-danger" *ngIf="cpassword.errors['required']">
                                            Confirm Password is required.
                                        </small>
                                    </div>
                                    <div *ngIf="(setPasswordForm.submitted) && !isMatched">
                                        <small class="text-danger">
                                            Password doesn't match.
                                        </small>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-block btn-primary">Set My Password</button>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
