import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../common/auth.service';
import { CompaniesService } from '../../companies/companies.service';
import { LockersService } from '../lockers.service';
import { ToastrService } from '../../../common/toastr.service';

import { Company } from '../../companies/company';
import {Locker, SlotDetails, LOCKER_CODES_TYPE} from '../locker';
import { User } from '../../users/user';

import { Constant } from '../../../common/constant';
import {LANGUAGE, RETURN_PROCESS_TYPE} from '../../../common/enums';
import * as _ from 'lodash';
import {CarrierConfig} from '../carrier-config';
import {LockerCarrier} from '../locker-carrier';
import {UsersService} from '../../users/users.service';
import {USER_ROLE} from '../../../../../server/model/user';
import {TIME_ZONES} from '../../../common/common_enums';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";


@Component({
    selector: 'pp-locker',
    templateUrl: './locker.component.html',
    styleUrls: ['./locker.component.css']
})

export class LockerComponent implements OnInit {

    public isDataLoaded;
    public isReadOnly;
    public lockerObj: Locker;
    public lockerCarriers: Array<any>;
    public returnTypes: Array<any> = [];
    public languages: Array<any> = [];
    public returnType: any = {};
    public language: any = {};
    public lockerId: string;
    public currentUser: User;
    public companiesArr: Array<Company> = [{ _id: '', name: 'Select Company' }];
    readonly CONSTANT = Constant;
    public adminEmails = [];
    public alphaNumRegEx: string = '[a-zA-Z0-9,; ]*';
    private totalLockerbanksArray = Constant.TOTAL_LOCKER_BANKS_FOR_AUTOMATIC_CREATE;
    public isDropOffEnabled = 'true';
    public isOutboundEnabled = 'false';
    selectedAccessCodeIndex: number = -1;
    public preservedCode: string = '';
    public totalLockerBank;
    public selectedCarrierObj;
    public banksArray: Array<Array<SlotDetails>> = [
        [
            {
                slotType: 'SMALL',
                slotNumber: '01',
                isUpperSlot: false
            }
        ]
    ];
    public lockerProfile: string[] = [];
    TIME_ZONES: string[] = [];
    toggleReturnPickup: boolean = false;
    toggleProductReturnPickup: boolean = false;
    public preservedValues: any = {
        lockerObj: "",
        adminEmail: "",
        isOutboundEnabled: "",
        isDropOffEnabled: ""
    };
    public setCodesModelRef: BsModalRef;
    public setCodesDescription: string;
    preserveCarrierConfig: any = {};

    constructor(
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private companiesService: CompaniesService,
        private lockersService: LockersService,
        private router: Router,
        private toastr: ToastrService,
        private usersService: UsersService,
        private _bsModalService: BsModalService
    ) {
        this.isDataLoaded = true;
        this.isReadOnly = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.getLanguages();
        this.lockerObj = {
            lockerType: 'Depot',
            isMultiBuilding: 'True',
            isAutoUpdate: 'False',
            cabinets: {},
            companyId: '',
            locationId: {formattedAddress: '', placeId: ''},
            defaultLanguage: this.language['EN']
        };
        this.totalLockerBank = 0;
        this.activatedRoute.params.subscribe(params => {
            this.lockerId = params['id'];
        });
        for (const zone in TIME_ZONES) {
            this.TIME_ZONES.push(zone);
        }
    }

    async ngOnInit() {
        this.getReturnTypes();
        if (this.currentUser.role.endsWith(Constant.ROLE_ADMIN)) {
            this.isDataLoaded = true;
            if (this.lockerId !== 'add') {
                this.lockerCarriers = await this.getCarrierConfigs();
                if (this.lockerCarriers && this.lockerCarriers.length) {
                    this.setIsEditableToLocalCarriers();
                }
            }
            this.fnGetCompanies();
        } else {
            if (this.lockerId !== 'add') {
                this.isDataLoaded = true;
                this.fnGetLocker(this.lockerId);
            }
        }
    }

    generateLockerBanks(totalLockerBanks: number): void {
        const existingLockers = this.banksArray.length-1;
        if (existingLockers < totalLockerBanks) {
            for (let index = existingLockers; index < totalLockerBanks; index++) {
                this.banksArray.push([{
                    slotType: 'SMALL',
                    slotNumber: '01',
                    isUpperSlot: false
                }]);
            }
        } else {
            for (let index = existingLockers; index > totalLockerBanks; index--){
                this.banksArray.pop();
            }
        }
    }

    getCharCode(index: number): string {
        return String.fromCharCode(64 + index);
    }

    /**
     * Set isEditable for all locker carriers
     */
    setIsEditableToLocalCarriers() {
        this.lockerCarriers.forEach(carrier => {
           carrier.isEditable = false;
        });
    }

    /**
     * Format valid postal codes on blur
     * */
    formatValidPostalCodes() {
        if (this.lockerObj.validPostalCodes) {
            const postalCodes = this.lockerObj.validPostalCodes.toUpperCase().split((/[,;]+/));
            this.lockerObj.validPostalCodes = postalCodes.toString()
                .replace(/ /g, '')
                .replace(/,/g, ', ');
        }
    }

    /**
     * Get Return types from Enum
     * */
    getReturnTypes() {
        for (const j in RETURN_PROCESS_TYPE) {
            this.returnTypes.push({id: j, name: <any>RETURN_PROCESS_TYPE[j]});
            this.returnType[j] = j;
        }
    }

    /**
     * Get Languages from Enum
     * */
    getLanguages() {
        for (const j in LANGUAGE) {
            this.languages.push({id: j, name: <any>LANGUAGE[j]});
            this.language[j] = j;
        }
    }

    /**
     * Set Locker Carrier Config
     * */
    setLockerCarrierConfig() {
        _.forEach(this.lockerObj.carriers, carrier => {
            for (let i = 0; i <= this.lockerCarriers.length; i++) {
                if (this.lockerCarriers[i] && carrier.carrierCode === this.lockerCarriers[i].carrierCode) {
                    this.lockerCarriers[i].returnProcessType = carrier.returnProcessType;
                    this.lockerCarriers[i].enabled = carrier.enabled;
                    if (carrier.returnPickupCode) {
                        this.lockerCarriers[i].returnPickupCode = carrier.returnPickupCode;
                    }
                    break;
                }
            }
        });
    }

    /**
     * Check return process type and remove retrunPickupCode if return type is not courier
     * */
    checkReturnProcessType(carrier) {
        if (carrier.returnPickupCode && carrier.returnProcessType !== this.returnType.COURIER) {
            delete carrier.returnPickupCode;
        }
    }

    /**
     * Change return process type
     * */
    changeReturnProcessType(carrier, type) {
        carrier.returnProcessType = type.toString();
        this.checkReturnProcessType(carrier);
    }

    /**
     * Enable editing locker carrier config
     * */
    enableLockerCarrierConfigEdit(lockerObj: any , carrier: any) {
        if (this.toggleReturnPickup) {
            this.cancelReturnPickupCode(lockerObj);
        }
        if (this.toggleProductReturnPickup) {
            this.cancelProductReturnPickupCode(lockerObj);
        }
        if (this.selectedAccessCodeIndex !== -1) {
            this.cancelAccessCode(lockerObj);
        }
        if (this.selectedCarrierObj) {
            this.selectedCarrierObj.returnProcessType = this.preserveCarrierConfig.returnProcessType;
            this.selectedCarrierObj.returnPickupCode = this.preserveCarrierConfig.returnPickupCode;
            this.selectedCarrierObj.enabled = this.preserveCarrierConfig.enabled;
        }
        this.lockerCarriers.forEach(car => {
           car.isEditable = car.carrierCode === carrier.carrierCode;
           return car;
        });
        this.selectedCarrierObj = carrier;
        this.preserveCarrierConfig = {
            returnProcessType: carrier.returnProcessType,
            enabled: carrier.enabled,
            returnPickupCode: carrier.returnPickupCode ? carrier.returnPickupCode : null
        };
    }

    cancelCarrierReturnPickUpCode() {
        this.selectedCarrierObj.returnPickupCode = this.preserveCarrierConfig.returnPickupCode;
        this.selectedCarrierObj.returnProcessType = this.preserveCarrierConfig.returnProcessType;
        this.selectedCarrierObj.enabled = this.preserveCarrierConfig.enabled;
        this.selectedCarrierObj.isEditable = !this.selectedCarrierObj.isEditable;
        this.preserveCarrierConfig = null;
        this.selectedCarrierObj = null;
    }
    /**
     * Save Locker Carrier Config
     * @param {object} carrier
     * */
    async saveLockerCarrierConfig(template: any, carrier: any) {
        const lockerCarrier: LockerCarrier = {
            carrierCode: carrier.carrierCode,
            returnProcessType: carrier.returnProcessType,
            enabled: carrier.enabled
        };
        if (!lockerCarrier.enabled) {
            lockerCarrier.returnProcessType = this.returnType.NO.toString();
            lockerCarrier.returnPickupCode = null;
        }
        if (lockerCarrier.returnProcessType.toString() === this.returnType.COURIER) {
            if (!carrier.returnPickupCode) {
                this.toastr.fnError('Return pickup code is required for carrier');
                return;
            }
            lockerCarrier.returnPickupCode = carrier.returnPickupCode;
        }
        if (lockerCarrier.returnProcessType.toString() === this.returnType.FRONT_DESK) {
            let message = '';
            const accessCode = !this.lockerObj.accessCodes[0] && !this.lockerObj.accessCodes[1] && !this.lockerObj.accessCodes[2];
            const pickupCode = !this.lockerObj.returnPickupCode;
            if (accessCode || pickupCode) {
                message = accessCode ? 'Front Desk Access Code' : '';
                message += pickupCode ? 'Expired Shipment Return Pickup Code' : '';
                const msg = message.split('Code');
                const result = msg.length === 3 ? msg[0] + 'Code & ' + msg[1] + 'Code' : message;
                this.setCodesDescription = `Please set the ${result} and then try again`;
                this.setCodesModelRef = this._bsModalService.show(template, {'class': 'modal-sm'});
                this.cancelCarrierReturnPickUpCode();
                return;
            }
        }
        try {
            if (this.preserveCarrierConfig.enabled !== lockerCarrier.enabled || this.preserveCarrierConfig.returnProcessType !== lockerCarrier.returnProcessType || (lockerCarrier.returnPickupCode && this.preserveCarrierConfig.returnPickupCode !== lockerCarrier.returnPickupCode)) {
                const res: any = await this.lockersService.saveLockerCarrierConfig(this.lockerId, lockerCarrier);
                if (res) {
                    this.selectedCarrierObj = null;
                    if (!res.accessCodes) {
                        res.accessCodes = [];
                    }

                    if (res.accessCodes.length < this.CONSTANT.MAX_ACCESS_CODES_PER_LOCKER) {
                        for (let startIndex = res.accessCodes.length; startIndex < this.CONSTANT.MAX_ACCESS_CODES_PER_LOCKER; startIndex++) {
                            res.accessCodes.push('');
                        }
                    }

                    if (!res.returnPickupCode) {
                        res.returnPickupCode = '';
                    }
                    carrier.isEditable = false;
                    this.lockerObj = res;
                }
            }
            carrier.isEditable = false;
            this.toastr.fnSuccess('Carrier has been successfully configured for ' + lockerCarrier.carrierCode);
        } catch (err) {
            this.toastr.fnError(err.message);
        }
    }

    /**
     * Get Carrier Configs
     * */
    async getCarrierConfigs(): Promise<Array<CarrierConfig>> {
        const carriers = await this.lockersService.getCarrierConfigs();
        _.forEach(carriers, (carrier: CarrierConfig)  => {
           return carrier.returnProcessType = RETURN_PROCESS_TYPE.NO;
        });
        return carriers;
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.companiesService.fnGetCompanies({limitData: true})
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({ _id: '', name: 'Select Company' });
                if (this.lockerId !== 'add') {
                    this.fnGetLocker(this.lockerId);
                } else {
                    this.isDataLoaded = false;
                }
            });
    }

    /**
     * Get locker
     * @param {string} id
     * */
    async fnGetLocker(id: string) {
        const lockerObj: Locker = await this.lockersService.fnGetLocker(id);
        if (lockerObj) {
            if (!lockerObj.accessCodes) {
                lockerObj.accessCodes = [];
            }

            if (lockerObj.accessCodes.length < this.CONSTANT.MAX_ACCESS_CODES_PER_LOCKER) {
                for (let startIndex = lockerObj.accessCodes.length; startIndex < this.CONSTANT.MAX_ACCESS_CODES_PER_LOCKER; startIndex++) {
                    lockerObj.accessCodes.push('');
                }
            }

            if (!lockerObj.returnPickupCode) {
                lockerObj.returnPickupCode = '';
            }

            if (!lockerObj.isDropOffEnabled) {
                this.isDropOffEnabled = 'false';
            }

            if (lockerObj.isOutboundEnabled) {
                this.isOutboundEnabled = 'true';
            }

            this.isDataLoaded = false;
            this.lockerObj = lockerObj;
            this.lockerObj.defaultLanguage = lockerObj.defaultLanguage ? lockerObj.defaultLanguage : LANGUAGE.EN;
            this.isReadOnly = true;
            this.lockerProfile = typeof this.lockerObj.lockerProfile === 'object' ? Object.keys(this.lockerObj.lockerProfile) : [];
            await this.getAdminEmails();
            this.preservedValues['lockerObj'] = {...this.lockerObj};
            this.preservedValues['adminEmails'] = this.adminEmails;
            this.preservedValues['isDropOffEnabled'] = this.lockerObj.isDropOffEnabled ? 'true' : 'false';
            this.preservedValues['isOutboundEnabled'] = this.lockerObj.isOutboundEnabled ? 'true' : 'false';
            await this.setLockerCarrierConfig();
        }
    }

    /**
     * Create/Update locker
     * @param {object} lockerObj
     * */
    fnSaveLocker(lockerObj: Locker) {
        lockerObj.isDropOffEnabled = this.isDropOffEnabled === 'true' ? true : false;
        lockerObj.isOutboundEnabled = this.isOutboundEnabled === 'true' ? true : false;
        if (this.lockerObj.productReturnPickupCode && !this.lockerObj.isOutboundEnabled) {
            this.lockerObj.productReturnPickupCode = "";
        }
        if (lockerObj._id) {
            this.lockersService.fnUpdateLocker(lockerObj)
                .then(() => {
                    this.toastr.fnSuccess('Locker updated successfully.');
                    this.router.navigateByUrl('/lockers');
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        } else {
            for (const index in this.banksArray) {
                this.lockerObj.cabinets[this.getCharCode(Number(index)+1)] = this.banksArray[index];
            }
            if (!lockerObj.companyId) {
                lockerObj.companyId = this.currentUser.companyId;
            }
            this.lockersService.fnCreateLocker(lockerObj)
                .then(() => {
                    this.toastr.fnSuccess('Locker created successfully.');
                    this.router.navigateByUrl('/lockers');
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        }
    }

    /**
     * Do not allow returnPickUpCode to be empty
     * @param {object} carrier
     * */
    doNotAllowEmptyReturnCode(carrier): boolean {
        if (carrier && carrier.returnProcessType === this.returnType.COURIER && carrier.returnPickupCode) {
            return !(/(^$)|(^[0-9]{6}$)/.test(carrier.returnPickupCode));
        }
        return false;
    }

    async getAdminEmails() {
        const filterObj: any = {companyId: this.lockerObj.companyId, role: USER_ROLE.ADMIN + ', ' + USER_ROLE.COMPANY_ADMIN};
        const users = <User[]>await this.usersService.fnGetUsers(filterObj);
        this.adminEmails = users.map((o) => {
                return o.email;
        });
    }

    toggleAccessCodeSaveBtn(lockerObj: Locker, index: number) {
        if (this.toggleReturnPickup){
            this.cancelReturnPickupCode(lockerObj)
        }
        if (this.toggleProductReturnPickup) {
            this.cancelProductReturnPickupCode(lockerObj);
        }
        if(this.selectedAccessCodeIndex !== -1){
            this.cancelAccessCode(lockerObj);
        }
        if (this.selectedCarrierObj) {
            for(const carrier of this.lockerCarriers){
                if (carrier.isEditable){
                    this.cancelCarrierReturnPickUpCode();
                    break;
                }
            }
        }
        this.preservedCode = lockerObj.accessCodes[index];
        this.selectedAccessCodeIndex = index;
    }

    toggleSaveButtonReturnCode(lockerObj: Locker) {
        if(this.selectedAccessCodeIndex !== -1){
            this.cancelAccessCode(lockerObj);
        }
        if (this.toggleProductReturnPickup) {
            this.cancelProductReturnPickupCode(lockerObj);
        }
        if (this.selectedCarrierObj) {
            for (const carrier of this.lockerCarriers) {
                if (carrier.isEditable) {
                    this.cancelCarrierReturnPickUpCode();
                    break;
                }
            }
        }
        this.preservedCode = lockerObj.returnPickupCode;
        this.toggleReturnPickup = !this.toggleReturnPickup;
    }

    toggleSaveButtonProductReturnPickupCode = (lockerObj: Locker) => {
        if (this.selectedAccessCodeIndex !== -1) {
            this.cancelAccessCode(lockerObj);
        }
        if (this.toggleReturnPickup) {
            this.cancelReturnPickupCode(lockerObj);
        }
        if (this.selectedCarrierObj) {
            for(const carrier of this.lockerCarriers){
                if (carrier.isEditable){
                    this.cancelCarrierReturnPickUpCode();
                    break;
                }
            }
        }
        this.preservedCode = lockerObj.productReturnPickupCode;
        this.toggleProductReturnPickup = !this.toggleProductReturnPickup;
    }

    async saveAccessCodes(lockerObj: Locker, index: number) {
        try {
            const accessCodes = this.lockerObj.accessCodes.filter(code => {
                return code && code.length > 0;
            });
            const carrierReturnProcess = this.lockerCarriers.find(carrier => {
                return carrier.returnProcessType === 'FRONT_DESK';
            });
            if (carrierReturnProcess && accessCodes.length === 0) {
               return this.toastr.fnError(`Front Desk Access Code is required as return process is set to FrontDesk for a carrier.`);
            }
            if (lockerObj.accessCodes[index] !== this.preservedCode) {
                const response = await this.lockersService.setCodes(this.lockerObj._id, LOCKER_CODES_TYPE.ACCESS_CODES, accessCodes);
                this.toastr.fnSuccess(response['message']);
            }
            this.selectedAccessCodeIndex = -1;
        } catch (err) {
            this.toastr.fnError(err.error.message);
        }
    }

    cancelAccessCode(lockerObj: Locker) {
        lockerObj.accessCodes[this.selectedAccessCodeIndex] = this.preservedCode;
        this.selectedAccessCodeIndex = -1;
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    async saveReturnPickupCode() {
        try {
            const returnPickupCode = this.lockerObj.returnPickupCode;
            const carrierReturnProcess = this.lockerCarriers.find(carrier => {
                return carrier.returnProcessType === 'FRONT_DESK';
            });
            if (carrierReturnProcess && !returnPickupCode) {
                return this.toastr.fnError(`Front Desk Expired Shipment Pickup Code is required as return process is set to FrontDesk for a carrier.`);
            }
            if (returnPickupCode !== this.preservedCode) {
                const response = await this.lockersService.setCodes(this.lockerObj._id, LOCKER_CODES_TYPE.RETURN_PICKUP_CODE, returnPickupCode);
                this.toastr.fnSuccess(response['message']);
            }
            this.toggleReturnPickup = false;
        } catch (err) {
            this.toastr.fnError(err.error.message);
        }
    }

    async saveProductReturnPickupCode() {
        try {
            const productReturnPickupCode = this.lockerObj.productReturnPickupCode;
            if (productReturnPickupCode !== this.preservedCode) {
                const response = await this.lockersService.setCodes(this.lockerObj._id, LOCKER_CODES_TYPE.PRODUCT_RETURN_PICKUP_CODE, productReturnPickupCode);
                this.toastr.fnSuccess(response['message']);
            }
            this.toggleProductReturnPickup = false;
        } catch (err) {
            this.toastr.fnError(err.error.message);
        }
    }

    cancelReturnPickupCode(lockerObj) {
        lockerObj.returnPickupCode = this.preservedCode;
        this.toggleReturnPickup = !this.toggleReturnPickup;
    }

    cancelProductReturnPickupCode(lockerObj) {
        lockerObj.productReturnPickupCode = this.preservedCode;
        this.toggleProductReturnPickup = !this.toggleProductReturnPickup;
    }

    resetValues = () => {
        this.isReadOnly = !this.isReadOnly;
        if (this.isReadOnly) {
            this.lockerObj = { ...this.lockerObj, ...this.preservedValues.lockerObj };
            this.adminEmails = this.preservedValues.adminEmails;
            this.isDropOffEnabled = this.lockerObj.isDropOffEnabled ? 'true' : 'false';
            this.isOutboundEnabled = this.lockerObj.isOutboundEnabled ? 'true' : 'false';
         }
    }

    closeSetCodesModal =() => {
        this.setCodesModelRef.hide();
    }

    formatLabel = (label) => {
        const str = label.split(/(?=[A-Z])/);
        str[0] = str[0].charAt(0).toUpperCase() + str[0].slice(1);
        return str.join(' ');
    }
}
