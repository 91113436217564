import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {ApkService} from '../apk.service';
import {ToastrService} from '../../../common/toastr.service';
import {APK} from '../apk';

@Component({
    selector: 'pp-upload-apk',
    templateUrl: './upload-apk.component.html',
    styleUrls: ['./upload-apk.component.css']
})
export class UploadAPKComponent implements OnInit {

    @ViewChild('apkFile', {static: false}) inputEl: ElementRef;
    public isDataLoaded;
    public apkId: string;
    public apkObj: APK;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private toastr: ToastrService,
                private apkService: ApkService) {
        this.apkObj = {description: '', latest: 'true'};
        this.isDataLoaded = false;
        this.activatedRoute.params.subscribe(params => {
            this.apkId = params['id'];
        });
    }

    ngOnInit() {
        if (this.apkId !== 'upload') {
            this.fnGetAPKInfo(this.apkId);
        }
    }

    /**
     * Get apk Information
     * @param {string} id
     * */
    fnGetAPKInfo(id: string) {
        this.isDataLoaded = true;
        this.apkService.fnGetAPKInfo(id)
            .then((apkObj: any) => {
                this.isDataLoaded = false;
                this.apkObj = apkObj;
            })
            .catch((error) => {
                this.isDataLoaded = false;
                this.toastr.fnError(error.message);
            });
    }

    /**
     * Upload or update APK information
     * @param {object} apkObj
     * */
    fnSaveAPK(apkObj) {
        if (apkObj._id) {
            this.isDataLoaded = true;
            this.apkService.fnUpdateAPK(apkObj)
                .then(() => {
                    this.isDataLoaded = false;
                    this.toastr.fnSuccess('APK information updated successfully.');
                    this.router.navigateByUrl('/manage-apk');
                })
                .catch((error) => {
                    this.isDataLoaded = false;
                    this.toastr.fnError(error.message);
                });
        } else {
            this.fnUploadNewAPK(apkObj);
        }
    }

    /**
     * Upload new apk
     * @param {object} apkObj
     * */
    fnUploadNewAPK(apkObj) {
        const inputEl: HTMLInputElement = this.inputEl.nativeElement;
        apkObj.apkFile = inputEl.files.item(0);
        this.isDataLoaded = true;
        this.apkService.fnUploadNewAPK(apkObj)
            .then(() => {
                this.isDataLoaded = false;
                this.toastr.fnSuccess('APK uploaded successfully.');
                this.router.navigateByUrl('/manage-apk');
            })
            .catch((error) => {
                this.isDataLoaded = false;
                this.toastr.fnError(error.message);
            });
    }
}
