import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {CompaniesService} from './companies.service';
import {Company} from './company';
import {ExportService} from '../../common/export.service';
import {ToastrService} from "../../common/toastr.service";

@Component({
    selector: 'pp-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.css']
})

export class CompaniesComponent implements OnInit {

    public isDataLoaded;
    public isExporting: boolean;
    public filterObj: any;
    companiesArr: Array<Company> = [];

    constructor(private _bsModalService: BsModalService, private companiesService: CompaniesService, private exportService: ExportService, private toastr: ToastrService) {
        this.isDataLoaded = false;
        this.isExporting = false;
        this.filterObj = {sortBy: 'name', orderBy: 'asc'};
    }

    ngOnInit() {
        this.fnGetCompanies(this.filterObj);
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.sortBy = 'name';
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        this.companiesService.fnGetCompanies(this.filterObj)
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
            });
    }

    /**
     * Get list of companies
     * @param {object} params
     * */
    fnGetCompanies(params?: object) {
        this.isDataLoaded = false;
        params['lookup'] = true;
        this.companiesService.fnGetCompanies(params)
            .then((companiesArr: Array<Company>) => {
                this.isDataLoaded = true;
                this.companiesArr = companiesArr;
            });
    }

    /**
     * Delete company
     * @param {string} id
     * */
    fnDeleteCompany(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this company?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(async (result) => {
            if (result) {
                try {
                    await this.companiesService.fnDeleteCompany(id);
                    _.remove(this.companiesArr, function (company) {
                        return company._id === id;
                    });
                    this.toastr.fnSuccess('Company deleted successfully.');
                } catch (error) {
                    this.toastr.fnError(error.message);
                }
            }
        });
    }

    fnExportCSV() {
        this.isExporting = true;
        this.companiesService.fnGetCompanies({ page: 1, limit: 10000 })
            .then((companiesArr: Array<Company>) => {
                const companies = companiesArr;
                const csvArr = [];
                _.forEach(companies, (element: Company) => {
                    csvArr.push(element);
                });
                const headers = ['name', 'brand', 'locationId.city', 'locationId.state',
                    'locationId.country', 'locationId.postalCode', 'totalLockers',
                    'updatedAt', 'createdAt'];
                this.exportService.fnDownloadCSV('Companies', csvArr, headers);
                this.isExporting = false;
            });
    }
}
