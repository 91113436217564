import {Component, OnInit, Input} from '@angular/core';

import {ShipmentUser} from './shipment-user';
import {Constant} from '../../../../common/constant';

@Component({
    selector: 'pp-shipment-user',
    templateUrl: './shipment-user.component.html',
    styleUrls: ['./shipment-user.component.css']
})

export class ShipmentUserComponent implements OnInit {

    @Input() public title: string;
    @Input() public userObj: ShipmentUser;
    @Input() public form: any;
    readonly CONSTANT = Constant;
    public componentId: number;
    public emailRegEx: any = Constant.EMAIL_REG_EX;

    constructor() {
    }

    ngOnInit() {
        this.componentId = Date.now() + Math.floor(Math.random() * 100) + 1;
    }

}
