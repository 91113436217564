import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Observable, of, mergeMap} from 'rxjs';
import * as _ from 'lodash';

import {UserModalComponent} from '../user-modal/user-modal.component';
import {AuthService} from '../../../common/auth.service';
import {ToastrService} from '../../../common/toastr.service';
import {GoogleMapsService} from '../../../common/google.maps.service';
import {UsersService} from '../../users/users.service';
import {ShipmentsService} from '../shipments.service';
import {CompaniesService} from '../../companies/companies.service';
import {LockersService} from '../../lockers/lockers.service';
import {Shipment} from '../shipment';
import {User} from '../../users/user';
import {Company} from '../../companies/company';
import {Locker} from '../../lockers/locker';
import {Constant} from '../../../common/constant';
import {SHIPMENT_MESSAGE_TYPE} from '../../../common/enums';

@Component({
    selector: 'pp-shipment',
    templateUrl: './shipment.component.html',
    styleUrls: ['./shipment.component.css']
})

export class ShipmentComponent implements OnInit {

    public isDataLoaded;
    public isLabelScanView: boolean;
    public isSelectUsersView: boolean;
    public isCompanyInfoView: boolean;
    public shippingObj: any;
    public manualUserObj: any;
    public scanLabelStr: string;
    public currentUser: User;
    public shipmentId: string;
    public shipmentObj: Shipment;
    public shipmentStatus: string;
    public usersArr: any = [{_id: '', firstName: 'Select', lastName: ' User'}];
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];

    public dataSource: Observable<any>;
    public carriersArr: any[] = [];
    public isReadOnly;

    public preservedShipmentStatus: string;

    readonly CONSTANT = Constant;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private _bsModalService: BsModalService,
                private auth: AuthService, private toastr: ToastrService, private googleMapsService: GoogleMapsService,
                private usersService: UsersService, private shipmentService: ShipmentsService,
                private lockersService: LockersService, private companiesService: CompaniesService) {
        this.isDataLoaded = true;
        this.isReadOnly = true;
        this.isLabelScanView = false;
        this.isSelectUsersView = false;
        this.isCompanyInfoView = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.activatedRoute.params.subscribe(params => {
            this.shipmentId = params['id'];
        });
        this.shipmentObj = {
            packages: [{size: Constant.SLOT_TYPE_SMALL, status: Constant.PACKAGE_STATUS_IN_TRANSIT, label: null}],
            companyId: '',
            receiver: '',
            lockerId: '',
            userId: this.currentUser._id
        };
        this.scanLabelStr = '';
        this.shippingObj = {
            parcel: {},
            from: {},
            to: {},
            lockerId: '',
            userId: this.currentUser._id
        };
        this.manualUserObj = {
            parcel: {},
            from: {},
            to: {}
        };
        this.preservedShipmentStatus = this.shipmentObj.status;
        this.dataSource = Observable
            .create((observer: any) => {
                // Runs on every search
                observer.next(this.scanLabelStr);
            }).pipe(mergeMap((token: string) => this.fnGetCarriersAsObservable(token)));
    }

    ngOnInit() {
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.fnGetCompanies();
            if (this.shipmentId !== 'add') {
                this.fnGetShipment(this.shipmentId);
            } else {
                this.isDataLoaded = false;
            }
        } else if (this.currentUser.role === Constant.ROLE_COMPANY_ADMIN || this.currentUser.role === Constant.ROLE_USER) {
            this.isDataLoaded = true;
            this.fnGetUsers({companyId: this.currentUser.companyId});
            if (this.shipmentId !== 'add') {
                this.fnGetShipment(this.shipmentId);
            }
        }
        this.fnGetCarriers();
    }

    /**
     * Add more package in single shipment.
     * @param {object} packages
     * */
    public fnAddMorePackage(packages) {
        packages.push({size: Constant.SLOT_TYPE_SMALL, status: Constant.PACKAGE_STATUS_IN_TRANSIT, label: null});
    }

    /**
     * Remove package from array.
     * @param {object} packages
     * @param {number} intIndex
     * */
    public fnRemovePackage(packages, intIndex) {
        _.remove(packages, (_package, packageIndex) => {
            return packageIndex === intIndex;
        });
    }

    /**
     * Get list of users
     * @param {object} params
     * */
    public fnGetUsers(params?: object) {
        this.usersService.fnGetUsers(params)
            .then((usersArr: Array<User>) => {
                this.isDataLoaded = false;
                this.usersArr = _.filter(usersArr, function (user) {
                    return (user.role === Constant.ROLE_USER || user.role === Constant.ROLE_CARRIER_USER);
                });
                this.usersArr.unshift({_id: '', firstName: 'Select', lastName: 'User'});
            });
    }

    /**
     * Get Lockers
     * @param {object} params
     * */
    public async fnGetLockers(params?: object) {
        this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber(params['companyId']);
        this.lockersArr.unshift({_id: '', name: 'Select Locker'});
    }

    /**
     * Get companies
     * */
    public fnGetCompanies() {
        this.isDataLoaded = true;
        this.companiesService.fnGetCompanies()
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
            });
    }

    /**
     * Change company event
     * @param {object} params
     * */
    public fnChangeCompany(params?: object) {
        this.shipmentObj.receiver = '';
        this.fnGetUsers(params);
        this.fnGetLockers(params);
    }

    /**
     * Get shipment
     * @param {string} id
     * */
    public fnGetShipment(id: string) {
        this.shipmentService.fnGetShipment(id)
            .then((shipmentObj: Shipment) => {
                this.shipmentObj = shipmentObj;
                if (shipmentObj.isOutbound) {
                    this.shipmentObj.receiver = this.shipmentObj.outboundShipment && this.shipmentObj.outboundShipment['outboundRecipient'] ;
                }
                this.preservedShipmentStatus = this.shipmentObj.status;
                this.shipmentStatus = this.shipmentObj ? this.shipmentObj.status : '';
                if (this.currentUser.role === Constant.ROLE_ADMIN) {
                    this.fnGetUsers({companyId: shipmentObj.companyId});
                }
            });
    }

    /**
     * Get user modal
     * */
    public fnOpenUserModal() {
        const modal = this._bsModalService.show(UserModalComponent, {'class': 'modal-lg'});
        (<UserModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.usersArr.push(result);
            }
        });
    }

    /**
     * Create/Update shipment
     * @param {object} shipmentObj
     * @param {object} shippingObj
     * */
    public fnSaveShipment(shipmentObj: Shipment, shippingObj) {
        // Update shipment
        if (shipmentObj._id) {
            if (this.preservedShipmentStatus !== shipmentObj.status) {
                shipmentObj['fromAdminPanel'] = true;
                this.fnUpdateShipmentStatus(shipmentObj);
            }
            this.toastr.fnSuccess('Shipment updated successfully.');
            shipmentObj.isOutbound ? this.router.navigateByUrl('/outbound-shipments') : this.router.navigateByUrl('/shipments');
        } else {
            // Create Shipment
            if (this.isSelectUsersView) {
                this.fnCreateShipmentForNotRegisteredUser(shipmentObj, shippingObj);
            } else {
                this.fnCreateShipmentForRegisteredUser(shipmentObj);
            }
        }
    }

    /**
     * Update shipment status
     * @param {object} shipmentObj
     * */
    private fnUpdateShipmentStatus(shipmentObj: Shipment) {
        this.shipmentService.fnUpdateShipmentStatus(shipmentObj)
            .then((res: any) => {
                shipmentObj.isOutbound ? this.router.navigateByUrl('/outbound-shipments') : this.router.navigateByUrl('/shipments');
        }).catch((err) => {
                this.toastr.fnError(err.message);
        });
    }

    /**
     * Create shipment for not registered user
     * @param {object} shipmentObj
     * @param {object} shippingObj
     * */
    public fnCreateShipmentForNotRegisteredUser(shipmentObj: Shipment, shippingObj) {
        const createObj = {
            receiver: shippingObj.from,
            packages: shipmentObj.packages,
            userId: this.currentUser._id,
            companyId: shipmentObj.companyId
        };
        this.googleMapsService.fnGetLatLagFromPostalCode(shippingObj.from.postalCode)
            .then((res: any) => {
                createObj.receiver.latitude = res.latitude;
                createObj.receiver.longitude = res.longitude;
                if (!createObj.companyId) {
                    createObj.companyId = this.currentUser.companyId;
                }
                this.shipmentService.fnCreateShipmentForNotRegisteredUser(createObj)
                    .then(() => {
                        this.toastr.fnSuccess('Shipment created successfully.');
                        this.router.navigateByUrl('/shipments');
                    })
                    .catch((err) => {
                        if (err.message) {
                            this.toastr.fnError(err.message);
                        } else {
                            this.toastr.fnError('Shipment not created successfully.');
                        }
                    });
            })
            .catch((err) => {
                this.toastr.fnError(err.message);
            });
    }

    /**
     * Create shipment for registered users.
     * @param {object} shipmentObj
     * */
    public fnCreateShipmentForRegisteredUser(shipmentObj) {
        if (!shipmentObj.companyId) {
            shipmentObj.companyId = this.currentUser.companyId;
        }
        this.shipmentService.fnCreateShipmentForRegisteredUser(shipmentObj)
            .then(() => {
                this.toastr.fnSuccess('Shipment created successfully.');
                this.router.navigateByUrl('/shipments');
            })
            .catch((err) => {
                if (err.message) {
                    this.toastr.fnError(err.message);
                } else {
                    this.toastr.fnError('Shipment not created successfully.');
                }
            });
    }

    /**
     * Get 3rd party data for typeahead.
     * */
    public fnGetCarriers() {
        this.shipmentService.fnGetCarriers()
            .then((response: Array<any>) => {
                this.carriersArr = _.filter(response, function (item) {
                    return item['company'] !== Constant.COMPANY_PUROLATOR;
                });
            });
    }

    /**
     * Get 3rd party single data for create shipment
     * @param {string} label
     * */
    public fnGetCarrier(label) {
        this.shipmentService.fnGetCarrier({label: label})
            .then((response) => {
                if (response) {
                    this.shippingObj = response;
                    this.isCompanyInfoView = true;
                } else {
                    this.toastr.fnInfo('Parcel not available.');
                }
            });
    }

    public fnGetCarriersAsObservable(token: string): Observable<any> {
        const query = new RegExp(token, 'ig');

        return of(
            this.carriersArr.filter((carrier: any) => {
                return query.test(carrier.parcel.label);
            })
        );
    }

    public fnTypeAheadOnSelect(e: TypeaheadMatch): void {
        this.scanLabelStr = e.value;
    }

    /**
     * Create a new shipment using 3rd part data
     * @param {object} shippingObj
     * */
    public fnCreateShipmentUsing3rdPartyData(shippingObj: any) {
        shippingObj.userId = this.currentUser._id;
        this.fnCreateShipping(shippingObj);
    }

    /**
     * Create a new shipment while scan package label.
     * @param {object} shippingObj
     * */
    public fnCreateShipping(shippingObj: any) {
        this.shipmentService.fnCreateShipping(shippingObj)
            .then(() => {
                this.toastr.fnSuccess('Shipment created successfully.');
                this.router.navigateByUrl('/shipments');
            })
            .catch((err) => {
                if (err.message) {
                    this.toastr.fnError(err.message);
                } else {
                    this.toastr.fnError('Shipment not created successfully.');
                }
            });
    }

    /**
     * Re-send notification to the user.
     * @param {string} shipmentObjId
     */
    public resendNotification(shipmentId: string) {
        const type = SHIPMENT_MESSAGE_TYPE.SHIPMENT_CREATED;
        this.shipmentService.resendShipmentNotification(shipmentId, type)
            .then(() => {
                this.toastr.fnSuccess('Notification has been sent successfully.');
                this.router.navigateByUrl('/shipments');
            })
            .catch((err) => {
                if (err.message) {
                    this.toastr.fnError(err.message);
                } else {
                    this.toastr.fnError('Notification has not been sent successfully.');
                }
            });
    }
}
