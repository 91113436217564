<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header ">Feedback
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="feedbackArr.length" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="table-responsive" *ngIf="feedbackArr.length">
            <table>
                <thead>
                <tr>
                    <th width='15%' class="bg-primary company-name cursor-auto">Company</th>
                    <th width='15%' class="bg-primary locker-name cursor-auto">Locker</th>
                    <th width='15%' class="bg-primary user-name cursor-auto">User</th>
                    <th width='30%' class="bg-primary feedback cursor-auto">FeedBack</th>
                    <th width='15%' class="bg-primary created-at cursor-auto">Created At</th>
                    <th width='10%' class="bg-primary transactions cursor-auto">Transactions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let feedback of feedbackArr">
                    <td width='15%'>
                        {{(feedback.companyId && feedback.companyId.name) ? feedback.companyId.name : '&nbsp;'}}
                    </td>
                    <td width='15%'>
                        {{(feedback.preferredLockerId && feedback.preferredLockerId.name) ? feedback.preferredLockerId.name : '&nbsp;'}}
                    </td>
                    <td width='15%'>
                        <a [routerLink]="['/users/'+feedback.userId._id]" *ngIf="feedback.userId">
                            {{feedback.userId['firstName'] + ' ' + feedback.userId['lastName']}}
                        </a>
                    </td>
                    <td width='30%'>{{feedback.feedback ? feedback.feedback : '&nbsp;'}}</td>
                    <td width='15%'>{{feedback.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td width='10%'>
                        <button type="button" class="btn btn-xs btn-primary"
                                [routerLink]="['/transactions']"
                                [queryParams]="{userId: feedback.userId ? feedback.userId._id : '',
                                                companyId: feedback.companyId ? feedback.companyId._id : '',
                                                lockerId: feedback.preferredLockerId ? feedback.preferredLockerId._id : ''}">
                            View
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !feedbackArr.length" class="text-center">No feedback found.</h3>
        <div class="col-lg-12 text-center" *ngIf="feedbackArr.length">
            <pagination [totalItems]="totFeedbacks" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit" [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
</div>
