import {COUNTRY} from '../../common/enums';

export class BillingAddressInfo {
    address: string;
    city: string;
    province: string;
    postalCode: string;
    country: COUNTRY;
}

export class CreditCardInfo {
    _id?: string;
    nameOnCard: string;
    number: string;
    expirationYear: string;
    expirationMonth: string;
    cvCode: string;
    billingAddress?: BillingAddressInfo;
}

