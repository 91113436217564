import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

@Component({
    selector: 'pp-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.css']
})

export class DeleteModalComponent implements OnInit {

    public active: boolean;
    public body: string;
    public title: string;
    public btnText: string;
    public onClose: Subject<boolean>;

    constructor(private _bsModalRef: BsModalRef) {
        this.active = false;
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    public showConfirmationModal(title: string, body: string, btnText?: string): void {
        this.title = title;
        this.body = body;
        this.btnText = btnText;
        this.active = true;
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this.active = false;
        this.onClose.next(false);
        this._bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this._bsModalRef.hide();
    }

}
