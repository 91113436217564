<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">
            Locker Logs : {{locker?.name}}
            <a [href]="downloadLogFileUrl" class="btn btn-primary pull-right"
               *ngIf="isLockerLogsAvailable && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                Download <i class="fa fa-fw" ngClass='fa fa-download'></i>
            </a>
            <button class="pull-right btn btn-success m-r-2" (click)="fnRefreshLockeLogs()"
                    *ngIf=" currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                Refresh <i class="fa fa-fw" ngClass='fa fa-refresh'></i>
            </button>
        </h1>
    </div>

    <div class="col-lg-6">
        <!--<label>Select Date</label>-->
        <input type="text"
               [(ngModel)]="date"
               placeholder="Datepicker"
               class="form-control"
               (ngModelChange)="fnSetUrl(date)"
               [maxDate]="maxDate"
               [minDate]="minDate"
               [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false }"
               bsDatepicker/>
        <br>
    </div>
    <div class="col-lg-6">
        <div class="form-group">
            <!--<label>Log Type</label>-->
            <select id="company" class="form-control" name="company" [(ngModel)]="logsType" [value]="logsType"
                    (change)="fnSetUrl(date)">
                <option value="{{CONSTANT?.ANDROID}}">{{CONSTANT?.IONIC | uppercase }}</option>
                <option value="{{CONSTANT?.SNBC}}">{{CONSTANT?.SNBC | uppercase }}</option>
                <option value="{{CONSTANT?.MIDDLEWARE}}">{{CONSTANT?.MIDDLEWARE | uppercase }}</option>
                <option value="{{CONSTANT?.DARWIN}}">{{CONSTANT?.DARWIN | uppercase }}</option>
            </select>
        </div>
    </div>
    <div class="col-sm-12" style="position:relative;padding-top:35%;">
        <iframe [src]="logFileUrl" class="resp-iframe" id="my_iframe" gesture="media" allowfullscreen
        ></iframe>
    </div>
</div>
