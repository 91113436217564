<div class="container-fluid sign-in-page">
    <div class="row">
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <img class="img-logo" src="assets/images/pp_logo.png" alt="Parcel Port" width="100%"/>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <div class="login-panel panel panel-default whiteframe-10dp">
                <div class="panel-heading">
                    <div class="panel-title text-center" *ngIf="isLogin"><strong>Sign In</strong></div>
                    <div class="panel-title text-center" *ngIf="!isLogin"><strong>Resend Verification code</strong>
                    </div>
                </div>
                <div class="panel-body">
                    <form #signInForm="ngForm" role="form" *ngIf="isLogin"
                          (ngSubmit)="signInForm.valid && fnSignIn(signInObj)">
                        <fieldset>
                            <div class="form-group"
                                 [ngClass]="{'has-error': (signInForm.submitted || email.touched) && !email.valid}">
                                <input class="form-control" placeholder="E-mail" name="email" type="email"
                                       [(ngModel)]="signInObj.email" #email="ngModel"
                                       [pattern]="emailRegEx" required/>
                                <div *ngIf="(signInForm.submitted || email.touched) && !email.valid">
                                    <small class="text-danger" *ngIf="email.errors['required']">
                                        Email is required.
                                    </small>
                                    <small class="text-danger" *ngIf="email.errors['pattern']">
                                        Email is invalid.
                                    </small>
                                </div>
                            </div>
                            <div class="form-group"
                                 [ngClass]="{'has-error': (signInForm.submitted || password.touched) && !password.valid}">
                                <input class="form-control" placeholder="Password" name="password" type="password"
                                       [(ngModel)]="signInObj.password" #password="ngModel" required/>
                                <div *ngIf="(signInForm.submitted || password.touched) && !password.valid">
                                    <small class="text-danger" *ngIf="password.errors['required']">
                                        Password is required.
                                    </small>
                                </div>
                            </div>
                            <!-- Change this to a button or input when using this as a form -->
                            <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                        </fieldset>
                    </form>
                    <div *ngIf="!isLogin">
                        <div class="form-group">
                            <input class="form-control" placeholder="E-mail" name="resendEmail" type="email"
                                   [(ngModel)]="resendEmail" [pattern]="emailRegEx"/>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary btn-block" (click)="fnResendCode()">Resend Code</button>
                        </div>
                    </div>
                </div>
                <div class="panel-footer text-center">
                    <a [routerLink]="['/reset-password-verification']">Reset Password</a> |
                    <a href="javascript:void(0);" *ngIf="isLogin" (click)="isLogin = false">Resend Verification Code</a>
                    <a href="javascript:void(0);" *ngIf="!isLogin" (click)="isLogin = true">Sign In</a>
                </div>
            </div>
        </div>
    </div>
</div>
