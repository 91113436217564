<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Shipments
            <button class="pull-right btn btn-primary" *ngIf="this.currentUser.role === CONSTANT.ROLE_ADMIN" [routerLink]="['/shipments/add']">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">New Shipment</span>
            </button>
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="shipmentArr.length && !invalidDateRange" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
            <button class="pull-right btn btn-danger m-r-2" *ngIf="shipmentArr.length" (click)="fnRemoveAllShipment(shipmentArr)">
                <i class="fa fa-trash-o"></i>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-3" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <div>
                    <div class="form-group">
                        <select id="company" class="form-control" name="company" [(ngModel)]="filterObj.companyId"
                                (change)="filterObj.lockerId = '';filterObj.receiver = '';filterObj.selectedUser = '';selectedUser = '';fnGetLockers({companyId: filterObj.companyId})">
                            <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-sm-3': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-sm-4': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}">
                    <div class="form-group">
                        <select id="locker" class="form-control" name="locker" [(ngModel)]="filterObj.lockerId"
                                (change)="filterObj.receiver = '';filterObj.selectedUser = '';selectedUser = '';fnChangeShipments(filterObj)">
                            <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                        </select>
                    </div>
            </div>

            <div [ngClass]="{'col-sm-3': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-sm-4': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}"
                class="{{!selectedCreatedAt || invalidDateRange ? 'has-error' : ''}}">
                <div class="form-group">
                    <input type="text" name="daterangeInput" class="form-control" daterangepicker
                           placeholder="Select Date Range"
                           [(ngModel)]="selectedCreatedAt"
                           [options]="{autoUpdateInput: false, opens: 'left'}"
                           (change)="changeDateRange()"
                           (applyDaterangepicker)="fnSelectedDate($event)"
                           (cancelDaterangepicker)="fnClearDate()"/>
                    <small class="text-danger" *ngIf="invalidDateRange">
                        Maximum date range is {{maxDateRangeDays}} days.
                    </small>
                </div>
            </div>

            <div [ngClass]="{'col-sm-3': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-sm-4': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}">
                <div class="form-group">
                    <select id="status" class="form-control" name="status" [(ngModel)]="filterObj.status"
                            (change)="fnChangeShipments(filterObj)">
                            <option value="">Any</option>
                            <option [ngValue]="item.value" *ngFor="let item of shipmentStatusArr">{{CONSTANT.READABLE_SHIPMENT_STATUS[item.key]}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div [ngClass]="'col-sm-5'">
                <div class="form-group">
                    <ng-template #customItemTemplate let-model="item" let-index="index">
                        {{model.firstName}} {{model.lastName}}{{model.email ? ' (' + model.email + ')' : ''}}
                    </ng-template>
                    <input id="users" name="users" class="form-control" autocomplete="off" [(ngModel)]="selectedUser" [typeahead]="dataSource"
                           (typeaheadOnSelect)="fnOnSelectUser($event)" [typeaheadItemTemplate]="customItemTemplate"
                           [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body" [typeaheadMinLength]="0"
                           [typeaheadOptionsInScrollableView]="10" (blur)="fnTypeAheadOnBlur($event)" placeholder="Select User" autocomplete="off">
                </div>
            </div>

            <div class="col-sm-5" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN || currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN">
                <div class="form-group">
                    <input type="text" name="trackingNumber" class="form-control" placeholder="Search Label" [(ngModel)]="searchLabelTxt" (blur)="onLabelChange()" autocomplete="off">
                </div>
            </div>

            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="fnClearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div *ngIf="shipmentArr.length">
            <table class="shipments-table">
                <thead>
                <tr>
                    <th class="bg-primary">
                        <input type="checkbox" class="form-check-input" name="isAllShipmentChecked"
                               [(ngModel)]="isAllShipmentChecked"
                               (change)="fnOnAllShipmentChanged(isAllShipmentChecked)"/>
                    </th>
                    <th *ngIf="CONSTANT.ROLE_ADMIN === currentUser.role" class="bg-primary company-name" (click)="sortBy('companyId.name')">
                        Company <i [class]="setSortIcon('companyId.name')"></i></th>
                    <th class="bg-primary carrier-name" (click)="sortBy('carrierName')">
                        Carrier Name <i [class]="setSortIcon('carrierName')"></i></th>
                    <th class="bg-primary receiver" (click)="sortBy('receiver.firstName')">
                        Receiver <i [class]="setSortIcon('receiver.firstName')"></i></th>
                    <th class="bg-primary shipment-status" (click)="sortBy('status')">
                        Status <i [class]="setSortIcon('status')"></i></th>
                    <th class="bg-primary created-at" (click)="sortBy('createdAt')">
                        Created <i [class]="setSortIcon('createdAt')"></i>
                    </th>
                    <th class="bg-primary updated-at" (click)="sortBy('packages.updatedAt')">
                        Modified <i [class]="setSortIcon('packages.updatedAt')"></i>
                    </th>
                    <th class="bg-primary expire-at" (click)="sortBy('packages.expireAt')">
                        Expires <i [class]="setSortIcon('packages.expireAt')"></i>
                    </th>
                    <th class="bg-primary cursor-auto">Slot #</th>
                    <th class="bg-primary cursor-auto" (click)="sortBy('packages.label')">
                        Label <i [class]="setSortIcon('packages.label')"></i></th>
                    <th class="bg-primary cursor-auto" (click)="sortBy('packages.size')">
                        Slot Size <i [class]="setSortIcon('packages.size')"></i></th>
                    <th class="bg-primary locker-name" (click)="sortBy('lockerId.name')">
                        Locker Name <i [class]="setSortIcon('lockerId.name')"></i></th>
                    <th class="bg-primary cursor-auto">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let shipment of shipmentArr">
                    <td><input type="checkbox" class="form-check-input" [(ngModel)]="shipment.isChecked"
                               (change)="fnOnShipmentChanged()"/></td>
                    <td *ngIf="CONSTANT.ROLE_ADMIN === currentUser.role">{{shipment.companyId && shipment.companyId.name}}</td>
                    <td>{{shipment.carrierName}}</td>
                    <td>
                        <div *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN && shipment.receiver">
                            <p class="m-b-0">
                                <a [routerLink]="['/users/'+shipment.receiver._id]">
                                    {{shipment.receiver['firstName'] + ' ' +
                                shipment.receiver['lastName']}}
                                </a>
                            </p>
                            <small *ngIf="shipment.status === CONSTANT.SHIPMENT_STATUS_IN_TRANSIT || shipment.status === CONSTANT.SHIPMENT_STATUS_EXCEPTION || shipment.status === CONSTANT.SHIPMENT_STATUS_EXPIRED">
                                <div class="btn-group btn-group-xs">
                                    <button type="button" class="btn btn-secondary bg-info" (click)="openPickupCodeModel(PickupCodeDialogBox,shipment);">
                                       Pickup code
                                    </button>
                                </div>
                            </small>
                        </div>
                        <div *ngIf="currentUser.role !== CONSTANT.ROLE_ADMIN && shipment.receiver">
                            <p class="m-b-0">
                                {{ shipment.receiver['firstName'] + ' ' + shipment.receiver['lastName'] }}
                            </p>
                            <small *ngIf="shipment.status === CONSTANT.SHIPMENT_STATUS_IN_TRANSIT || shipment.status === CONSTANT.SHIPMENT_STATUS_EXCEPTION || shipment.status === CONSTANT.SHIPMENT_STATUS_EXPIRED">
                                <div class="btn-group btn-group-xs">
                                    <button type="button" class="btn btn-secondary bg-info" (click)="openPickupCodeModel(PickupCodeDialogBox,shipment);">
                                        Pickup code
                                    </button>
                                </div>
                            </small>
                        </div>
                    </td>
                    <td>
                        <p class="m-b-0">
                            {{shipment.status ? ( CONSTANT.READABLE_SHIPMENT_STATUS[shipment.status] | RemoveUnderscore)
                            : '&nbsp;'}}
                        </p>
                        <small style="white-space: pre-wrap;">{{shipment.packages[0].updatedAt | LocalDate}}</small>
                    </td>
                    <td style="white-space: pre-wrap;">{{shipment.createdAt | LocalDate}}</td>
                    <td style="white-space: pre-wrap;">{{shipment.packages[0].updatedAt | LocalDate}}</td>
                    <td style="white-space: pre-wrap;">{{shipment.packages[0].expireAt | LocalDate}}</td>
                    <td>{{shipment.packages && shipment.packages[0] && shipment.packages[0].slotId
                        &&  shipment.packages[0].slotId.cabinetId
                        && shipment.packages[0].slotId.cabinetId.cabinetNumber + shipment.packages[0].slotId.slotNumber}}</td>

                    <td>{{shipment.packages && shipment.packages.length ? shipment.packages[0].label : '&nbsp;'}}</td>
                    <td>{{shipment.packages && shipment.packages.length ? (shipment.packages[0].size | RemoveUnderscore) : '&nbsp;'}}</td>
                    <td>{{shipment.lockerId && shipment.lockerId.name}}</td>
                    <td>
                        <div class="btn-group btn-group-xs"
                             *ngIf="this.currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                            <button type="button" class="btn btn-primary"
                                    *ngIf="this.currentUser.role === CONSTANT.ROLE_ADMIN || (this.currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN && shipment.status === SHIPMENT_STATUS.PREPARING)"
                                    [routerLink]="['/shipments/'+shipment._id]">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-danger" (click)="fnDeleteShipment(shipment._id)">
                                <i class="fa fa-trash-o"></i>
                            </button>
                            <button type="button" class="btn btn-primary" *ngIf="checkShipmentStatusAndPermission(shipment.status)" (click)="openModal(ShipmentExpireDate)">
                                <i class="fa fa fa-calendar"></i>
                            </button>
                        </div>
                    </td>

                    <ng-template #ShipmentExpireDate>
                        <div class="modal-header">
                            <h4 class="modal-title pull-left">Select a new expiration date.</h4>
                            <button type="button" class="close pull-right" aria-label="Close" (click)="closeExpirationDateModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>Shipment expiration date will be changed as well as the shipment status.</p>
                            <ul>
                                <li>To <b>extend</b> a shipment's expiry date, use the date picker to select a new date in the future. The shipment status will change to <b>IN TRANSIT</b>.</li>
                                <li>To <b>expire</b> a shipment, type in a new date in the past. Do not use the date picker. The shipment status will change to <b>EXPIRED</b>.</li>
                            </ul>
                            <input type="text" name="shipmentExpireDate" class="form-control m-b-6" bsDatepicker
                                   placeholder="Select a new date"
                                   [(ngModel)]="selectedExpireDate"
                                   (bsValueChange)="onSelectedExpireDate($event)"
                                   [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', minDate: todayDate, showWeekNumbers:false }"
                                   [bsValue]="todayDate"
                            />
                            <button type="button" class="btn btn-success" [disabled]="!this.selectedExpireDate" (click)="onSubmitShipmentExpireDate(shipment._id)">
                                Change Date
                            </button>
                        </div>
                    </ng-template>

                    <ng-template #PickupCodeDialogBox>
                        <div class="modal-header">
                            <h4 class="modal-title pull-left">
                                Pickup code
                            </h4>
                            <button type="button" class="close pull-right" aria-label="Close"
                                    (click)="closePickupCodeModel()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>
                                Shipment <b>{{ shipmentInfo.label }}</b> <br> pickup code:
                            </p>

                            <div class="text-center"
                                 style="display: flex;justify-content: center;align-items: flex-end;">
                                <div>
                                    <h1 style="text-align:center;color:#001f89;margin-top: 5px;" #pickupCodeText>
                                        {{ shipmentInfo.accessCode }}
                                    </h1>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-lg btn-link" tooltip="Copy to clipboard"
                                            placement="right"
                                            (click)="copyPickupCode(pickupCodeText);" style="font-size: 1.4rem;color: black;margin-bottom: 4px;">
                                        <i class="fa fa-files-o" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="alert-success text-center" *ngIf="shipmentInfo.isCopied">Copied</div>
                        </div>
                    </ng-template>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !shipmentArr.length" class="text-center">No shipments found.</h3>
        <div class="col-lg-12 text-center" *ngIf="shipmentArr.length">
            <pagination [totalItems]="totShipments" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
</div>
