import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import {Cabinet} from './cabinet';
import {SearchResults} from '../../common/search-results';

@Injectable()
export class CabinetsService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get list of cabinets
     * @param {object} params
     * */
    fnGetCabinets(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/cabinets' + (qString ? '?' + qString : ''))
                .subscribe((response: SearchResults<Cabinet>) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get cabinet
     * @param {string} id
     * */
    fnGetCabinet(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/cabinets/' + id)
                .subscribe((response: Cabinet) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create new cabinet
     * @param {object} cabinetObj
     * */
    fnCreateCabinet(cabinetObj: Cabinet) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/cabinets', cabinetObj)
                .subscribe((response: Cabinet) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update cabinet
     * @param {object} cabinetObj
     * */
    fnUpdateCabinet(cabinetObj: Cabinet) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/cabinets/' + cabinetObj._id, cabinetObj)
                .subscribe((response: Cabinet) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete cabinet
     * @param {string} id
     **/
    fnDeleteCabinet(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/cabinets/' + id)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
}
