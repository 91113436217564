import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';

import {Constant} from '../../../common/constant';
import {Company} from '../../companies/company';
import {Locker} from '../../lockers/locker';
import {User} from '../../users/user';

import {AuthService} from '../../../common/auth.service';
import {CompaniesService} from '../../companies/companies.service';
import {PaymentPlansService} from '../../payment-plans/payment-plans.service';
import {LockersService} from '../../lockers/lockers.service';
import {ToastrService} from '../../../common/toastr.service';
import {EnrollmentUserService} from './enrollment-user.service';
import {Location} from '../../location/location';

@Component({
    selector: 'pp-enrollment-user',
    templateUrl: './enrollment-user.component.html',
    styleUrls: ['./enrollment-user.component.css']
})

export class EnrollmentUserComponent implements OnInit {

    public isDataLoaded;
    public currentUser: User;
    public userObj: User;
    public userId: string;
    public emailRegEx: any = Constant.EMAIL_REG_EX;
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];
    public isRequired: Boolean = false;

    readonly CONSTANT = Constant;

    constructor(
        private _bsModalService: BsModalService,
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private companiesService: CompaniesService,
        private paymentplansService: PaymentPlansService,
        private lockersService: LockersService,
        private router: Router,
        private toastr: ToastrService,
        private usersService: EnrollmentUserService,
    ) {
        this.isDataLoaded = false;
        this.userObj = {
            locationId: {formattedAddress: '', placeId: ''},
            companyId: '',
            preferredLockerId: '',
            language: Constant.USER_LANGUAGES[0]['key'],
            isHeightRestricted: false
        };
        this.currentUser = this.auth.fnGetCurrentUser();
    }

    ngOnInit() {
        if (this.currentUser.role === Constant.ROLE_COMPANY_ADMIN) {
            this.userObj.companyId = this.currentUser.companyId;
            this.fnGetLockers({companyId: this.userObj.companyId});
        }
    }

    /**
     * Get lockers
     * */
    async fnGetLockers(params?: object) {
        if (!params['companyId']) {
            this.lockersArr = [{_id: '', name: 'Select Locker'}];
            return;
        }
        const lockers =<Array<Locker>> await this.lockersService.getLockersNameAndNumber(params['companyId'])
        this.lockersArr = [];
        if (lockers.length === 1) {
            const lockerObj: Locker = lockers[0];
            this.userObj.preferredLockerId = lockerObj._id;
        } else {
            this.lockersArr = lockers;
            this.lockersArr.unshift({_id: '', name: 'Select Locker'});
        }
    }

    /**
     * Create/Update user
     * @param {object} userObj
     * */
    fnSaveUser(userObj: User) {
        if (!userObj.locationId.formattedAddress && !userObj.locationId.placeId) {
            return;
        }
        if (!userObj.phone && !userObj.email) {
            this.toastr.fnError('An email address or mobile phone # is required');
            this.isRequired = true;
        } else {
            this.isRequired = false;
            if (!userObj.companyId) {
                userObj.companyId = this.currentUser.companyId;
            }
            this.usersService.fnCreateUser(userObj)
                .then(() => {
                    this.toastr.fnSuccess('User created successfully.');
                    this.router.navigateByUrl('/users');
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        }
    }

    resetRequired() {
        this.isRequired = false;
    }
}
