import {Injectable} from '@angular/core';
import * as _ from 'lodash';

declare let google: any;

@Injectable()
export class GoogleMapsService {

    constructor() {
    }

    /**
     * Get lat and lag using postal code
     * @param {string} postalCode
     * */
    fnGetLatLagFromPostalCode(postalCode: string) {
        return new Promise((resolve, reject) => {
            if (postalCode) {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({'address': postalCode}, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const address = results[0];
                        const latitude = address.geometry.location.lat();
                        const longitude = address.geometry.location.lng();
                        resolve({latitude: latitude, longitude: longitude});
                    } else  {
                        resolve({latitude: 42.432239, longitude: -71.18360189999999});
                    }
                });
            } else {
                reject({message: 'Postal code not valid.'});
            }
        });
    }

    /**
     * Find closest location using latitude and longitude
     * @param {object} lockerArr
     * @param {number} latitude
     * @param {number} longitude
     * */
    fnFindClosestLocation(lockerArr: object, latitude: number, longitude: number) {
        const lat = latitude;
        const lng = longitude;
        const R = 6371; // radius of earth in km
        const distances = {};
        let closest = '';
        _.forEach(lockerArr, (itemObj: any) => {
            const mLat = itemObj.locationId.latitude;
            const mLng = itemObj.locationId.longitude;
            const dLat = this.fnRad(mLat - lat);
            const dLong = this.fnRad(mLng - lng);
            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.fnRad(lat)) * Math.cos(this.fnRad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = R * c;
            distances[itemObj._id] = d;
            if (closest === '' || d < distances[closest]) {
                closest = itemObj._id;
            }
        });
        const sortedDistance = _.chain(distances)
            .map((val, key) => {
                return {name: key, count: val};
            })
            .sortBy('count')
            .keyBy('name')
            .mapValues('count')
            .value();
        const closestLockerArr = [];
        _.forEach(sortedDistance, (distanceValue, lockerIdKey) => {
            closestLockerArr.push({lockerId: lockerIdKey, distance: distanceValue});
        });
        return closestLockerArr;
    }

    /**
     * Calculate Pie
     * */
    fnRad(x) {
        return x * Math.PI / 180;
    }

}
