import {Component, OnInit} from '@angular/core';
import {AuthService} from '../common/auth.service';
import {Router} from '@angular/router';
import {ToastrService} from '../common/toastr.service';

@Component({
    selector: 'pp-activation-status',
    templateUrl: './activation-status.component.html',
    styleUrls: ['./activation-status.component.css']
})
export class ActivationStatusComponent implements OnInit {
    public isActivated: boolean = false;
    public matchedUserId: string;
    public userMessage: string;
    public verificationCode: string;
    public token: string;

    constructor(private auth: AuthService,
                private router: Router,
                private toastr: ToastrService) {
    }

    ngOnInit() {
        this.userMessage = 'Please wait while We are trying to activate you...';
        this.verificationCode = this.router.routerState.snapshot.url.slice(10);
        this.auth.fnConfirmCode(this.verificationCode)
            .then((res: any) => {
                this.token = res.token;
                if (res.userId) {
                    this.matchedUserId = res.userId;
                    this.isActivated = true;
                } else {
                    this.matchedUserId = '';
                    this.isActivated = false;
                    this.userMessage = res.message;
                }
            });
    }

    fnAcceptTerms() {
        if (this.matchedUserId) {
            this.auth.fnAcceptTerms(this.matchedUserId)
                .then((res: any) => {
                    if (res.message === 'User activated successfully.') {
                        this.toastr.fnSuccess('Your account has been activated!');
                        this.router.navigate(['/profile', this.matchedUserId], {queryParams: {token: this.token}, skipLocationChange: true});
                    } else {
                        this.toastr.fnError(res.message);
                    }
                });
        } else {
            console.log('No user found to accept t&c.');
        }
    }
}
