<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Slots
            <button class="pull-right btn btn-primary" [routerLink]="['/slots/add']"
                    *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">New Slot</span>
            </button>
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="slotsArr.length" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-4" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" [(ngModel)]="filterObj.companyId"
                            (change)="filterObj.lockerId = '';filterObj.cabinetId = '';changeCompany({companyId: filterObj.companyId})">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="filterObj.lockerId"
                            (change)="filterObj.cabinetId = '';changeLocker({lockerId: filterObj.lockerId})">
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <select id="cabinetId" class="form-control" name="cabinetId" [(ngModel)]="filterObj.cabinetId"
                            (change)="fnChangeFilters(filterObj)">
                        <option [ngValue]="item._id" *ngFor="let item of cabinetsArr">
                            {{item.cabinetNumber ? item.type.toUpperCase() + ' - ' + item.cabinetNumber : 'Select Cabinet Number'}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="fnClearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12 m-t-10">
        <div class="table-responsive" *ngIf="slotsArr.length">
            <table>
                <thead>
                <tr>
                    <th class="bg-primary company-name" (click)="sortBy('companyId.name')">
                        Company Name <i [class]="setSortIcon('companyId.name')"></i></th>
                    <th class="bg-primary locker-name" (click)="sortBy('lockerId.name')">
                        Locker Name <i [class]="setSortIcon('lockerId.name')"></i></th>
                    <th class="bg-primary cabinet-number" (click)="sortBy('cabinetId.cabinetNumber')">
                        Cabinet <i [class]="setSortIcon('cabinetId.cabinetNumber')"></i></th>
                    <th class="bg-primary slot-number" (click)="sortBy('slotNumber')">
                        Slot Number <i [class]="setSortIcon('slotNumber')"></i></th>
                    <th class="bg-primary slot-number cursor-auto">Upper Slot</th>
                    <th class="bg-primary slot-type" (click)="sortBy('type')">
                        Type <i [class]="setSortIcon('type')"></i></th>
                    <th class="bg-primary slot-status" (click)="sortBy('status')">
                        Status <i [class]="setSortIcon('status')"></i></th>
                    <th class="bg-primary cursor-auto">Shipments</th>
<!--                    <th class="bg-primary cursor-auto" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">View Status</th>-->
                    <th class="bg-primary cursor-auto" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let slot of slotsArr">
                    <td>{{slot.companyId ? slot.companyId.name : '&nbsp;'}}</td>
                    <td>{{slot.lockerId ? slot.lockerId.name : '&nbsp;'}}</td>
                    <td>{{ (slot.cabinetId ? slot.cabinetId.type + ' - ' + slot.cabinetId.cabinetNumber : '&nbsp;') | uppercase }} </td>
                    <td>{{slot.slotNumber}}</td>
                    <td>{{slot.isUpperSlot ? 'Yes' : 'No'}}</td>
                    <td>{{slot.type | RemoveUnderscore}}</td>
                    <td>
                        <div class="pull-left">
                            <div *ngIf="slot.status === CONSTANT.SLOT_STATUS_REPAIR">
                                {{slot.status | RemoveUnderscore}}
                            </div>
                            <div *ngIf="slot.status !== CONSTANT.SLOT_STATUS_REPAIR">
                                <div>
                                    {{slot.status | RemoveUnderscore}}
                                </div>

                                <small class="text-danger"
                                       *ngIf="slot.status === CONSTANT.SLOT_STATUS_PURGED && slot.packageId">
                                    Parcel needs to be removed. It's {{moment().diff(moment(slot.packageId.updatedAt),
                                    'days')}}
                                    days old.
                                </small>
                            </div>
                        </div>
                        <div class="pull-right btn-group btn-group-xs" *ngIf="slot.status === CONSTANT.SLOT_STATUS_REPAIR || slot.status === CONSTANT.SLOT_STATUS_NOT_IN_USE || slot.status === CONSTANT.SLOT_STATUS_RESERVED || slot.status === CONSTANT.SLOT_STATUS_OPEN">
                            <button type="button" class="btn btn-default" (click)="showReason(ReasonModal, slot);">
                                <i class="fa fa-info-circle"></i>
                            </button>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="slot.packageId && slot.packageId.shipmentId">
                            <a [routerLink]="['/shipments/'+slot.packageId.shipmentId._id]">View</a>
                        </div>
                        <span *ngIf="!slot.packageId">&nbsp;</span>
                    </td>
<!--                    <td *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)" style="text-align: center">-->
<!--                        <div class="btn-group btn-group-md" >-->
<!--                            <button type="button" class="btn btn-default" (click)="showStatus(StatusModal, slot.slotNumber, slot.cabinetId?.cabinetNumber, slot.lockerId?._id)">-->
<!--                                <i class="fa fa-info-circle"></i>-->
<!--                            </button>-->
<!--                            <button type="button" class="btn btn-default" (click)="showSlotOpenModal(slotOpenModal, slot.slotNumber, slot.cabinetId?.cabinetNumber, slot.lockerId?._id)">-->
<!--                                <i class="fa fa-inbox"></i>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </td>-->
                    <td *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                        <div class="btn-group btn-group-xs">
                            <button type="button" class="btn btn-primary"
                                    [routerLink]="['/slots/'+slot._id]" [queryParams]="{shipmentId: slot.packageId && slot.packageId.shipmentId && slot.packageId.shipmentId._id }">
                                    <!-- *ngIf="slot.status !== CONSTANT.SLOT_STATUS_UNAVAILABLE" -->
                                <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-danger"
                                    *ngIf="slot.status === CONSTANT.SLOT_STATUS_AVAILABLE"
                                    (click)="fnDeleteSlot(slot._id)">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !slotsArr.length" class="text-center">No slots found.</h3>
        <div class="col-lg-12 text-center" *ngIf="slotsArr.length">
            <pagination [totalItems]="totSlots" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit" [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>

    <ng-template #ReasonModal>
        <div class="modal-header">
            <h4 class="modal-title pull-left">
                <b>{{ CONSTANT.REASON_MODAL_TITLE[slotInfo.status]}} : {{ slotInfo.slotNumber }}</b>
            </h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="slotReasonModelRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>
                Date: <b>{{ slotInfo && slotInfo.reasonDate | LocalDate }}</b>
            </p>
            <p>
                This Slot {{ CONSTANT.REASON_MODAL_DESC[slotInfo.status] }} due to the following reason:
            </p>
            <p>
                <b>{{ slotInfo.reason }} </b>
            </p>
        </div>
    </ng-template>

<!--    <ng-template #StatusModal>-->
<!--        <div class="modal-header">-->
<!--            <h4 class="modal-title pull-left">-->
<!--                <b>Slot Status: {{ slotDetails?.boxId }}</b>-->
<!--            </h4>-->
<!--            <button type="button" class="close pull-right" aria-label="Close" (click)="closeStatusModal();">-->
<!--                <span aria-hidden="true">&times;</span>-->
<!--            </button>-->
<!--        </div>-->
<!--        <div class="modal-body">-->
<!--            <p *ngIf="slotStatus">-->
<!--                <b>Open:</b> {{ slotStatusResp?.isOpened }}-->
<!--            </p>-->
<!--            <p *ngIf="slotStatus">-->
<!--                <b>Storage:</b> {{ slotStatusResp?.isStorage }}-->
<!--            </p>-->
<!--            <div *ngIf="slotStatusInterval">-->
<!--                Connection is open-->
<!--                <button type="button" class="btn btn-primary" (click)="closeSocketConnection();">-->
<!--                    Close connection-->
<!--                </button>-->
<!--            </div>-->
<!--        <pp-spinner [isSpinnerLoad]="!slotStatus"></pp-spinner>-->
<!--        </div>-->
<!--    </ng-template>-->
<!--    <ng-template #slotOpenModal>-->
<!--        <div class="modal-header">-->
<!--            <h4 class="modal-title pull-left">-->
<!--                <b>Slot: {{ slotDetails?.boxId }}</b>-->
<!--            </h4>-->
<!--            <button type="button" class="close pull-right" aria-label="Close" (click)="closeSlotOpenModal();">-->
<!--                <span aria-hidden="true">&times;</span>-->
<!--            </button>-->
<!--        </div>-->

<!--        <div class="modal-body">-->
<!--            <label for="reason">Enter reason to open slot-->
<!--                <span class="text-danger">*</span>-->
<!--            </label>-->

<!--            <textarea id="reason" class="form-control" name="reason" placeholder="Enter reason" [(ngModel)]="slotOpenReason" #reason="ngModel"></textarea>-->
<!--            <div *ngIf="reason.touched && !reason.value">-->
<!--                <small class="text-danger">Reason is required.</small>-->
<!--            </div>-->

<!--            <label for="password" class="m-t-10">Enter Password-->
<!--                <span class="text-danger">*</span>-->
<!--            </label>-->
<!--            <input  id="password" type="password" width="90%" class="form-control" name="password" placeholder="Enter password" #password="ngModel" [(ngModel)]="slotOpenPassword"/>-->
<!--            <div *ngIf="password.touched && !password.value">-->
<!--                <small class="text-danger">Password is required.</small>-->
<!--            </div>-->

<!--            <div class="m-t-10">-->
<!--                <button type="button" class="btn btn-primary m-r-4" [disabled]="!(reason.value && password.value)" (click)="validateReasonAndPassword(OTPModal)">-->
<!--                    Submit-->
<!--                </button>-->
<!--                <button type="button" class="btn btn-default m-l-4" (click)="closeSlotOpenModal()">-->
<!--                    Cancel-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->

<!--    </ng-template>-->
<!--    <ng-template #OTPModal>-->
<!--        <div class="modal-header">-->
<!--            <h4 class="modal-title pull-left">-->
<!--                <b>Enter OTP</b>-->
<!--            </h4>-->
<!--            <button type="button" class="close pull-right" aria-label="Close" (click)="closeOTPModal();">-->
<!--                <span aria-hidden="true">&times;</span>-->
<!--            </button>-->
<!--        </div>-->
<!--        <div class="modal-body">-->
<!--            <input  id="otp" type="text" width="90%" class="form-control" name="otp" placeholder="Enter OTP" #otp="ngModel" [(ngModel)]="SlotOpenRequestotp"/>-->
<!--            <div *ngIf="otp.touched && !otp.value">-->
<!--                <small class="text-danger">OTP is required.</small>-->
<!--            </div>-->
<!--            <div class="m-t-10">-->
<!--                <button type="button" class="btn btn-primary m-r-4" [disabled]="!otp.value" (click)="validateOTP(StatusModal)">-->
<!--                    Submit-->
<!--                </button>-->
<!--                <button type="button" class="btn btn-default m-l-4" (click)="closeOTPModal()">-->
<!--                    Cancel-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-template>-->
</div>
