import {Component, OnInit} from '@angular/core';

import {BuildingDataService} from './building-data.service';

@Component({
    selector: 'pp-building-data',
    templateUrl: './building-data.component.html',
    styleUrls: ['./building-data.component.css']
})
export class BuildingDataComponent implements OnInit {

    public isDataLoaded;
    public filterObj: any;
    public carrierArr: Array<any> = [];
    public totCarriers: number;
    public upsFilterObj: any;
    public upsCarrierArr: Array<any> = [];
    public totUpsCarriers: number;
    public rbcFilterObj: any;
    public rbcArr: Array<any> = [];
    public totRbc: number;

    constructor(private bdService: BuildingDataService) {
        this.totCarriers = 0;
        this.filterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
        this.upsFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
        this.rbcFilterObj = {start: 0, end: 0, page: 1, limit: 10};
    }

    ngOnInit() {
        this.fnSelectTab('RAW_DUMP');
    }

    /**
     * Get list of carriers
     * @param {object} params
     * */
    fnGetCarrierList(params?: object) {
        this.isDataLoaded = false;
        this.bdService.fnGetAllCarrier(params)
            .then((res: any) => {
                this.totCarriers = res.total;
                this.carrierArr = res.data;
                this.isDataLoaded = true;
            });
    }

    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetCarrierList(this.filterObj);
    }

    fnUpsPageChanged(event: any) {
        this.upsFilterObj.page = event.page;
        this.fnGetUPSCarriers(this.upsFilterObj);
    }

    fnRbcPageChanged(event: any) {
        this.rbcFilterObj.end = event.page * this.rbcFilterObj.limit;
        this.rbcFilterObj.start = this.rbcFilterObj.end - this.rbcFilterObj.limit;
    }

    /**
     * QV raw dump
     * @param {object} params
     * */
    fnGetUPSCarriers(params?: object) {
        this.isDataLoaded = false;
        this.bdService.fnGetUPSCarriers(params)
            .then((res: any) => {
                this.upsCarrierArr = res.data;
                this.totUpsCarriers = res.total;
                this.isDataLoaded = true;
            });
    }

    /**
     * QV raw dump
     * @param {object} params
     * */
    fnGetRBCImportData(params?: object) {
        this.isDataLoaded = false;
        this.bdService.fnGetRBCImportData(params)
            .then((res: any) => {
                this.rbcArr = res;
                this.isDataLoaded = true;
            });
    }

    fnSelectTab(selectedTab, event?) {
        switch(selectedTab) {
            case 'RAW_DUMP':
                this.fnGetUPSCarriers(this.upsFilterObj);
                break;
            case 'FROM_DB':
                this.fnGetCarrierList(this.filterObj);
                break;
            case 'JSON_RBC':
                this.rbcFilterObj.end = this.rbcFilterObj.page * this.rbcFilterObj.limit;
                this.fnGetRBCImportData();
                break;
        }
    }

}
