import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import * as moment from 'moment';

@Injectable()
export class AnalyticsService {

    constructor(private http: HttpClient) {
    }

    /**
     * QV data from our database table
     * @param {any} params
     * */
    fnGetPackages(params?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/packages' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * QV raw dump
     * @param {any} params
     * */
    fnGetUsersAnalytics(params?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/packages/byuser' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * RBC import data
     * @param {any} params
     * */
    fnGetDaysAnalytics(params?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/packages/bydate' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
    /**
     * RBC import data
     * @param {any} params
     * */
    fnGetNotificationCounts(params?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/notifications/bydate' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    fnGetUserCounts(params?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/user/bydate' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }


    /*
     * @param {number} milliseconds
     * */
    fnMiliSecondsToHour(milliseconds: number) {
        let formatted = '';
        const ms = moment.duration(milliseconds);
        let secondUnit = 'secs';
        let minuteUnit = 'mins';
        let hourUnit = 'hrs';
        let dayUnit = 'days';
        if (ms.minutes() == 1)
            minuteUnit = 'min';
        if (ms.hours() == 1)
            hourUnit = 'hr';
        if (ms.seconds() == 1)
            secondUnit = 'sec';
        if (ms.days() == 1)
            dayUnit = 'day';
        if (ms.days() != 0)
            formatted = ms.days() + " " + dayUnit;
        if (ms.hours() != 0)
            formatted = formatted + " " + ms.hours() + " " + hourUnit;
        if (ms.minutes() != 0)
            formatted = formatted + " " + ms.minutes() + " " + minuteUnit;
        if (ms.seconds() != 0)
            formatted = formatted + " " + ms.seconds() + " " + secondUnit;
        return formatted;
    }

    /*
     * @param {number} milliseconds
     * @param {string} unit    // optional ('days'/'hours'/'minutes'/'seconds')
     * */
    fnMilliSecondsByUnit(milliseconds: number, unit?: string) {
        let formatted;
        let result;
        if (unit == 'days') {
            formatted = (moment.duration(milliseconds).asDays());
        } else if (unit == 'hrs') {
            formatted = (moment.duration(milliseconds).asHours());
        } else if (unit == 'mins') {
            formatted = (moment.duration(milliseconds).asMinutes());
        } else if (unit == 'secs') {
            formatted = (moment.duration(milliseconds).asSeconds());
        } else {
            result = this.fnMiliSecondsToHour(milliseconds);
            return result;
        }

        if (unit && formatted.toFixed(2) <= 1) {
            unit = unit.substring(0, unit.length - 1);
        }

        if (unit) {
            result = formatted.toFixed(2) + " " + unit;
        }
        return result;
    }


}
