export class Constant {
    public static API_URL = '/'; // Live API URL 'http://ec2-34-231-237-69.compute-1.amazonaws.com:3000/'
    public static DEV_API_URL = 'dev.theparcelport.com';
    public static PROD_API_URL = 'app.theparcelport.com';
    public static PROD_1_API_URL = 'app-1.theparcelport.com';
    public static PROD_2_API_URL = 'app-2.theparcelport.com';
    public static SERVER_URL = window.location.origin;
    // public static SERVER_URL = 'http://localhost:3000';    // for local environment
    public static CURRENT_DATE_FORMAT = 'YYYY-MM-DD';
    public static EMAIL_REG_EX = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$');
    public static BLANK_SPACE_REG_EX = /^(?!\s*$).+/;
    public static NUMBER_REG_EX = /^\d+$/;
    public static CAPTCHA_SITE_KEY= '6Lc7w48UAAAAAFETnZK3LEoknDBm1d3yMslSPFmt';
    public static DEFAULT_DATE_RANGE_DAYS = 10;
    public static DEFAULT_DATE_RANGE_DAYS_ADMIN = 31;
    public static MAX_DATE_RANGE_DAYS = 31;
    public static MAX_DATE_RANGE_DAYS_ADMIN = 61;
    public static PHONE_REG_EX = new RegExp('^(?=.*[0-9])[- +()0-9]+$');
    public static US_ASCII_NAME_REG_EX = '[\x00-\x7F]*'; // Regular expression to allow only ASCII values

    /** User Roles **/
    public static ROLE_ADMIN = 'ADMIN';
    public static ROLE_COMPANY_ADMIN = 'COMPANY_ADMIN';
    public static ROLE_CARRIER_ADMIN = 'CARRIER_ADMIN';
    public static ROLE_CARRIER_USER = 'CARRIER_USER';
    public static ROLE_USER = 'USER';

    /** User Roles Prefix **/
    public static CARRIER = 'CARRIER';

    /** Company Name **/
    public static COMPANY_PUROLATOR = 'Purolator';

    /** Package Statuses **/
    public static PACKAGE_STATUS_STORED = 'STORED';
    public static PACKAGE_STATUS_DELIVERED = 'DELIVERED';
    public static PACKAGE_STATUS_IN_TRANSIT = 'IN_TRANSIT';
    public static PACKAGE_STATUS_UNDELIVERED = 'UNDELIVERED';
    public static PACKAGE_STATUS_CANCELLED = 'CANCELLED';
    public static PACKAGE_STATUS_DOOR_JAMMED = 'DOOR JAMMED';
    public static PACKAGE_STATUS_EXCEPTION = 'EXCEPTION';

    /** Slot Statuses **/
    public static SLOT_STATUS_RESERVED = 'RESERVED';
    public static SLOT_STATUS_AVAILABLE = 'AVAILABLE';
    public static SLOT_STATUS_UNAVAILABLE = 'UNAVAILABLE';
    public static SLOT_STATUS_REPAIR = 'REPAIR';
    public static SLOT_STATUS_RESERVED_FOR_HARDWARE = 'RESERVED_FOR_HARDWARE';
    public static SLOT_STATUS_OPEN = 'OPEN';
    public static SLOT_STATUS_PURGED = 'PURGED';
    public static SLOT_STATUS_CLOSE_AND_PURGED = 'CLOSE_AND_PURGED';
    public static SLOT_STATUS_JAMMED = 'DOOR_JAMMED';
    public static SLOT_STATUS_NOT_IN_USE = 'NOT_IN_USE';

    /** Slot Type **/
    public static SLOT_TYPE_SMALL = 'SMALL';
    public static SLOT_TYPE_MEDIUM = 'MEDIUM';
    public static SLOT_TYPE_LARGE = 'LARGE';
    public static SLOT_TYPE_EXTRA_LARGE = 'EXTRA_LARGE';
    public static SLOT_TYPE_XX_LARGE = 'XX_LARGE';

    /** Payment Plan Types **/
    public static PLAN_TYPE_ONE_TIME = {_id: 'oneTime', name: 'One-Time Single Payment'};
    public static PLAN_TYPE_FLAT_FEE_RECURRING = {_id: 'flatFeeRecurring', name: 'Flat Fee Recurring'};
    public static PLAN_TYPE_REGULAR_RECURRING = {_id: 'regularRecurring', name: 'Regular Recurring'};
    public static PLAN_TYPE_PAY_AFTER = {_id: 'payAfter', name: 'Pay After'};

    /** Return Process Types **/
    public static RETURN_PROCESS_TYPE_NO = 'NO';
    public static RETURN_PROCESS_TYPE_FRONT_DESK = 'FRONT_DESK';
    public static RETURN_PROCESS_TYPE_COURIER = 'COURIER';

    /** Shipment Statuses **/
    public static SHIPMENT_STATUS_IN_TRANSIT = 'IN_TRANSIT';
    public static SHIPMENT_STATUS_DELIVERED = 'DELIVERED';
    public static SHIPMENT_STATUS_CANCELLED = 'CANCELLED';
    public static SHIPMENT_STATUS_EXCEPTION = 'EXCEPTION';
    public static SHIPMENT_STATUS_EXPIRED = 'EXPIRED';
    public static SHIPMENT_STATUS_RETURNED = 'RETURNED';
    public static SHIPMENT_STATUS_PREPARING = 'PREPARING';
    public static SHIPMENT_STATUS_PRODUCT_RETURN = 'PRODUCT_RETURN';
    public static SHIPMENT_STATUS_OUTBOUND = 'OUTBOUND';

    /** Languages for user */
    public static USER_LANGUAGES_ENUM = [
        'EN',
        'FR'
    ];

    public static USER_LANGUAGES = [
        {
            key: 'EN',
            value: 'English'
        }, {
            key: 'FR',
            value: 'French'
        }
    ];

    public static CABINET_NUMBER_ENUM = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'Z'];
    public static TOTAL_LOCKER_BANKS_FOR_AUTOMATIC_CREATE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    public static SLOT_NUMBER_ENUM = ['01', '02', '03', '04', '05', '06', '07', '08', '09',
        '10', '11', '12', '13', '14', '15', '16', '17', '18'];

    /** Types of Locker's logs*/
    public static ANDROID = 'android';
    public static IONIC = 'ionic';
    public static SNBC = 'snbc';
    public static MIDDLEWARE = 'middleware';
    public static DARWIN = 'darwin';

    /**
     * Captcha Site key
     * @type {string}
     */
    public static CAPTCHA_SITE_KEY_PROD = "6Lf1wasUAAAAAJvrul_5a7w4SWkKnfvhwOjH_IXE";
    public static CAPTCHA_SITE_KEY_DEV = "6Lenv6sUAAAAAEfihbBOTpeFApXCx-0tMPYl9cIG";

    public static MAX_ACCESS_CODES_PER_LOCKER = 3;

    public static READABLE_SHIPMENT_STATUS = {
        IN_TRANSIT: 'In Locker',
        DELIVERED: 'Retrieved',
        CANCELLED: 'Cancelled',
        EXCEPTION: 'Exception',
        EXPIRED: 'Expired',
        RETURNED: 'Returned',
        PREPARING: 'Preparing',
        PRODUCT_RETURN: 'Product Return'
    };

    public static READABLE_OUTBOUND_SHIPMENT_STATUS = {
        OUTBOUND: 'In Locker',
        DELIVERED: 'Retrieved by Courier',
        CANCELLED: 'Cancelled',
        PREPARING: 'Pending Deposit',
        PRODUCT_RETURN: 'Product Return'
    };

    public static HOST_BANK = 'HOST BANK';
    public static LOCKER_BANK = 'LOCKER BANK';

    public static ADMIN_EMAIL = 'dev.webosmotic@gmail.com';

    public static REASON_MODAL_TITLE = {
        REPAIR: 'Repair',
        NOT_IN_USE: 'Not In Use',
        RESERVED: 'Reserved',
        OPEN: 'Open'
    };

    public static REASON_MODAL_DESC = {
        REPAIR: 'needs to be repair',
        NOT_IN_USE: 'is currently not in use',
        RESERVED: 'is reserved',
        OPEN: 'is open'
    };
}
