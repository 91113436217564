<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Upload APK</h1>
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);" [routerLink]="['/manage-apk']">Manage APK</a></li>
            <li>{{apkObj._id ? 'Edit' : 'Add'}} APK</li>
        </ul>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <div class="col-lg-12" *ngIf="!isDataLoaded">
        <form #apkForm="ngForm" enctype="multipart/form-data"
              (ngSubmit)="apkForm.valid && fnSaveAPK(apkObj)" novalidate>
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Upload</legend>

                <div class="form-group"
                     [ngClass]="{'has-error': (apkForm.submitted || apkName.touched) && !apkName.valid}">
                    <label for="apkName">APK Name<span class="text-danger">*</span></label>
                    <input id="apkName" type="text" class="form-control" name="apkName"
                           placeholder="Enter a apk name"
                           [(ngModel)]="apkObj.name" #apkName="ngModel" required/>
                    <div *ngIf="(apkForm.submitted || apkName.touched) && !apkName.valid">
                        <small class="text-danger" *ngIf="apkName.errors['required']">APK name is required.</small>
                    </div>
                </div>

                <div class="form-group"
                     [ngClass]="{'has-error': (apkForm.submitted || version.touched) && !version.valid}">
                    <label for="version">APK Version<span class="text-danger">*</span></label>
                    <input id="version" type="text" class="form-control" name="version"
                           placeholder="Enter a apk version"
                           [(ngModel)]="apkObj.version" #version="ngModel" required/>
                    <div *ngIf="(apkForm.submitted || version.touched) && !version.valid">
                        <small class="text-danger" *ngIf="version.errors['required']">APK version is required.</small>
                    </div>
                </div>

                <div class="form-group">
                    <label for="description">APK Description</label>
                    <textarea id="description" class="form-control" name="description"
                              placeholder="Enter a apk description" [(ngModel)]="apkObj.description"></textarea>
                </div>

                <div class="form-group" *ngIf="!apkObj._id">
                    <label for="apkFile">Select APK File</label>
                    <input id="apkFile" type="file" class="form-control" name="apkFile"
                           placeholder="Select apk file" #apkFile/>
                </div>
            </fieldset>

            <div class="form-group">
                <button class="btn btn-primary" type="submit" [disabled]="isDataLoaded">
                    {{apkObj._id ? 'Update' : 'Upload'}}
                </button>
                <button class="btn btn-warning" [routerLink]="['/manage-apk']">Cancel</button>
            </div>
        </form>
    </div>
</div>
