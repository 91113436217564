<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header ">Companies
            <button class="pull-right btn btn-primary" [routerLink]="['/companies/add']">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">New Company</span>
            </button>
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12" *ngIf="isDataLoaded">
        <div class="table-responsive" *ngIf="companiesArr.length">
            <table>
                <thead>
                <tr>
                    <th class="bg-primary company-name" (click)="sortBy('name')">
                        Company <i [class]="setSortIcon('name')"></i></th>
                    <th class="bg-primary cursor-auto">Brand</th>
                    <th class="bg-primary city" (click)="sortBy('locationId.city')">
                        City <i [class]="setSortIcon('locationId.city')"></i></th>
                    <th class="bg-primary state" (click)="sortBy('locationId.state')">
                        State <i [class]="setSortIcon('locationId.state')"></i></th>
                    <th class="bg-primary country" (click)="sortBy('locationId.country')">
                        Country <i [class]="setSortIcon('locationId.country')"></i></th>
                    <th class="bg-primary postal-code" (click)="sortBy('locationId.postalCode')">
                        Postal Code <i [class]="setSortIcon('locationId.postalCode')"></i></th>
                    <th class="bg-primary cursor-auto">Total Lockers</th>
                    <th class="bg-primary cursor-auto">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let company of companiesArr">
                    <td>{{company.name || '&nbsp;'}}</td>
                    <td>{{company.brand || '&nbsp;'}}</td>
                    <td>{{company.locationId && company.locationId.city ? company.locationId.city : '&nbsp;'}}</td>
                    <td>{{company.locationId && company.locationId.state ? company.locationId.state : '&nbsp;'}}</td>
                    <td>{{company.locationId && company.locationId.country ? company.locationId.country : '&nbsp;'}}
                    </td>
                    <td>{{company.locationId && company.locationId.postalCode ? company.locationId.postalCode : '&nbsp;'}}</td>
                    <td>{{company.totalLockers}}</td>
                    <td>
                        <div class="btn-group btn-group-xs">
                            <button type="button" class="btn btn-primary" [routerLink]="['/companies/'+company._id]">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-danger" (click)="fnDeleteCompany(company._id)">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="!companiesArr.length" class="text-center">No companies found.</h3>
    </div>
</div>
