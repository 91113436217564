import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import {Slot} from './slot';

@Injectable()
export class SlotsService {
    socket: {};

    constructor(private http: HttpClient) {
    }
    /**
     * Get list of slots
     * */
    fnGetSlots(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/slots' + (qString ? '?' + qString : ''))
                .subscribe((response: Slot) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get slot
     * @param {string} id
     * */
    fnGetSlot(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/slots/' + id)
                .subscribe((response: Slot) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create new slot
     * @param {object} slotObj
     * */
    fnCreateSlot(slotObj: Slot) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/slots', slotObj)
                .subscribe((response: Slot) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update slot
     * @param {object} slotObj
     * */
    fnUpdateSlot(slotObj: Slot) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/slots/' + slotObj._id, slotObj)
                .subscribe((response: Slot) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete slot
     * @param {string} id
     **/
    fnDeleteSlot(id: string) {
        return this.http.delete(Constant.API_URL + 'api/slots/' + id).toPromise();
    }

    /**
     * Remove Shipment
     * @param {string} slotId
     * @param {string} packageId
     **/
    unlinkShipment(slotId: string, packageId: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/slot/' + slotId + '/package/' + packageId)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    validatePassword(password: string) {
        return new Promise((resolve, reject) => {
            this.http.post(Constant.API_URL + 'api/auth/validate-password', {'password' : password})
                .subscribe((response) => {
                    resolve(response);
                },  (error) => {
                    reject(error.error);
                });
        });
    }

    validateOTP(otp: string) {
        return new Promise((resolve, reject) => {
            this.http.post(Constant.API_URL + 'api/auth/verify-otp', {'otp' : otp, slotOpenRequest: true})
                .subscribe((response) => {
                    resolve(response);
                },  (error) => {
                    reject(error.error);
                });
        });
    }
}
