import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import {APK} from './apk';
import {SearchResults} from '../../common/search-results';

@Injectable()
export class ApkService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get list of apk
     * @param {object} params
     * */
    fnGetAllAPK(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/apk' + (qString ? '?' + qString : ''))
                .subscribe((response: SearchResults<APK>) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get apk Information
     * @param {string} id
     * */
    fnGetAPKInfo(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/apk/' + id)
                .subscribe((response: APK) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Upload new apk
     * @param {object} apkObj
     * */
    fnUploadNewAPK(apkObj: APK) {
        const formData = new FormData();
        formData.append('name', apkObj.name);
        formData.append('description', apkObj.description);
        formData.append('apkFile', apkObj.apkFile);
        formData.append('latest', apkObj.latest);
        formData.append('version', apkObj.version);
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/apk', formData)
                .subscribe((response: APK) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update apk information
     * @param {object} apkObj
     * */
    fnUpdateAPK(apkObj: APK) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/apk/' + apkObj._id, apkObj)
                .subscribe((response: APK) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete APK file and details
     * @param {string} id
     * */
    fnDeleteAPK(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/apk/' + id)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
}
