import { Component, OnInit } from '@angular/core';
import {Constant} from '../common/constant';
import {UsersService} from '../authenticate/users/users.service';
import {ToastrService} from '../common/toastr.service';

@Component({
  selector: 'pp-reset-password-verification',
  templateUrl: './reset-password-verification.component.html',
  styleUrls: ['./reset-password-verification.component.css']
})
export class ResetPasswordVerificationComponent {

    verificationData = {
        email: '',
        tokenRes: ''
    };
    public emailRegEx: any = Constant.EMAIL_REG_EX;
    public showResetInstructionMessage: boolean = false;
    readonly captchaSiteKey: string;

    constructor( private usersService: UsersService, private toastr: ToastrService) {
        if (window.location.host === Constant.PROD_API_URL) {
            this.captchaSiteKey = Constant.CAPTCHA_SITE_KEY_PROD;
        } else {
            this.captchaSiteKey = Constant.CAPTCHA_SITE_KEY_DEV;
        }
    }

    async onSubmit(verificationData) {
        try {
            await this.usersService.generatePasswordResetLink(verificationData);
            this.toastr.fnSuccess('Password reset link send successfully.');
            this.showResetInstructionMessage = true;
        } catch (error) {
            this.toastr.fnError(error.message);
        }
    }

    getCaptchaToken = (captchaResponse: string) => {
        this.verificationData.tokenRes = captchaResponse;
    }
}
