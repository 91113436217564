<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Cabinet</h1>
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);" [routerLink]="['/cabinets']">Cabinets</a></li>
            <li *ngIf="!isDataLoaded">{{cabinetObj._id ? 'Edit' : 'Add'}} Cabinet</li>
        </ul>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <div class="col-lg-12" *ngIf="!isDataLoaded">
        <form #cabinetForm="ngForm"
              (ngSubmit)="cabinetForm.valid && fnSaveCabinet(cabinetObj)" novalidate>
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Basic</legend>

                <div class="form-group" *ngIf="currentUser.role == CONSTANT.ROLE_ADMIN && !cabinetObj._id"
                     [ngClass]="{'has-error': (cabinetForm.submitted || company.touched) && !company.valid}">
                    <label for="company">Select Company<span class="text-danger">*</span></label>
                    <select id="company" class="form-control" name="company" [(ngModel)]="cabinetObj.companyId"
                            (change)="cabinetObj.lockerId = '';fnGetLockers({companyId: cabinetObj.companyId})"
                            #company="ngModel" required>
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                    <div *ngIf="(cabinetForm.submitted || company.touched) && !company.valid">
                        <small class="text-danger" *ngIf="company.errors['required']">Company is required.</small>
                    </div>
                </div>

                <div class="form-group" *ngIf="cabinetObj._id">
                    <label>Selected Company</label>
                    <label class="form-control" disabled="true">{{selectedCompany}}</label>
                </div>

                <div class="form-group" [ngClass]="{'has-error': (cabinetForm.submitted || locker.touched) && !locker.valid}" *ngIf="!cabinetObj._id">
                    <label for="locker">Select Locker<span class="text-danger">*</span></label>
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="cabinetObj.lockerId" (change)="setCabinetNumber()"
                            #locker="ngModel" required>
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">
                            {{item.name}}
                        </option>
                    </select>
                    <div *ngIf="(cabinetForm.submitted || locker.touched) && !locker.valid">
                        <small class="text-danger" *ngIf="locker.errors['required']">Locker is required.</small>
                    </div>
                </div>

                <div class="form-group" *ngIf="cabinetObj._id">
                    <label>Selected Locker</label>
                    <label class="form-control" disabled="true">{{selectedLocker}}</label>
                </div>

                <div class="form-group" [ngClass]="{'has-error': (cabinetForm.submitted || cabinetNumber.touched) && !cabinetNumber.valid}">
                    <label for="cabinetNumber">Cabinet Number<span class="text-danger">*</span></label>
                    <select id="cabinetNumber" class="form-control" name="cabinetNumber"
                            [(ngModel)]="cabinetObj.cabinetNumber" #cabinetNumber="ngModel" required>
                        <option value="" >Select cabinet number</option>
                        <option [ngValue]="cabinetNumber" *ngFor="let cabinetNumber of availableCabinets">
                            {{cabinetNumber}}
                        </option>
                    </select>
                        <div *ngIf="(cabinetForm.submitted || cabinetNumber.touched) && !cabinetNumber.valid">
                            <small class="text-danger" *ngIf="cabinetNumber.errors['required']">Cabinet number is required.</small>
                        </div>
                </div>

                <div class="form-group m-b-50">
                    <label>Cabinet Type</label>
                    <div class="col-xs-12 p-0">
                        <div class="btn-group">
                            <label class="btn btn-success" name="hostBank" (click)="checkCabinetType()" [(ngModel)]="cabinetObj.type" btnRadio="HOST BANK">Host Bank</label>
                            <label class="btn btn-success" name="lockerBank" (click)="checkCabinetType()" [(ngModel)]="cabinetObj.type" btnRadio="LOCKER BANK">Locker Bank</label>
                        </div>
                    </div>
                </div>
                <ng-template [ngIf]="cabinetObj.cabinetNumber && !cabinetObj._id">
                    <app-bank [cabinetNumber]="cabinetObj.cabinetNumber" [cabinet]="slotsArray" [cabinetType]="cabinetObj.type" ></app-bank>
                </ng-template>
            </fieldset>

            <div class="form-group">
                <button class="btn btn-primary m-r-4" type="submit">
                    {{cabinetObj._id ? 'Update Cabinet' : 'Create Cabinet'}}
                </button>
                <button class="btn btn-warning" [routerLink]="['/cabinets']">Cancel</button>
            </div>
        </form>
    </div>
</div>
