export enum CURRENCY {
    CAD = 'CAD',
    USD = 'USD'
}

export enum LANGUAGE {
    EN = 'English',
    FR = 'French',
}

export enum SLOT_SIZE {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    EXTRA_LARGE = 'EXTRA_LARGE',
    XX_LARGE = 'XX_LARGE',
}

export enum PLAN_TYPE {
    RECURRING_PLAN = 'Recurring',
    PAY_PER_USE_PLAN = 'PayPerUse'
}

export enum COUNTRY {
    CA = 'CA',
    US = 'US'
}

export enum RETURN_PROCESS_TYPE {
    NO = 'No',
    FRONT_DESK = 'Front Desk',
    COURIER = 'Courier'
}

export enum SHIPMENT_STATUS {
    PREPARING = 'PREPARING',
    IN_TRANSIT = 'IN_TRANSIT',
    DELIVERED = 'DELIVERED',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
    EXCEPTION = 'EXCEPTION',
    RETURNED = 'RETURNED',
    PRODUCT_RETURN = 'PRODUCT_RETURN'
}

export enum OUTBOUND_SHIPMENT_STATUS {
    PREPARING = 'PREPARING',
    DELIVERED = 'DELIVERED',
    CANCELLED = 'CANCELLED',
    OUTBOUND = 'OUTBOUND'
}

export enum TRANSACTION_ACTION {
    DELETED = 'DELETED',
    CREATED = 'CREATED',
    SHIPMENT_STATUS_CHANGED = 'SHIPMENT_STATUS_CHANGED',
    DROP_OFF = 'DROP_OFF',
    PICK_UP = 'PICKUP',
    USER_UPDATED = 'USER_UPDATED',
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    EXPIRATION_DATE_CHANGED = 'EXPIRATION_DATE_CHANGED',
    EXPIRED_SHIPMENT_COLLECTED = 'EXPIRED_SHIPMENT_COLLECTED',
    REBOOT = 'REBOOT',
    REQUESTED_NEW_PASSWORD = 'REQUESTED_NEW_PASSWORD',
    UPDATED_PASSWORD = 'UPDATED_PASSWORD',
    SLOT_UPDATED = 'SLOT_UPDATED',
    USER_INSERTED = 'USER_INSERTED',
    ERROR = 'ERROR',
    USER_ARCHIVED = 'USER_ARCHIVED',
    SCAN = 'SCAN',
    MANUAL_ENTRY = 'MANUAL_ENTRY',
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
    VERIFICATION_CODE_UPDATED = 'VERIFICATION_CODE_UPDATED',
    APP_STATE = 'APP_STATE',
    PREPARING = 'PREPARING',
    PRODUCT_RETURN = 'PRODUCT_RETURN',
    PRODUCT_RETURN_COLLECTED = 'PRODUCT_RETURN_ COLLECTED',
    USER_IMPORT_ERROR = 'USER_IMPORT_ERROR'
}

export enum USA_STATES {
    AL = 'Alabama',
    AK = 'Alaska',
    AS = 'American Samoa',
    AZ = 'Arizona',
    AR = 'Arkansas',
    CA = 'California',
    CO = 'Colorado',
    CT = 'Connecticut',
    DE = 'Delaware',
    DC = 'District of Columbia',
    FM = 'Federated States of Micronesia',
    FL = 'Florida',
    GA = 'Georgia',
    GU = 'Guam',
    HI = 'Hawaii',
    ID = 'Idaho',
    IL = 'Illinois',
    IN = 'Indiana',
    IA = 'Iowa',
    KS = 'Kansas',
    KY = 'Kentucky',
    LA = 'Louisiana',
    ME = 'Maine',
    MH = 'Marshall Islands',
    MD = 'Maryland',
    MA = 'Massachusetts',
    MI = 'Michigan',
    MN = 'Minnesota',
    MS = 'Mississippi',
    MO = 'Missouri',
    MT = 'Montana',
    NE = 'Nebraska',
    NV = 'Nevada',
    NH = 'New Hampshire',
    NJ = 'New Jersey',
    NM = 'New Mexico',
    NY = 'New York',
    NC = 'North Carolina',
    ND = 'North Dakota',
    MP = 'Northern Mariana Islands',
    OH = 'Ohio',
    OK = 'Oklahoma',
    OR = 'Oregon',
    PW = 'Palau',
    PA = 'Pennsylvania',
    PR = 'Puerto Rico',
    RI = 'Rhode Island',
    SC = 'South Carolina',
    SD = 'South Dakota',
    TN = 'Tennessee',
    TX = 'Texas',
    UT = 'Utah',
    VT = 'Vermont',
    VI = 'Virgin Islands',
    VA = 'Virginia',
    WA = 'Washington',
    WV = 'West Virginia',
    WI = 'Wisconsin',
    WY = 'Wyoming'
}

export enum CANADA_PROVINCE {
    AB = 'Alberta',
    BC = 'British Columbia',
    MB = 'Manitoba',
    NB = 'New Brunswick',
    NL = 'Newfoundland and Labrador',
    NS = 'Nova Scotia',
    ON = 'Ontario',
    PE = 'Prince Edward Island',
    QC = 'Quebec',
    SK = 'Saskatchewan',
    NT = 'Northwest Territories',
    NU = 'Nunavut',
    YT = 'Yukon'
}

export enum SHIPMENT_MESSAGE_TYPE {
    SHIPMENT_CREATED = 'SHIPMENT_CREATED',
    SHIPMENT_REMINDER = 'SHIPMENT_REMINDER',
    SHIPMENT_EXPIRED = 'SHIPMENT_EXPIRED',
    SHIPMENT_PICKUP = 'SHIPMENT_PICKUP',
}
