import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {AuthService} from '../../common/auth.service';
import {ToastrService} from '../../common/toastr.service';

@Component({
    selector: 'pp-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    public currentUser: any;
    public token: any;
    public isMatched: boolean;
    public passwordObj: any;
    public confirmPassword: string;
    public isVisible = {
        isOldPasswordVisible: false,
        isNewPasswordVisible: false,
        isCPasswordVisible: false
    }

    constructor(private auth: AuthService, private toastr: ToastrService) {
        this.isMatched = true;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.token = this.auth.fnGetToken();
        this.passwordObj = {
            oldPassword: '',
            newPassword: ''
        };
    }

    ngOnInit() {
    }

    changePassword() {
        if (this.passwordObj.newPassword === this.confirmPassword) {
            this.isMatched = true;
            this.auth.changePassword(this.currentUser._id, this.passwordObj, this.token)
                .then((response: any) => {
                    this.toastr.fnSuccess(response.message);
                })
                .catch((error: any) => {
                    this.toastr.fnError(error.message);
                });
        } else {
            this.isMatched = false;
        }
    }

    changePasswordVisibility = (elementRef, inputVisibility) => {
        this.isVisible[inputVisibility] = !this.isVisible[inputVisibility];
        (elementRef.type === 'password') ? elementRef['type'] = 'text' : elementRef['type'] = 'password';
    }

    hidePassword = (event, elementRef) => {
        if (event.target.value.length === 0) {
            elementRef.type = 'password'
        }
    }
}
