<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Settings</h1>
    </div>

    <div class="col-lg-12">
        <fieldset class="col-md-12 fieldset">
            <legend class="fieldset-legend">Change Password</legend>
            <form #changePassForm="ngForm" (ngSubmit)="changePassForm.valid && changePassword()" novalidate>
                <div class="form-group"
                     [ngClass]="{'has-error': (changePassForm.submitted || oldPassword.touched) && !oldPassword.valid}">
                    <label for="oldPassword">Old Password<span class="text-danger">*</span></label>
                    <input #op id="oldPassword" type="password" width="90%" class="form-control" name="oldPassword"
                           placeholder="Enter old password" [(ngModel)]="passwordObj.oldPassword"  (input)="hidePassword($event, op)"
                           #oldPassword="ngModel" required/>
                    <i class="fa fa-2x " *ngIf="passwordObj.oldPassword" [ngClass]="isVisible.isOldPasswordVisible ? 'fa-eye-slash' : 'fa-eye'" (click)="changePasswordVisibility(op, 'isOldPasswordVisible')" aria-hidden="true"></i>
                    <div *ngIf="(changePassForm.submitted || oldPassword.touched) && !oldPassword.valid">
                        <small class="text-danger" *ngIf="oldPassword.errors.required">Old Password is required.</small>
                    </div>
                </div>
                <div class="form-group"
                     [ngClass]="{'has-error': (changePassForm.submitted || newPassword.touched) && !newPassword.valid}">
                    <label for="newPassword">New Password<span class="text-danger">*</span></label>
                    <input #np id="newPassword" type="password" class="form-control" name="newPassword"
                           placeholder="Enter new password" [(ngModel)]="passwordObj.newPassword" (input)="hidePassword($event, np)"
                           #newPassword="ngModel" required/>
                    <i class="fa fa-2x" *ngIf="passwordObj.newPassword" [ngClass]="isVisible.isNewPasswordVisible ? 'fa-eye-slash' : 'fa-eye'" (click)="changePasswordVisibility(np, 'isNewPasswordVisible')" aria-hidden="true"></i>
                    <div *ngIf="(changePassForm.submitted || newPassword.touched) && !newPassword.valid">
                        <small class="text-danger" *ngIf="newPassword.errors.required">New Password is required.</small>
                    </div>
                </div>
                <div class="form-group"
                     [ngClass]="{'has-error': (changePassForm.submitted || cPassword.touched) && !cPassword.valid}">
                    <label for="cPassword">Confirm Password<span class="text-danger">*</span></label>
                    <input #cp id="cPassword" type="password" class="form-control" name="cPassword"
                           placeholder="Re-Enter new password to confirm" [(ngModel)]="confirmPassword" (input)="hidePassword($event, cp)"
                           #cPassword="ngModel" required/>
                    <i class="fa fa-2x" *ngIf="confirmPassword" [ngClass]="isVisible.isCPasswordVisible ? 'fa-eye-slash' : 'fa-eye'" (click)="changePasswordVisibility(cp, 'isCPasswordVisible')" aria-hidden="true"></i>
                    <div *ngIf="(changePassForm.submitted || cPassword.touched) && !cPassword.valid">
                        <small class="text-danger" *ngIf="cPassword.errors.required">Confirm Password is required.
                        </small>
                    </div>
                    <div *ngIf="(changePassForm.submitted || cPassword.touched) && cPassword.valid">
                        <small class="text-danger" *ngIf="passwordObj.newPassword !== confirmPassword">Password doesn't
                            match.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary" type="submit">
                        Change Password
                    </button>
                </div>
            </form>
        </fieldset>
    </div>
</div>
